import usePaginatedLoader from 'src/api/loaders/usePaginatedLoader';
import { ALL_SCANS_QUERY, transformAllScansResponse as transformResponse } from 'src/api/vulnerabilities';

export default function useVulnerabilityScansLoader({ variables }) {
  return usePaginatedLoader(
    ALL_SCANS_QUERY,
    { variables },
    transformResponse,
  );
}
