import i18n from 'i18next';

import { TableColumnDefinition } from 'src/hooks/useTable';

const useBreachCredentialsColumns = () => [
  new TableColumnDefinition({
    name: 'addedAt',
    title: i18n.t('Date'),
  }),
  new TableColumnDefinition({
    name: 'domain',
    title: i18n.t('Breached Source'),
  }),
  new TableColumnDefinition({
    name: 'email',
    title: i18n.t('Email'),
  }),
  new TableColumnDefinition({
    name: 'password',
    title: i18n.t('Password information'),
  }),

  new TableColumnDefinition({
    gridTemplate: 'max-content',
    name: 'new',
  }),

  // Expand button
  new TableColumnDefinition({
    gridTemplate: 'minmax(60px, max-content)',
    name: 'expand',
  }),
];

export default useBreachCredentialsColumns;
