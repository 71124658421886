import { format, sub } from 'date-fns';
import range from 'lodash-es/range';

import { DEFAULT_DATE_FORMAT } from 'src/constants/date';
import { domainColors } from 'src/constants/domains';
import * as colorsMap from 'src/design/theme/colors';
import { sortByDate } from 'src/helpers/sort';

/**
 * domain name: [sorted values per month]
 */
function buildDomainsMap(domains) {
  return domains.reduce((result, domain) => ({
    ...result,
    [domain.name]: sortByDate((d) => d.date)({ sortDirection: 'asc' })(domain.recordsPerMonth)
      .map((d) => d.records),
  }), {});
}

function calculateAllDomainsRecords(domainsMap) {
  return Object
    .values(domainsMap)
    .reduce(
      (total, domainRecordsPerMonthArray) => total.map(
        (monthValue, index) => monthValue + domainRecordsPerMonthArray[index],
      ),
      new Array(12).fill(0),
    );
}

const getFormattedMonthInPast = (months) => format(sub(new Date(), { months }), DEFAULT_DATE_FORMAT);

const getMonths = () => range(0, 12).map(getFormattedMonthInPast).reverse();

export function buildChartData(bcStatistics) {
  const domainsMap = buildDomainsMap(bcStatistics.domains);
  const allDomainRecordsPerMonthArray = calculateAllDomainsRecords(domainsMap);

  return {
    categories: getMonths(),
    colors: [colorsMap['od-action']],
    series: [{
      data: allDomainRecordsPerMonthArray.map((value, index) => ({
        domainsMap,
        index,
        y: value,
      })),
    }],
  };
}

export function tooltipFormatter() {
  const { point: { domainsMap, index } } = this;
  const domains = Object.keys(domainsMap).sort();

  return (`
    <div class="DomainColumnChart__tooltip-formatter flex flex-col px-3 py-2 gap-2 rounded-xl">
     ${domains.map((domain, domainIndex) => (`
      <div class="flex items-center gap-2">
        <div class="h-3.5 w-3.5 rounded" style="background: ${domainColors[domainIndex]}"></div>
        <div class="text-od-white-900">${domain}</div>
        <div class="od-font-secondary-semi-bold text-od-white-900">${domainsMap[domain][index]}</div>  
      </div>
     `)).join('')}
    </div>
  `);
}
