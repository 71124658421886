import React from 'react';

import PropTypes from 'prop-types';

import { useCurrentUser } from 'src/contexts/useCurrentUser';
import GroupsGridViewRow from 'src/views/TacticVulnerabilities/TargetGroups/GroupsGridViewRow';

function GroupsGridView(props) {
  const { evsTargetGroups } = props;
  const { permissions } = useCurrentUser();

  const canDelete = (targetGroup) => evsTargetGroups.length > 1
    && !targetGroup.isScanRunning
    && permissions.canManageEvsSubscription();

  return (
    <section className="VulnerabilityGroupsGridView grid gap-2 items-center">
      {evsTargetGroups.map((targetGroup) => (
        <GroupsGridViewRow
          key={targetGroup.id}
          canDelete={canDelete(targetGroup)}
          targetGroup={targetGroup}
        />
      ))}
    </section>
  );
}

GroupsGridView.propTypes = {
  evsTargetGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default GroupsGridView;
