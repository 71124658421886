import React from 'react';

import { useTranslation } from 'react-i18next';

import useVulnerabilities from 'src/api/loaders/useVulnerabilities';
import ScanActions from 'src/components/features/vulnerabilities/ScanActions';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import Loading from 'src/design/ui-kit/Loading/Loading';
import useUserParams from 'src/hooks/useUserParams';
import CompanyDashboardInfoTooltip from 'src/views/CompanyDashboard/CompanyDashboardInfoTooltip';
import CompanyDashboardVulnerabilityScanningGroup from
  'src/views/CompanyDashboard/CompanyDashboardVulnerabilityScanningGroup';
import CompanyDashboardVulnerabilityScanningOverviewTotal
  from 'src/views/CompanyDashboard/CompanyDashboardVulnerabilityScanningOverviewTotal';

function CompanyDashboardVulnerabilityScanningOverview() {
  const { t } = useTranslation();
  const { companyId } = useUserParams();
  const { evsTargetGroups, loading, scanStatistics } = useVulnerabilities({ companyId });
  const { permissions } = useCurrentUser();

  return (
    <section>
      <h3>{t('Vulnerability Scanning Overview')}</h3>

      {loading && (
      <Loading />
      )}

      {
        scanStatistics && (
          <div className="bg-od-black-500 rounded-od-20 px-5 py-5 mt-6">
            <div className="flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <h4>{t('Total')}</h4>
                <CompanyDashboardInfoTooltip />
              </div>
              {permissions.canManageEvsSubscription() && (
                <ScanActions />
              )}
            </div>

            <CompanyDashboardVulnerabilityScanningOverviewTotal scanStatistics={scanStatistics} />
          </div>
        )
      }

      <div className="grid grid-cols-1 gap-8 mt-8">
        {evsTargetGroups.map((targetGroup) => (
          <CompanyDashboardVulnerabilityScanningGroup
            key={targetGroup.id}
            expandedInitialValue={false}
            targetGroup={targetGroup}
          />
        ))}
      </div>
    </section>
  );
}

export default CompanyDashboardVulnerabilityScanningOverview;
