import React from 'react';

import { useTranslation } from 'react-i18next';

import GoBackLink from 'src/components/common/GoBackLink';
import Page from 'src/components/common/Page';
import Loading from 'src/design/ui-kit/Loading/Loading';
import BillingInvoicesTableView from 'src/views/Billing/BillingInvoices/BillingInvoicesTableView';

function BillingInvoices() {
  const loading = false;
  const { t } = useTranslation();

  const invoices = [
    {
      id: '#143567',
      issuedAt: new Date().toDateString(),
      paidAt: new Date().toDateString(),
      status: 'Paid',
      total: '$129.00',
    },
    {
      id: '#1434523',
      issuedAt: new Date().toDateString(),
      paidAt: new Date().toDateString(),
      status: 'Due',
      total: '$200.00',
    },
  ];

  if (loading) {
    return (
      <Page title={t('Loading')}>
        <Loading />
      </Page>
    );
  }
  return (
    <Page title={t('Billing invoices')}>
      <h1 className="mb-4">{t('Billing invoices')}</h1>

      <GoBackLink to="/billing">
        {t('Billing')}
      </GoBackLink>

      <section className="mt-10">
        <BillingInvoicesTableView invoices={invoices} />
      </section>
    </Page>
  );
}

export default BillingInvoices;
