import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import BadgeEvsNotEnabled from 'src/components/features/vulnerabilities/BadgeEvsNotEnabled';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import FieldCountStatus from 'src/design/ui-kit/Field/FieldCountStatus/FieldCountStatus';
import Tooltip from 'src/design/ui-kit/Tooltip/Tooltip';
import EditCompanyButton from 'src/views/Companies/EditCompanyButton';

function CompaniesTableViewRow(props) {
  const { data: company, id } = props;

  const { t } = useTranslation();
  const { permissions } = useCurrentUser();

  return (
    <tr id={id}>
      <td>
        <Tooltip text={t('Control this company')}>
          <NavLink
            className="text-link flex gap-2 items-center group"
            to={`/companies/${company.id}/dashboard`}
          >
            <FontAwesomeIcon
              className="Button--primary px-3 py-2 max-h-9 rounded-od-10"
              icon={light('person-to-portal')}
            />

            <span className="group-hover:text-od-button-primary-hover">
              {company.name}
            </span>
          </NavLink>
        </Tooltip>
      </td>

      <td className="justify-center">
        <NavLink
          className="link-text flex gap-2 items-center group"
          to={`/companies/${company.id}/users`}
        >
          {company.usersCount}
        </NavLink>
      </td>

      <td className="justify-center">
        {company.evsStatistics ? (
          <NavLink
            className="block"
            to={`/companies/${company.id}/dashboard`}
          >
            <div className="flex gap-3">
              {company.evsStatistics.newCount > 0 && (
                <Tooltip text={t('New vulnerabilities since the last scan')}>
                  <FieldCountStatus
                    negative
                    value={company.evsStatistics.newCount}
                  />
                </Tooltip>
              )}
              {company.evsStatistics.remediatedCount > 0 && (
                <Tooltip text={t('Vulnerabilities no longer seen since the last scan')}>
                  <FieldCountStatus value={company.evsStatistics.remediatedCount} />
                </Tooltip>
              )}
            </div>
          </NavLink>
        ) : (
          <BadgeEvsNotEnabled companyId={company.id} />
        )}
      </td>

      {/* <td> */}
      {/*  <FieldCountStatus */}
      {/*    value={company.bcStatistics.breachCount} */}
      {/*  /> */}
      {/* </td> */}

      {/* <td className="justify-end"> */}
      {/*  <FieldPeriodCost */}
      {/*    timeUnit="month" */}
      {/*    value={789.90} */}
      {/*  /> */}
      {/* </td> */}

      {
        permissions.canManageCompany() && (
          <td className="justify-end">
            <EditCompanyButton company={company} />
          </td>
        )
      }
    </tr>
  );
}

CompaniesTableViewRow.propTypes = {
  data: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
};

export default CompaniesTableViewRow;
