import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import BadgeEvsNotEnabled from 'src/components/features/vulnerabilities/BadgeEvsNotEnabled';
import FieldCost from 'src/design/ui-kit/Field/FieldCost/FieldCost';
import FieldDatetime from 'src/design/ui-kit/Field/FieldDatetime/FieldDatetime';
import Tooltip from 'src/design/ui-kit/Tooltip/Tooltip';

function BillingCompaniesTableViewRow(props) {
  const { data: billingCompany } = props;

  const { t } = useTranslation();
  const { isSubscribed } = billingCompany;

  return (
    <tr className="hover:bg-od-black-300">
      <td className="h-16">
        {isSubscribed ? (
          <Tooltip text={t('View cost breakdown')}>
            <NavLink
              className="text-link flex gap-2 items-center group"
              to={`/billing/companies/${billingCompany.company.id}`}
            >
              <FontAwesomeIcon
                className="Button--primary px-3 py-2 max-h-9 rounded-od-10"
                icon={light('file-invoice-dollar')}
              />

              <span className="group-hover:text-od-button-primary-hover">
                {billingCompany.company.name}
              </span>
            </NavLink>
          </Tooltip>
        ) : (
          <span className="text-od-white-500">{billingCompany.company.name}</span>
        )}
      </td>

      <td className="h-16">
        {isSubscribed ? (
          <FieldCost value={billingCompany.cost} />
        ) : (
          <BadgeEvsNotEnabled companyId={billingCompany.company.id} />
        )}
      </td>

      <td className="h-16 justify-end">
        {isSubscribed && (
          <div className="flex gap-3 items-center">
            <div className="flex flex-col gap-1">
              <FieldCost value={billingCompany.nextPayment.cost} />
              <FieldDatetime value={new Date(billingCompany.nextPayment.date)} />
            </div>
          </div>
        )}
      </td>
    </tr>
  );
}

BillingCompaniesTableViewRow.propTypes = {
  data: PropTypes.any.isRequired,
};

export default BillingCompaniesTableViewRow;
