/* eslint-disable max-len */
/*
Permission,Description
nessus.full_write_evssubscription,Subscribe any company to EVS
nessus.managed_write_evssubcription,Subscribe companies managed by the MSP to EVS
nessus.self_write_evssubscription,Subscribe own company to EVS
nessus.full_read_target_group,User can read any information related to any company's target group
nessus.full_write_target_group,User can change information related to the target groups of any company
nessus.managed_read_target_group,User can read any target group information related to companies managed by their MSP
nessus.managed_write_target_group,User can change information of target groups belonging to any company managed by their MSP
nessus.self_read_target_group,"User can read any target group information related, everywhere"
nessus.self_write_target_group,User can change information of target groups belonging to their own company
users.full_billing_company,See/Change information related to any company
users.full_delete_company,Permanently delete any company
users.full_view_company,View information about any company
users.full_write_company,Write information about any company
users.managed_delete_company,Permanently delete any company managed by your MSP
users.managed_view_company,View information about any company managed by your MSP
users.managed_write_company,Write information about any company managed by your MSP
users.self_billing_company,See/Change information related to your company
users.self_change_company,Change information about your own company
users.self_view_company,View information about your own company
users.full_billing_msp,Change billing information for any MSP
users.full_view_msp,View information about any MSP
users.full_write_msp,Full-write access to any MSP
users.self_billing_msp,Change billing information for your MSP
users.self_change_msp,Change information about your MSP
users.self_view_msp,View information about your own MSP
users.full_subscribe,Subscribe any company to any tactic
users.managed_subscribe,Subscribe any managed company to any tactic
users.self_subscribe,Subscribe any their company to any tactic
users.tactic_configure,Permission to configure fully details about a tactic
users.full_view_user,View any information about any user
users.full_write_user,Change any information about any user
users.managed_view_user,View information about any user in any companies managed by the MSP
users.managed_write_user,Write information about any user in any company managed by the MSP
users.within_company_view_user,View any information about any user within the same company
users.within_company_write_user,Write information about any user within the company
users.within_msp_view_user,View any information about any user witihn the same MSP
users.within_msp_write_user,Write information about any user within the MSP
 */

import { getUserConfig, USER_TYPES } from 'src/users';

export function hasAtLeastOne(permissions, list) {
  return permissions.some((permission) => list.includes(permission));
}

export function buildPermissions(me) {
  const permissions = me ? me.permissions : [];
  const userType = me ? me.userType : null;
  const userConfig = getUserConfig(userType);

  return {
    canManageCompany: () => [
      USER_TYPES.GLOBAL_ADMIN,
      USER_TYPES.MSP_ADMIN,
    ].includes(userType),

    canManageEvsNotificationSettings: () => [
      USER_TYPES.MSP_ADMIN,
      USER_TYPES.MSP_ANALYST,
    ].includes(userType),

    canManageEvsSubscription: () => [
      USER_TYPES.MSP_ADMIN,
      USER_TYPES.COMPANY_ADMIN,
    ].includes(userType),

    canManageUsers: (userTypeCategory) => {
      const hasPermission = [
        USER_TYPES.GLOBAL_ADMIN,
        USER_TYPES.MSP_ADMIN,
        USER_TYPES.COMPANY_ADMIN,
      ].includes(userType);

      const hasInvitationTypes = userConfig.getInvitationTypes(userTypeCategory).length > 0;

      return hasPermission && hasInvitationTypes;
    },

    canSetupPaymentMethod: () => [
      USER_TYPES.MSP_ADMIN,
    ].includes(userType),

    // Only MSP Admins can view & set billing details
    canViewBillingDetails: () => [
      USER_TYPES.MSP_ADMIN,
    ].includes(userType),

    // Global Admin does not have access to MSPs list
    // therefore the feature is temporarily blocked for them
    canViewCompanyUsers: () => hasAtLeastOne(
      permissions,
      [
        'users.managed_view_user',
        'users.within_company_view_user',
      ],
    ),

    canViewPrices: () => [
      USER_TYPES.MSP_ADMIN,
      USER_TYPES.COMPANY_ADMIN,
    ].includes(userType),
  };
}
