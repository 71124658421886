import React from 'react';

import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { EVS_SUBSCRIBE } from 'src/api/evsSubscription';
import { CREATE_EVS_TARGET_GROUP_MUTATION } from 'src/api/vulnerabilities';
import BulkAddStringsForm from 'src/components/common/BulkEditStringsForm/BulkAddStringsForm';
import FormControl from 'src/components/common/FormControl';
import SidebarModal from 'src/components/modals/SidebarModal';
import TargetGroupCost from 'src/components/modals/TargetGroupSidebarModal/TargetGroupCost';
import useIPHelpers from 'src/components/modals/TargetGroupSidebarModal/useIPHelpers';
import { EVS_SUBSCRIPTION_CHANGED, TARGET_GROUP_ADDED } from 'src/constants/events';
import { useCurrentCompany } from 'src/contexts/useCurrentCompany';
import Divider from 'src/design/ui-kit/Divider/Divider';
import Input from 'src/design/ui-kit/Input/Input';
import { isValidIP } from 'src/helpers/ip';
import { calculatePrices } from 'src/helpers/price';
import useEmitter from 'src/hooks/useEmitter';
import useToast from 'src/hooks/useToast';
import AddTargetGroupContinueButton
  from 'src/views/TacticVulnerabilities/TargetGroups/AddTargetGroup/AddTargetGroupContinueButton';
import useTargetGroupForm from 'src/views/TacticVulnerabilities/TargetGroups/AddTargetGroup/useTargetGroupForm';

function TargetGroupSidebarModal(props) {
  const { isOpen, mode, onClose } = props;

  const { t } = useTranslation();
  const { companyId } = useParams();
  const emitter = useEmitter();
  const { showSuccessMessage } = useToast();
  const { basePrice, evsSubscriptionStatus, targetPrice } = useCurrentCompany();

  const {
    form: { clearErrors, control, formState, register, reset, watch },
    helpers: { getFieldError },
  } = useTargetGroupForm();

  const formData = watch();

  const { indexFormatter, valueFormatter } = useIPHelpers(formData.targets);

  const [createTargetGroup, { loading: creatingTargetGroup }] = useMutation(CREATE_EVS_TARGET_GROUP_MUTATION, {
    onCompleted(response) {
      emitter.emit(TARGET_GROUP_ADDED, response);
      showSuccessMessage(t('Target group successfully created'));
      onClose();
    },
  });
  const [subscribe, { loading: subscribing }] = useMutation(EVS_SUBSCRIBE, {
    onCompleted({ evsSubscribe }) {
      if (evsSubscribe) {
        showSuccessMessage(t('Successfully subscribed'));
        onClose();
        emitter.emit(EVS_SUBSCRIPTION_CHANGED);
      }
    },
  });

  const onSave = async () => {
    try {
      if (mode === 'subscription') {
        await subscribe({
          variables: {
            company: companyId,
            targetGroupName: formData.name,
            targetGroupTargets: formData.targets,
          },
        });
      } else if (mode === 'targetGroup') {
        await createTargetGroup({
          variables: {
            companyId,
            name: formData.name,
            targets: formData.targets,
          },
        });
      }
    } catch (e) {
      //
    }
  };

  const handleClose = () => {
    clearErrors();
    reset();
    onClose();
  };

  const loading = creatingTargetGroup || subscribing;

  const { totalPrice, totalTargetsPrice } = calculatePrices({
    basePrice,
    evsSubscriptionStatus,
    targetPrice,
    targetsCount: formData.targets.length,
  });

  return (
    <SidebarModal
      contentClassName="grid flex-1 min-h-0"
      contentStyle={{
        gridTemplateRows: 'max-content 1px minmax(0, 1fr) max-content',
      }}
      isOpen={isOpen}
      onClose={handleClose}
      title={
        mode === 'subscription'
          ? t('Subscribe to EVS')
          : t('Create Target Group')
      }
    >
      <div className="pl-6 pr-10 pt-3 pb-7">
        <FormControl error={getFieldError('lastName')}>
          <Input
            name="name"
            placeholder={t('Group name')}
            register={register}
          />
        </FormControl>
      </div>

      <Divider />

      <div className="pl-6 pr-10 pt-3">
        <Controller
          control={control}
          name="targets"
          render={({ field: { onChange } }) => (
            <BulkAddStringsForm
              canEdit={false}
              indexFormatter={indexFormatter}
              onChange={onChange}
              placeholder={t('IP')}
              validator={isValidIP}
              valueFormatter={valueFormatter}
            />
          )}
        />
      </div>

      <Divider />

      <div className="pl-6 pr-10 pb-4">
        <TargetGroupCost
          totalPrice={totalPrice}
          totalTargetsPrice={totalTargetsPrice}
        />
      </div>

      <section className="flex flex-col mt-2 pl-6 pr-10 gap-5">
        <AddTargetGroupContinueButton
          disabled={!formState.isValid || loading}
          mode={mode}
          onConfirm={onSave}
          totalPrice={totalPrice}
        />
      </section>
    </SidebarModal>
  );
}

TargetGroupSidebarModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf(['subscription', 'targetGroup']).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TargetGroupSidebarModal;
