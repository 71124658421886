import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { SCAN_QUERY } from 'src/api/vulnerabilities';
import Nav, { NavItem } from 'src/components/common/Nav';
import Page from 'src/components/common/Page';
import Loading from 'src/design/ui-kit/Loading/Loading';
import Switch from 'src/design/ui-kit/Switch/Switch';
import Tooltip from 'src/design/ui-kit/Tooltip/Tooltip';
import useUserParams from 'src/hooks/useUserParams';
import PageNotFound from 'src/views/PageNotFound/PageNotFound';
import ExportButton from 'src/views/VulnerabilityScan/ExportButton';
import ScanOverview from 'src/views/VulnerabilityScan/ScanOverview';
import ScanVulnerabilities from 'src/views/VulnerabilityScan/ScanVulnerabilities/ScanVulnerabilities';

function VulnerabilityScan() {
  const { t } = useTranslation();
  const { scanId } = useUserParams();
  const [activeTab, setActiveTab] = useState('overview');
  const [showInfo, setShowInfo] = useState(false);

  const {
    data,
    loading,
  } = useQuery(
    SCAN_QUERY,
    {
      variables: {
        ignoreErrors: true,
        scanId,
      },
    },
  );

  const { scan } = data || { scan: null };

  if (loading) {
    return (
      <Page title={t('Loading')}>
        <Loading />
      </Page>
    );
  }

  if (!scan) {
    return (
      <PageNotFound title={t('Scan not found')} />
    );
  }

  const navItems = [
    new NavItem({
      action: () => setActiveTab('overview'),
      key: 'overview',
      name: t('Overview'),
    }),
    new NavItem({
      action: () => setActiveTab('vulnerabilities'),
      key: 'vulnerabilities',
      name: t('Vulnerabilities'),
    }),
  ];

  return (
    <Page title={t('Vulnerability Scan Results')}>
      <div className="VulnerabilityScan flex items-center gap-3">
        <div className="flex-1">
          <Nav
            active={activeTab}
            navItems={navItems}
            variant="primary"
          />
        </div>
        <div className="flex items-center gap-3">
          {activeTab === 'vulnerabilities' && (
            <div className="flex gap-2 items-center">
              <Switch
                checked={showInfo}
                id="VulnerabilityScan__info-toggle"
                label={t('Show info')}
                onChange={setShowInfo}
              />
              <Tooltip
                popoverProps={{
                  positions: ['bottom'],
                }}
                text={t('Informational findings are not vulnerabilities but contain'
                  + ' configuration information that may be useful to an auditor or an administrator.'
                  + ' By default, these are not shown.')}
              >
                <FontAwesomeIcon icon={light('circle-info')} />
              </Tooltip>
            </div>
          )}
          <ExportButton scan={scan} />
        </div>
      </div>

      {activeTab === 'overview' && (
        <ScanOverview scan={scan} showInfo={showInfo} />
      )}
      {activeTab === 'vulnerabilities' && (
        <ScanVulnerabilities scan={scan} showInfo={showInfo} />
      )}
    </Page>
  );
}

export default VulnerabilityScan;
