import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import TableView from 'src/components/common/Table/TableView';
import { TableColumnDefinition } from 'src/hooks/useTable';
import BillingInvoicesTableViewRow from 'src/views/Billing/BillingInvoices/BillingInvoicesTableViewRow';

function BillingInvoicesTableView(props) {
  const { invoices } = props;
  const { t } = useTranslation();

  const columns = [
    new TableColumnDefinition({
      name: 'invoice',
      title: t('Invoice'),
    }),
    new TableColumnDefinition({
      name: 'total',
      title: t('Total'),
    }),
    new TableColumnDefinition({
      name: 'status',
      title: t('Status'),
    }),
    new TableColumnDefinition({
      name: 'issuedOn',
      title: t('Issued on'),
    }),
    new TableColumnDefinition({
      name: 'paidOn',
      title: t('Paid on'),
    }),
    new TableColumnDefinition({
      gridTemplate: '60px',
      name: 'downloadButton',
    }),
  ];

  return (
    <TableView
      columns={columns}
      data={invoices}
      TableRow={BillingInvoicesTableViewRow}
    />
  );
}

BillingInvoicesTableView.propTypes = {
  invoices: PropTypes.array.isRequired,
};

export default BillingInvoicesTableView;
