import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { EVS_UNSUBSCRIBE } from 'src/api/evsSubscription';
import ConfirmationModal from 'src/components/modals/ConfirmationModal';
import { EVS_SUBSCRIPTION_CHANGED } from 'src/constants/events';
import Button from 'src/design/ui-kit/Button/Button';
import useEmitter from 'src/hooks/useEmitter';
import useToast from 'src/hooks/useToast';
import useUserParams from 'src/hooks/useUserParams';

function EvsTacticUnsubscribeButton() {
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);

  const { t } = useTranslation();
  const { companyId } = useUserParams();
  const { showSuccessMessage } = useToast();
  const emitter = useEmitter();

  const [unsubscribe, { loading: saving }] = useMutation(EVS_UNSUBSCRIBE, {
    onCompleted({ evsUnsubscribe }) {
      if (evsUnsubscribe) {
        showSuccessMessage(t('Successfully unsubscribed'));
        emitter.emit(EVS_SUBSCRIPTION_CHANGED);
        setIsOpenConfirmationModal(false);
      }
    },
    variables: {
      company: companyId,
    },
  });

  return (
    <>
      <Button
        onClick={() => setIsOpenConfirmationModal(true)}
        size="sm"
        variant="secondary"
      >
        {t('Unsubscribe')}
      </Button>
      <ConfirmationModal
        Cancel={(
          <Button
            disabled={saving}
            onClick={() => setIsOpenConfirmationModal(false)}
          >
            {t('No')}
          </Button>
        )}
        Confirm={(
          <Button
            disabled={saving}
            onClick={unsubscribe}
            variant="secondary"
          >
            {t('Yes')}
          </Button>
        )}
        isOpen={isOpenConfirmationModal}
        onClose={() => setIsOpenConfirmationModal(false)}
        subtitle={t('Are you sure you want to unsubscribe from this tactic?'
          + ' Doing so will result in deletion of all target groups and results after your billing period has ended.')}
        title={t('Unsubscribe')}
      />
    </>
  );
}

export default EvsTacticUnsubscribeButton;
