export const COMPANY_ADDED = 'COMPANY_ADDED';

export const COMPANY_EDITED = 'COMPANY_EDITED';

export const USER_ADDED = 'USER_ADDED';

export const USER_UPDATED = 'USER_UPDATED';

export const USER_DELETED = 'USER_DELETED';

export const DOMAINS_UPDATED = 'DOMAINS_UPDATED';

export const TARGET_GROUP_ADDED = 'TARGET_GROUP_ADDED';

export const TARGET_GROUP_UPDATED = 'TARGET_GROUP_UPDATED';

export const TARGET_GROUP_DELETED = 'TARGET_GROUP_DELETED';

export const BILLING_INFORMATION_UPDATED = 'BILLING_INFORMATION_UPDATED';

export const EVS_SUBSCRIPTION_CHANGED = 'EVS_SUBSCRIPTION_CHANGED';

export const EVS_SCAN_STARTED = 'EVS_SCAN_STARTED';

export const NOTIFICATIONS_UPDATED = 'NOTIFICATIONS_UPDATED';

export const UNSEEN_NOTIFICATIONS_UPDATED = 'UNSEEN_NOTIFICATIONS_UPDATED';
