import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { buildOption } from 'src/components/features/vulnerabilities/TargetGroupSelect';
import useFormBuilder from 'src/hooks/useFormBuilder';

function useScheduleForm({ initialTargetGroup }) {
  const { t } = useTranslation();

  const initialFormData = {
    daysValue: {
      days: [],
      period: 'MONTHLY',
    },
    targetGroup: initialTargetGroup ? [buildOption(initialTargetGroup)] : [],
    timeOfDay: '',
  };

  const validationSchema = yup.object({
    daysValue: yup
      .object()
      .test(
        'notEmpty',
        t('At least 1 day is required'),
        (daysValue) => daysValue.days.length > 0,
      )
      .required(t('Field is required')),
    targetGroup: yup
      .object()
      .required(t('Field is required')),
    timeOfDay: yup
      .string()
      .required(t('Field is required')),
  }).required();

  return useFormBuilder(initialFormData, validationSchema);
}

export default useScheduleForm;
