import React from 'react';

import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { MSP_BILLING_COMPANY_DETAILS } from 'src/api/billing';
import { useCurrentCompany } from 'src/contexts/useCurrentCompany';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import Button from 'src/design/ui-kit/Button/Button';
import FieldCost from 'src/design/ui-kit/Field/FieldCost/FieldCost';
import InfoCard from 'src/design/ui-kit/InfoCard/InfoCard';
import useUserParams from 'src/hooks/useUserParams';
import EvsNotificationSettings from 'src/views/TacticVulnerabilities/EvsNotificationSettings';

function TacticVulnerabilitiesDetails(props) {
  const { evsTargetGroups } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { permissions } = useCurrentUser();
  const { companyId } = useUserParams();

  const { companyEvsSubscription } = useCurrentCompany();

  const { data } = useQuery(MSP_BILLING_COMPANY_DETAILS, {
    skip: !permissions.canViewBillingDetails(),
    variables: { id: companyId },
  });

  const { mspBillingCompanyDetails } = data || { mspBillingCompanyDetails: null };

  return (
    <section className={classNames('mt-8 grid grid-cols-2 gap-4', {
      'xl:grid-cols-4': !permissions.canViewBillingDetails(),
      'xl:grid-cols-6': permissions.canViewBillingDetails(),
    })}
    >
      <InfoCard description={t('IP addresses')}>
        {companyEvsSubscription.targetCount}
      </InfoCard>

      <InfoCard description={t('Groups')}>
        {evsTargetGroups.length}
      </InfoCard>

      {permissions.canViewBillingDetails() && (
        <InfoCard className="col-span-2" description={t('Total cost')}>
          <div className="flex gap-2 justify-between">
            <h4>
              {mspBillingCompanyDetails && (
                <FieldCost value={mspBillingCompanyDetails.cost} />
              )}
            </h4>

            {permissions.canSetupPaymentMethod() && (
              <Button
                onClick={() => navigate(`/billing/companies/${companyId}`)}
                size="sm"
                variant="secondary"
              >
                {t('Open billing')}
              </Button>
            )}
          </div>
        </InfoCard>
      )}

      {permissions.canManageEvsNotificationSettings() && (
        <EvsNotificationSettings />
      )}
    </section>
  );
}

TacticVulnerabilitiesDetails.propTypes = {
  evsTargetGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TacticVulnerabilitiesDetails;
