import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { DELETE_EVS_TARGET_MUTATION } from 'src/api/vulnerabilities';
import ConfirmationModal from 'src/components/modals/ConfirmationModal';
import { TARGET_GROUP_UPDATED } from 'src/constants/events';
import Button from 'src/design/ui-kit/Button/Button';
import useEmitter from 'src/hooks/useEmitter';
import useToast from 'src/hooks/useToast';

function EvsTargetsDeleteButton(props) {
  const { target } = props;
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);

  const { t } = useTranslation();
  const emitter = useEmitter();

  const { showSuccessMessage } = useToast();
  const [deleteTarget, { loading: saving }] = useMutation(
    DELETE_EVS_TARGET_MUTATION,
    {
      onCompleted() {
        emitter.emit(TARGET_GROUP_UPDATED);
        showSuccessMessage(t('Target deleted successfully'));
        setIsOpenConfirmationModal(false);
      },
    },
  );

  const onDelete = async () => {
    await deleteTarget({
      variables: {
        targetGroup: target.group.id,
        targets: [target.ip],
      },
    });
  };

  return (
    <>
      <Button
        onClick={() => setIsOpenConfirmationModal(true)}
        variant="text"
      >
        <FontAwesomeIcon icon={light('trash')} />
      </Button>
      <ConfirmationModal
        Cancel={(
          <Button
            disabled={saving}
            onClick={() => setIsOpenConfirmationModal(false)}
            variant="secondary"
          >
            {t('No')}
          </Button>
        )}
        Confirm={(
          <Button
            disabled={saving}
            onClick={onDelete}
          >
            {t('Yes')}
          </Button>
        )}
        isOpen={isOpenConfirmationModal}
        onClose={() => setIsOpenConfirmationModal(false)}
        subtitle={t('Are you sure that you want to delete IP "{{ip}}"?', { ip: target.ip })}
        title={t('Delete IP')}
      />
    </>
  );
}

EvsTargetsDeleteButton.propTypes = {
  target: PropTypes.object.isRequired,
};

export default EvsTargetsDeleteButton;
