import { useState } from 'react';

import { useQuery } from '@apollo/client';

/**
 * @description
 * The function will be used for no-cache only to handle the pagination in the right way
 * https://github.com/apollographql/apollo-client/issues/5239
 */
export default function usePaginatedLoader(query, { variables }, transformResponse) {
  const [items, setItems] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [pageInfo, setPageInfo] = useState({
    endCursor: null,
    hasNextPage: false,
  });

  const result = useQuery(query, {
    fetchPolicy: 'no-cache',
    onCompleted(response) {
      const transformedResponse = transformResponse(response);
      setItems(transformedResponse.items);
      setPageInfo(transformedResponse.pageInfo);
    },
    variables,
  });

  const fetchMore = async (...args) => {
    setIsLoadingMore(true);
    const response = await result.fetchMore(...args);
    const transformedResponse = transformResponse(response.data);
    setItems([
      ...items,
      ...transformedResponse.items,
    ]);
    setPageInfo(transformedResponse.pageInfo);
    setIsLoadingMore(false);
  };

  return {
    ...result,
    data: {
      items,
      pageInfo,
    },
    fetchMore,
    isLoadingMore,
  };
}
