import React, { useState } from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import TargetGroupSidebarModal from
  'src/components/modals/TargetGroupSidebarModal/TargetGroupSidebarModal';
import Button from 'src/design/ui-kit/Button/Button';

function AddTargetGroupButton() {
  const [isOpenTargetGroupSidebarModal, setIsOpenTargetGroupSidebarModal] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <Button
        onClick={() => setIsOpenTargetGroupSidebarModal(true)}
        title={t('Create Target Group')}
      >
        <FontAwesomeIcon icon={light('square-plus')} />
        {t('Add group')}
      </Button>
      <TargetGroupSidebarModal
        isOpen={isOpenTargetGroupSidebarModal}
        mode="targetGroup"
        onClose={() => setIsOpenTargetGroupSidebarModal(false)}
      />
    </>
  );
}

export default AddTargetGroupButton;
