import React from 'react';

import PropTypes from 'prop-types';

import { useCurrentUser } from 'src/contexts/useCurrentUser';
import EvsTargetsDeleteButton from 'src/views/TacticVulnerabilities/EvsTargets/EvsTargetsDeleteButton';

function EvsTargetsTableViewRow(props) {
  const { data: target } = props;

  const { permissions } = useCurrentUser();

  return (
    <tr id={`TableRow${target.id}`}>
      <td>
        <span>{target.ip}</span>
      </td>
      <td>
        <span className="text-od-white-500">{target.group.name}</span>
      </td>
      {permissions.canManageEvsSubscription() && (
        <td>
          <EvsTargetsDeleteButton target={target} />
        </td>
      )}
    </tr>
  );
}

EvsTargetsTableViewRow.propTypes = {
  data: PropTypes.object.isRequired,
};

export default EvsTargetsTableViewRow;
