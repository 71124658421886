import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { EVS_SUBSCRIBE } from 'src/api/evsSubscription';
import ConfirmationModal from 'src/components/modals/ConfirmationModal';
import { EVS_SUBSCRIPTION_CHANGED } from 'src/constants/events';
import { useCurrentCompany } from 'src/contexts/useCurrentCompany';
import { useMSPBilling } from 'src/contexts/useMSPBilling';
import Button from 'src/design/ui-kit/Button/Button';
import useEmitter from 'src/hooks/useEmitter';
import useToast from 'src/hooks/useToast';
import useUserParams from 'src/hooks/useUserParams';
import PaymentMethodModal from 'src/views/Billing/ChangePaymentMethod/PaymentMethodModal';

function EvsTacticResumeButton() {
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);

  const { t } = useTranslation();
  const { companyId } = useUserParams();
  const { showSuccessMessage } = useToast();
  const emitter = useEmitter();
  const { evsTargetGroups } = useCurrentCompany();
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const { mspBillingDetails: { paymentSource: { isCardWorking } } } = useMSPBilling();

  const [subscribe, { loading: saving }] = useMutation(EVS_SUBSCRIBE, {
    onCompleted({ evsSubscribe }) {
      if (evsSubscribe) {
        showSuccessMessage(t('Subscription successfully resumed'));
        emitter.emit(EVS_SUBSCRIPTION_CHANGED);
        setIsOpenConfirmationModal(false);
      }
    },
    variables: {
      company: companyId,
    },
  });

  const onConfirm = async () => {
    const reactivatedTargetGroups = evsTargetGroups.map((targetGroup) => targetGroup.id);
    await subscribe({
      variables: {
        company: companyId,
        reactivatedTargetGroups,
      },
    });
  };

  const onClickResumeButton = async () => {
    if (isCardWorking) {
      await setIsOpenConfirmationModal(true);
    } else {
      setIsPaymentModalOpen(true);
    }
  };

  const onPaymentSuccess = () => {
    setIsPaymentModalOpen(false);
    setIsOpenConfirmationModal(true);
  };

  return (
    <>
      <Button onClick={onClickResumeButton} size="sm">
        {t('Resume')}
      </Button>
      <PaymentMethodModal
        isOpen={isPaymentModalOpen}
        onClose={() => setIsPaymentModalOpen(false)}
        onSuccess={onPaymentSuccess}
      />
      <ConfirmationModal
        Cancel={(
          <Button
            disabled={saving}
            onClick={() => setIsOpenConfirmationModal(false)}
            variant="secondary"
          >
            {t('No')}
          </Button>
        )}
        Confirm={(
          <Button
            disabled={saving}
            onClick={onConfirm}
          >
            {t('Yes')}
          </Button>
        )}
        isOpen={isOpenConfirmationModal}
        onClose={() => setIsOpenConfirmationModal(false)}
        Subtitle={(
          <span className="text-sm od-font-secondary-regular text-xs text-od-white-500 mt-3">
            {/* eslint-disable-next-line max-len */}
            {t('This action will resume your previous subscription and keep all of your target groups, schedules, and costs.')}
          </span>
        )}
        title={t('Resume subscription?')}
      />
    </>
  );
}

export default EvsTacticResumeButton;
