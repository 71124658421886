import React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from 'src/design/ui-kit/Button/Button';
import InfoCard from 'src/design/ui-kit/InfoCard/InfoCard';

function BillingInvoicesDetails() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // TODO: Use real invoices endpoint
  const invoices = [{}];

  return (
    <section>
      <h2 className="text-base mt-6">{t('Billing invoices')}</h2>

      <div className="grid grid-cols-3 gap-4 mt-4">
        <InfoCard description={t('Last invoice')}>
          $103 on Mar 30, 2023
        </InfoCard>
        <InfoCard description={t('Upcoming invoice')}>
          $103 on Apr 30, 2023
        </InfoCard>
        <InfoCard description={t('All invoices')}>
          {invoices.length > 0 ? (
            <Button
              onClick={() => navigate('/billing/invoices')}
              size="sm"
              variant="secondary"
            >
              {t('View')}
            </Button>
          ) : (t('No invoices available'))}
        </InfoCard>
      </div>
    </section>
  );
}

export default BillingInvoicesDetails;
