import React, { useEffect } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import DropdownArrow from 'src/components/common/DropdownArrow';
import Button from 'src/design/ui-kit/Button/Button';
import Loading from 'src/design/ui-kit/Loading/Loading';
import { sortAlphabetically } from 'src/helpers/sort';
import { TableColumnDefinition, TableSortState, toggleSortDirection } from 'src/hooks/useTable';

export default function TableView(props) {
  const {
    TableRow, className, columns, data,
    isLoadingMore, onLoadMore, onSortStateChange, pageInfo,
    rowProps, rowTemplate, showHead, sortState,
  } = props;

  const requestSort = (sortConfig) => {
    const prevSortDirection = sortState.sortDirection;
    // Don't toggle sortDirection if previously no sortKey selected
    // so sorting wasn't applied at all
    const newSortDirection = sortConfig.sortKey
      ? toggleSortDirection(prevSortDirection) : prevSortDirection;

    const newSortState = new TableSortState(
      sortConfig.sortKey,
      newSortDirection,
      sortConfig.sortFn({
        sortDirection: newSortDirection,
        sortKey: sortConfig.sortKey,
      }),
    );
    onSortStateChange(newSortState);
  };

  const { t } = useTranslation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      onLoadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const gridTemplateColumns = columns.map(({ gridTemplate }) => gridTemplate).join(' ');
  // gridTemplateRows consists of th + data rows
  const gridTemplateRows = `28px ${data.map(() => rowTemplate).join(' ')}`;

  return (
    <>
      <table
        className={classNames('TableView', className)}
        style={{
          gridTemplateColumns,
          gridTemplateRows,
        }}
      >
        <thead className={classNames({
          invisible: !showHead,
        })}
        >
          <tr>
            {columns.map((column) => (
              <th
                key={column.name}
                className={
                  classNames('od-font-secondary-medium px-0 py-2 flex', {
                    'cursor-pointer': column.sortable,
                  })
                }
                onClick={() => (column.sortable ? requestSort(column.sortConfig) : null)}
                style={{
                  justifyContent: column.justifyContent,
                }}
              >
                <div className="h-4 inline-flex items-center text-od-white-500 pb-3 gap-2">
                  {column.title}
                  {column.Icon}
                  {column.sortable && column.sortConfig.sortKey === sortState.sortKey && (
                    <div className="flex items-center mt-0.5">
                      <DropdownArrow
                        icon="arrow"
                        open={sortState.sortDirection === 'asc'}
                      />
                    </div>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((dataItem, index) => (
            <TableRow
              key={dataItem.id ? dataItem.id : index}
              data={dataItem}
              id={`TableRow${dataItem.id}`}
              {...rowProps}
            />
          ))}
        </tbody>
      </table>
      {pageInfo?.hasNextPage && !isLoadingMore && (
        <div
          ref={ref}
          className="flex justify-center mt-4"
        >
          <Button
            onClick={onLoadMore}
            size="sm"
          >
            {t('Load more')}
          </Button>
        </div>
      )}
      {isLoadingMore && (
        <Loading />
      )}
    </>
  );
}

TableView.propTypes = {
  TableRow: PropTypes.func.isRequired,
  className: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.instanceOf(TableColumnDefinition)).isRequired,
  data: PropTypes.array,
  isLoadingMore: PropTypes.bool,
  onLoadMore: PropTypes.func,
  onSortStateChange: PropTypes.func,
  pageInfo: PropTypes.object,
  rowProps: PropTypes.object,
  rowTemplate: PropTypes.string,
  showHead: PropTypes.bool,
  sortState: PropTypes.instanceOf(TableSortState),
};

TableView.defaultProps = {
  className: 'gap-y-2',
  data: [],
  isLoadingMore: false,
  onLoadMore: () => {},
  onSortStateChange: () => {},
  pageInfo: null,
  rowProps: {},
  rowTemplate: 'minmax(56px, max-content)',
  showHead: true,
  sortState: new TableSortState(
    '',
    'asc',
    sortAlphabetically((item, sortKey) => item[sortKey]),
  ),
};
