import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import useFormBuilder from 'src/hooks/useFormBuilder';

function useTargetGroupForm() {
  const { t } = useTranslation();

  const initialFormData = {
    name: '',
    targets: [],
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .required(t('Field is required')),
    targets: yup
      .array()
      .test(
        'notEmpty',
        t('At least 1 target is required'),
        (arr) => arr.length > 0,
      ),
  }).required();

  return useFormBuilder(initialFormData, validationSchema);
}

export default useTargetGroupForm;
