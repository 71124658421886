import i18n from 'i18next';

import { useCurrentUser } from 'src/contexts/useCurrentUser';
import { TableColumnDefinition } from 'src/hooks/useTable';

const useEvsTargetsColumns = () => {
  const { permissions } = useCurrentUser();
  return [
    new TableColumnDefinition({
      name: 'ip',
      title: i18n.t('IP'),
    }),
    new TableColumnDefinition({
      name: 'targetGroup',
      title: i18n.t('Target Group'),
    }),
    permissions.canManageEvsSubscription() ? new TableColumnDefinition({
      gridTemplate: 'max-content',
      name: 'deleteButton',
    }) : null,
  ].filter(Boolean);
};

export default useEvsTargetsColumns;
