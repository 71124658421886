import React from 'react';

import { useCurrentCompany } from 'src/contexts/useCurrentCompany';
import { useMSPBilling } from 'src/contexts/useMSPBilling';
import EvsTacticResumeButton from 'src/views/TacticsSettings/EvsTactic/EvsTacticResumeButton';
import EvsTacticSubscribeButton from 'src/views/TacticsSettings/EvsTactic/EvsTacticSubscribeButton';
import EvsTacticUnsubscribeButton from 'src/views/TacticsSettings/EvsTactic/EvsTacticUnsubscribeButton';

function EvsTacticActionButton() {
  const { evsSubscriptionStatus } = useCurrentCompany();
  const { loading } = useMSPBilling();

  if (loading) {
    return null;
  }
  if (evsSubscriptionStatus === 'active') {
    return <EvsTacticUnsubscribeButton />;
  }
  if (evsSubscriptionStatus === 'expiring') {
    return <EvsTacticResumeButton />;
  }
  return <EvsTacticSubscribeButton />;
}

export default EvsTacticActionButton;
