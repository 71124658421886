import React, { useState } from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useSetDomainsMutation from 'src/api/mutations/useSetDomainsMutation';
import ConfirmationModal from 'src/components/modals/ConfirmationModal';
import { DOMAINS_UPDATED } from 'src/constants/events';
import Button from 'src/design/ui-kit/Button/Button';
import { deleteAt } from 'src/helpers/array';
import useEmitter from 'src/hooks/useEmitter';
import useToast from 'src/hooks/useToast';
import useUserParams from 'src/hooks/useUserParams';

function DeleteDomainButton(props) {
  const { domain, domains } = props;
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);

  const { companyId } = useUserParams();

  const { t } = useTranslation();

  const emitter = useEmitter();

  const { showSuccessMessage } = useToast();

  const [setDomains, { loading: saving }] = useSetDomainsMutation({
    onCompleted() {
      emitter.emit(DOMAINS_UPDATED);
      showSuccessMessage(t('Domain "{{domain}}" deleted successfully', { domain }));
      setIsOpenConfirmationModal(false);
    },
  });

  const onDelete = async () => {
    const currentIndex = domains.indexOf(domain);
    const updatedDomains = deleteAt(currentIndex, domains);

    await setDomains({
      variables: {
        companyId,
        domains: updatedDomains,
      },
    });
  };

  return (
    <>
      <Button
        onClick={() => setIsOpenConfirmationModal(true)}
        variant="text"
      >
        <FontAwesomeIcon icon={light('trash')} />
      </Button>
      <ConfirmationModal
        Cancel={(
          <Button
            disabled={saving}
            onClick={() => setIsOpenConfirmationModal(false)}
            variant="secondary"
          >
            {t('No')}
          </Button>
        )}
        Confirm={(
          <Button
            disabled={saving}
            onClick={onDelete}
          >
            {t('Yes')}
          </Button>
        )}
        isOpen={isOpenConfirmationModal}
        onClose={() => setIsOpenConfirmationModal(false)}
        subtitle={t('Are you sure that you want to delete domain "{{domain}}"?', { domain })}
        title={t('Delete domain')}
      />
    </>
  );
}

DeleteDomainButton.propTypes = {
  domain: PropTypes.string.isRequired,
  domains: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DeleteDomainButton;
