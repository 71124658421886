import { gql } from '@apollo/client';

export const SETUP_PAYMENT_SOURCE_MUTATION = gql`
  mutation ($token: String, $customer: ID!, $email: String!) {
    setupPaymentSource(input: {
      token: $token
      customer: $customer
      email: $email
    }) {
      billingInformation {
        email
        isCardWorking
      }
    }
  }
`;

export const MSP_BILLING_DETAILS = gql`
  query mspBillingDetails($id: ID!) {
    mspBillingDetails(id: $id) {
      totalCompanies
      totalMonthlyCost
      paymentSource {
        isCardWorking
        ccLastDigits
        email
      }
      companies {
        company {
          id
          name
        }
        cost
        isSubscribed
        nextPayment {
          cost
          date
        }
        lastPayment {
          cost
          date
        }
      }
    }
  }
`;

export const MSP_BILLING_COMPANY_DETAILS = gql`
  query mspBillingCompanyDetails($id: ID!) {
    mspBillingCompanyDetails(id: $id) {
      company {
        id
        name
      }
      nextPayment {
        cost
        date
      }
      cost
      evsSubscription {
        id
        isActive
        expiresAt
        targetCount
      }
    }
  }
`;
