import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DomainColumnChart from 'src/components/charts/DomainColumnChart/DomainColumnChart';
import Page from 'src/components/common/Page';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import IconBreachCredentials from 'src/design/custom-icons/IconBreachCredentials/IconBreachCredentials';
import Button from 'src/design/ui-kit/Button/Button';
import EmptyState from 'src/design/ui-kit/EmptyState/EmptyState';
import Loading from 'src/design/ui-kit/Loading/Loading';
import useUserParams from 'src/hooks/useUserParams';

import AllDomainAnalyticsSection from './AllDomainAnalyticsSection';
import ExportButton from './ExportButton';
import PerDomainAnalyticsTableView from './PerDomainAnalyticsTableView/PerDomainAnalyticsTableView';

function BreachCredentials(props) {
  const { company } = props;
  const { companyId } = useUserParams();
  const { t } = useTranslation();
  const { userConfig } = useCurrentUser();

  // TODO: remove this check once there are subscriptions
  const hasSubscriptions = company?.activeSubscriptions.length > 0 || true;
  const loading = false;
  const { bcStatistics } = company;

  if (loading) {
    return (
      <Page title={t('Breach Credentials Dashboard')}>
        <Loading />
      </Page>
    );
  }

  if (!hasSubscriptions || !bcStatistics) {
    return (
      <Page title={t('Breach Credentials Dashboard')}>
        <EmptyState
          subtitle={t('To set up your first tactic, click the "Open tactics" button below!')}
          title={t("Here's where you'll see an overview of your breach credentials")}
        >
          <Button link={userConfig.buildUrl('/tactics-settings', `/${companyId}`)}>
            {t('Open Tactics')}
          </Button>
        </EmptyState>
      </Page>
    );
  }

  return (
    <Page className="grid gap-10" title={t('Breach Credentials Dashboard')}>
      <header className="grid grid-cols-[max-content_auto_max-content] gap-4 items-center">
        <IconBreachCredentials />
        <h1 className="flex-1">{t('Breach Credentials Dashboard')}</h1>
        <ExportButton />
      </header>

      <section>
        <div className="od-font-title-semi-bold mb-6">
          {t('All domain analytics')}
        </div>
        <AllDomainAnalyticsSection bcStatistics={bcStatistics} />
      </section>

      <DomainColumnChart
        bcStatistics={bcStatistics}
        title={t('Records by Month')}
      />

      <section className="grid gap-8">
        <div className="od-font-title-semi-bold">
          {t('Per domain analytics')}
        </div>
        <PerDomainAnalyticsTableView bcStatistics={bcStatistics} />
      </section>
    </Page>
  );
}

BreachCredentials.defaultProps = {
  company: null,
};

BreachCredentials.propTypes = {
  company: PropTypes.object,
};

export default BreachCredentials;
