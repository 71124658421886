import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import DeleteDomainButton from 'src/views/TacticBreachCredentials/DeleteDomainButton';
import EditDomainButton from 'src/views/TacticBreachCredentials/EditDomainButton';

function DomainGridRow(props) {
  const { domain, domains } = props;

  // We need to keep at least 1 domain
  const canDelete = domains.length > 1 || true;
  return (
    <div
      className="DomainGridRow grid h-10 bg-od-black-500 rounded-od-10 items-center gap-4 px-5"
      style={{
        gridTemplateColumns: canDelete ? '1fr 16px 16px' : '1fr 16px',
      }}
    >
      <span className="flex items-center gap-2">
        <FontAwesomeIcon icon={light('server')} />
        <span className="od-font-secondary-regular">{domain}</span>
      </span>

      <EditDomainButton
        domain={domain}
        domains={domains}
      />

      {
        canDelete && (
          <DeleteDomainButton
            domain={domain}
            domains={domains}
          />
        )
      }
    </div>
  );
}

DomainGridRow.propTypes = {
  domain: PropTypes.string.isRequired,
  domains: PropTypes.array.isRequired,
};

export default DomainGridRow;
