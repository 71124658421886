/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';

import PropTypes from 'prop-types';

import Modal from 'src/components/modals/Modal';

// TODO: Add storybook example
function ConfirmationModal(props) {
  const {
    Cancel, Confirm, Subtitle, isOpen, onClose, subtitle, title,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      shouldCloseOnOverlayClick={false}
      Subtitle={Subtitle}
      subtitle={subtitle}
      title={title}
    >
      <div className="ConfirmationModal__actions flex gap-4 items-center justify-end">
        {Cancel}
        {Confirm}
      </div>
    </Modal>
  );
}

ConfirmationModal.defaultProps = {
  Subtitle: null,
  subtitle: '',
};

ConfirmationModal.propTypes = {
  Cancel: PropTypes.object.isRequired,
  Confirm: PropTypes.object.isRequired,
  Subtitle: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default ConfirmationModal;
