import React from 'react';

import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Page from 'src/components/common/Page';
import { CurrentUserProvider, useCurrentUser } from 'src/contexts/useCurrentUser';
import { CurrentUserNotificationsProvider } from 'src/contexts/useCurrentUserNotifications';
import Loading from 'src/design/ui-kit/Loading/Loading';
import AuditorRoutes from 'src/routes/AuditorRoutes';
import CompanyAdminRoutes from 'src/routes/CompanyAdminRoutes';
import GlobalAdminRoutes from 'src/routes/GlobalAdminRoutes';
import MSPAdminRoutes from 'src/routes/MSPAdminRoutes';
import MSPAnalystRoutes from 'src/routes/MSPAnalystRoutes';
import { USER_TYPES } from 'src/users';
import RequestResetPassword from 'src/views/Auth/RequestResetPassword/RequestResetPassword';
import ResetPassword from 'src/views/Auth/ResetPassword/ResetPassword';
import SignIn from 'src/views/Auth/SignIn/SignIn';
import SignUpCompany from 'src/views/Auth/SignUp/SignUpCompany';
import VerifyEmail from 'src/views/Auth/VerifyEmail';

import 'react-toastify/dist/ReactToastify.css';

// TODO: Refactor i18n to remove escape chars, use combination of "" ''
// TODO: CompanyDashboard BC overview: fix $since params
// TODO: Export: Add message the export is loading

function UserRoutes() {
  const { me, userType } = useCurrentUser();
  const { t } = useTranslation();

  if (userType === USER_TYPES.GLOBAL_ADMIN) {
    return (
      <GlobalAdminRoutes />
    );
  }

  if (userType === USER_TYPES.MSP_ADMIN && me?.msp) {
    return (
      <MSPAdminRoutes />
    );
  }

  if (userType === USER_TYPES.MSP_ANALYST && me?.msp) {
    return (
      <MSPAnalystRoutes />
    );
  }

  if (userType === USER_TYPES.COMPANY_ADMIN && me?.company) {
    return (
      <CompanyAdminRoutes />
    );
  }

  if (userType === USER_TYPES.AUDITOR && me?.company) {
    return (
      <AuditorRoutes />
    );
  }

  return (
    <Routes>
      <Route
        element={(
          <Page title={t('Loading')}>
            <Loading />
          </Page>
        )}
        path="/"
      />
    </Routes>
  );
}

function App() {
  return (
    <CurrentUserProvider>
      <ToastContainer style={{
        width: 'auto',
      }}
      />
      <div className="App w-full h-full">
        <Routes>
          <Route
            element={(
              <CurrentUserNotificationsProvider>
                <UserRoutes />
              </CurrentUserNotificationsProvider>
            )}
            path="/*"
          />

          {/*  AUTH ROUTES */}
          <Route path="auth">
            <Route element={<SignIn />} path="sign-in" />
            <Route element={<SignUpCompany />} path="sign-up/company" />
            {/* <Route element={<SignUpUser />} path="auth/sign-up/user" /> */}
            <Route element={<RequestResetPassword />} path="request-reset-password" />
            <Route element={<ResetPassword />} path="password-reset/:uid" />
            <Route element={<VerifyEmail />} path="verify-email" />
          </Route>
        </Routes>
      </div>
    </CurrentUserProvider>
  );
}

export default App;
