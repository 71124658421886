import { useTranslation } from 'react-i18next';

import colors from 'src/design/theme/colors';

export const VULNERABILITY_STATUS_NEW = 'newVulnerabilities';

export const VULNERABILITY_STATUS_REMEDIATED = 'remediatedVulnerabilities';

export const VULNERABILITY_STATUS_CURRENT = 'currentVulnerabilities';

export const SEVERITY_CRITICAL = 4;

export const SEVERITY_HIGH = 3;

export const SEVERITY_MEDIUM = 2;

export const SEVERITY_LOW = 1;

export const SEVERITY_INFO = 0;

export function getVulnerabilitiesTotalByStatus({ showInfo, vulnerabilities }) {
  const total = vulnerabilities.length;
  if (showInfo) {
    return total;
  }

  return total - vulnerabilities.filter(({ severity }) => severity === SEVERITY_INFO).length;
}

export function useVulnerabilityStatuses({ scan, showInfo }) {
  const { t } = useTranslation();
  return [
    {
      id: VULNERABILITY_STATUS_CURRENT,
      text: t('Current vulnerabilities {{count}}', { count: getVulnerabilitiesTotalByStatus({
        showInfo,
        vulnerabilities: scan[VULNERABILITY_STATUS_CURRENT],
      }) }),
    },
    {
      id: VULNERABILITY_STATUS_NEW,
      text: t('New vulnerabilities {{count}}', { count: getVulnerabilitiesTotalByStatus({
        showInfo,
        vulnerabilities: scan[VULNERABILITY_STATUS_NEW],
      }) }),
    },
    {
      id: VULNERABILITY_STATUS_REMEDIATED,
      text: t('Remediated vulnerabilities {{count}}', { count: getVulnerabilitiesTotalByStatus({
        showInfo,
        vulnerabilities: scan[VULNERABILITY_STATUS_REMEDIATED],
      }) }),
    },
  ];
}

export function useScanVulnerabilitySeverityDefinitions({ showInfo }) {
  const { t } = useTranslation();
  const types = [
    {
      color: colors['od-complementary-critical'],
      key: 'critical',
      name: t('Critical'),
      severity: SEVERITY_CRITICAL,
    },
    {
      color: colors['od-complementary-high'],
      key: 'high',
      name: t('High'),
      severity: SEVERITY_HIGH,
    },
    {
      color: colors['od-complementary-medium'],
      key: 'medium',
      name: t('Medium'),
      severity: SEVERITY_MEDIUM,

    },
    {
      color: colors['od-complementary-low'],
      key: 'low',
      name: t('Low'),
      severity: SEVERITY_LOW,
    },
  ];
  if (showInfo) {
    types.push({
      color: colors['od-complementary-info'],
      key: 'info',
      name: t('Info'),
      severity: SEVERITY_INFO,
    });
  }
  return types;
}
