/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import debounce from 'lodash-es/debounce';
import { useTranslation } from 'react-i18next';

import { COMPANIES_QUERY, transformResponse } from 'src/api/companies';
import NoResultMessage from 'src/components/common/NoResultMessage';
import Page from 'src/components/common/Page';
import TableView from 'src/components/common/Table/TableView';
import { COMPANY_ADDED, COMPANY_EDITED } from 'src/constants/events';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import InputSearch from 'src/design/ui-kit/InputSearch/InputSearch';
import Loading from 'src/design/ui-kit/Loading/Loading';
import Switch from 'src/design/ui-kit/Switch/Switch';
import highlightElement from 'src/helpers/highlightElement';
import { sortAlphabetically } from 'src/helpers/sort';
import useEmitter from 'src/hooks/useEmitter';
import { TableSortState } from 'src/hooks/useTable';
import AddCompanyButton from 'src/views/Companies/AddCompanyButton';
import CompaniesEmptyState from 'src/views/Companies/CompaniesEmptyState';
import CompaniesTableViewRow from 'src/views/Companies/CompaniesTableViewRow';
import useCompanyColumns from 'src/views/Companies/useCompanyColumns';

const first = 20;

const initialSortState = new TableSortState(
  'name',
  'asc',
  sortAlphabetically((company) => company.name)({
    sortDirection: 'asc',
    sortKey: 'name',
  }),
);

function Companies() {
  const [searchValue, setSearchValue] = useState('');
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [hasFetchedFirstTime, setHasFetchedFirstTime] = useState(false);

  const { permissions } = useCurrentUser();
  const columns = useCompanyColumns(permissions);
  const [sortState, setSortState] = useState(initialSortState);

  const { t } = useTranslation();
  const emitter = useEmitter();

  const getVariables = () => ({
    enabled: !showArchived,
    first,
    ...sortState.sortKey === 'name' && {
      orderBy: sortState.sortDirection === 'asc' ? 'name' : '-name',
    },
    name_Icontains: searchValue,
  });

  const {
    data,
    error,
    fetchMore,
    refetch,
  } = useQuery(COMPANIES_QUERY, {
    onCompleted() {
      setHasFetchedFirstTime(true);
    },
    variables: getVariables(),
  });

  const { items: companies, pageInfo } = transformResponse(data);

  const handleRefetch = async (v = getVariables()) => {
    setIsRefetching(true);
    await refetch(v);
    setIsRefetching(false);
  };

  useEffect(() => {
    const onEnabledChange = async (newShowArchived) => {
      setShowArchived(newShowArchived);
      await handleRefetch({
        ...getVariables(),
        enabled: !newShowArchived,
      });
    };
    onEnabledChange(showArchived);
  }, [showArchived]);

  // Refetch companies if a company was created/updated/deleted
  useEffect(() => {
    emitter.on(COMPANY_ADDED, async ({ createCompany: { company } }) => {
      await handleRefetch();
      // Highlight added company
      setTimeout(() => highlightElement(`TableRow${company.id}`));
    });
    emitter.on(COMPANY_EDITED, handleRefetch);

    return () => {
      emitter.off(COMPANY_ADDED);
      emitter.off(COMPANY_EDITED);
    };
  });

  if (error || !hasFetchedFirstTime) {
    return (
      <Page title={t('Loading')}>
        <Loading />
      </Page>
    );
  }

  const onSearchInput = debounce((value) => {
    setSearchValue(value);
    handleRefetch({
      ...getVariables(),
      name_Icontains: value,
    });
  });

  const onLoadMore = async () => {
    setIsLoadingMore(true);
    await fetchMore({
      variables: {
        ...getVariables(),
        after: pageInfo.endCursor,
      },
    });
    setIsLoadingMore(false);
  };

  const onSortStateChange = async (newSortState) => {
    setSortState(newSortState);
    await handleRefetch({
      ...getVariables(),
      ...newSortState.sortKey === 'name' && {
        orderBy: newSortState.sortDirection === 'asc' ? 'name' : '-name',
      },
    });
  };

  return (
    <Page title={t('Companies')}>
      <div className="flex items-center gap-4">
        <div className="flex-1">
          <InputSearch
            onInput={onSearchInput}
            placeholder={t('Search by companies')}
            value={searchValue}
          />
        </div>
        <Switch
          checked={showArchived}
          id="Companies__archived-toggle"
          label={t('Show archived')}
          onChange={setShowArchived}
        />
        {permissions.canManageCompany() && companies.length > 0 && (
          <AddCompanyButton />
        )}
      </div>
      {companies.length === 0 && searchValue && (
        <div className="mt-6 od-font-main-semi-bold text-od-white-500">
          <NoResultMessage searchValue={searchValue} />
        </div>
      )}
      {companies.length === 0 && !searchValue && (
        <CompaniesEmptyState
          permissions={permissions}
          showArchived={showArchived}
        />
      )}
      <div className="mt-8">
        <TableView
          columns={columns}
          data={isRefetching ? [] : companies}
          isLoadingMore={isLoadingMore || isRefetching}
          onLoadMore={onLoadMore}
          onSortStateChange={onSortStateChange}
          pageInfo={pageInfo}
          showHead={companies.length > 0}
          sortState={sortState}
          TableRow={CompaniesTableViewRow}
        />
      </div>
    </Page>
  );
}

export default Companies;
