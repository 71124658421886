import React, { useRef } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import FormControl from 'src/components/common/FormControl';
import Modal from 'src/components/modals/Modal';
import Button from 'src/design/ui-kit/Button/Button';
import Input from 'src/design/ui-kit/Input/Input';
import useValidationHelpers from 'src/hooks/useValidationHelpers';

const schema = () => yup.object({
  days: yup
    .number()
    .required(i18n.t('Field is required')),
}).required();

const initialFormData = (days) => ({
  days,
});

function RecentTagHoldingTimeModal(props) {
  const { isOpen, onClose } = props;
  const { t } = useTranslation();

  const formRef = useRef();

  const { formState, handleSubmit, register, reset } = useForm({
    defaultValues: initialFormData(),
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema()),
  });

  const handleClose = () => {
    onClose();
    setTimeout(reset);
  };

  const { getFieldError } = useValidationHelpers({
    formState,
  });

  const onSubmit = async (submitData, event) => {
    event.preventDefault();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      shouldFocusFirstFocusable
      subtitle={t('Please enter the amount of days')}
      title={t('Change recent holding time')}
    >
      <form
        ref={formRef}
        className="mt-12"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormControl
          error={getFieldError('days')}
          htmlFor="RecentTagHoldingTimeModal__days"
          label={t('Days')}
        >
          <Input
            error={getFieldError('email')}
            id="RecentTagHoldingTimeModal__days"
            name="days"
            placeholder={t('Days')}
            register={register}
          />
        </FormControl>
        <div className="flex justify-end mt-10">
          <Button
            disabled={!formState.isValid}
            type="submit"
          >
            {t('Save changes')}
          </Button>
        </div>
      </form>
    </Modal>
  );
}

RecentTagHoldingTimeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RecentTagHoldingTimeModal;
