import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import SidebarModal from 'src/components/modals/SidebarModal';
import { BILLING_INFORMATION_UPDATED } from 'src/constants/events';
import { useMSPBilling } from 'src/contexts/useMSPBilling';
import useEmitter from 'src/hooks/useEmitter';
import CardForm from 'src/views/Billing/ChangePaymentMethod/CardForm';

function PaymentMethodModal(props) {
  const { isOpen, onClose, onSuccess } = props;
  const { t } = useTranslation();
  const emitter = useEmitter();
  const { mspBillingDetails: { paymentSource }, refetch } = useMSPBilling();

  const handleClose = () => {
    onClose();
  };

  const { email, isCardWorking } = paymentSource;

  const onSave = () => {
    if (onSuccess) {
      onSuccess();
    } else {
      emitter.emit(BILLING_INFORMATION_UPDATED);
      refetch();
    }
  };

  return (
    <SidebarModal
      contentClassName="h-full overflow-scroll"
      isOpen={isOpen}
      onClose={handleClose}
      title={t('Set payment method')}
    >
      <CardForm
        email={email}
        onSave={onSave}
        showEmailField={!isCardWorking || !email}
      />
    </SidebarModal>
  );
}

PaymentMethodModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};

PaymentMethodModal.defaultProps = {
  onSuccess: null,
};

export default PaymentMethodModal;
