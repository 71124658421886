import { gql } from '@apollo/client';

export const BREACH_CREDENTIALS_QUERY = gql`
  query allBreachedCredentials($first: Int!, $after: String, $companyId: ID!, $domain: String) {
    allBreachedCredentials(
      first: $first
      after: $after
      company: $companyId
      domain: $domain
    ) @connection(key: "id") {
      edges {
        node {
          id
          addedAt
          breach {
            name
          }
          domain
          password
          username
          ipAddress
          allData
          userId
          emailAddress
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

// TODO: Fix $since, period: $period for recordCount()
export const BREACH_CREDENTIALS_STATISTICS = gql`
  query bcStatistics($companyId: ID!) {
    bcStatistics(company: $companyId) {
      breachCount
      recordCount
      credentialSetCount
      domainCount
      impactedUserCount
      domains {
        name
        addedAt
        breachCount
        recordCount
        credentialSetCount
        impactedUserCount
        recordsPerMonth {
          date
          records
        }
      }
    }
  }
`;

export function transformResponse(data) {
  if (!data) {
    return {
      items: [],
      pageInfo: {
        endCursor: null,
        hasNextPage: false,
      },
    };
  }
  return {
    items: data.allBreachedCredentials.edges.map((edge) => edge.node),
    pageInfo: data.allBreachedCredentials.pageInfo,
  };
}

export const BCI_DOMAINS = gql`
  query bciDomains($companyId: ID!) {
    bciDomains(company: $companyId)
  }
`;

export const BCI_SET_DOMAINS_MUTATION = gql`
  mutation($companyId: ID!, $domains: [String!]!) {
    bciSetDomains(input: {
      company: $companyId
      domains: $domains
    }) {
      domains
    }
  }
`;

export const EXPORT_BREACHES_MUTATION = gql`
  mutation($companyId: ID!) {
    exportBreachesCsv(input: {
      company: $companyId
    }) {
      url
    }
  }
`;
