import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import colors from 'src/design/theme/colors';
import InfoCardVisual from 'src/design/ui-kit/InfoCardVisual/InfoCardVisual';

function CompanyDashboardVulnerabilityScanningOverviewTotal(props) {
  const { scanStatistics } = props;
  const { t } = useTranslation();

  return (
    <ul className="DashboardVulnerabilityScanningOverviewTotal mt-5 grid lg:grid-cols-4 gap-3">
      <InfoCardVisual
        color={colors['od-status-box-previous-bg']}
        description={t('Previous')}
        Icon={(
          <FontAwesomeIcon
            className="rotate-90"
            icon={light('arrow-up-to-dotted-line')}
            size="xl"
          />
        )}
        tag="li"
      >
        {scanStatistics.currentCount
          + scanStatistics.remediatedCount
          - scanStatistics.newCount}
      </InfoCardVisual>
      <InfoCardVisual
        color={colors['od-status-box-new-bg']}
        description={t('New')}
        Icon={(
          <FontAwesomeIcon
            icon={light('arrow-up-to-dotted-line')}
            size="xl"
          />
        )}
        tag="li"
      >
        {scanStatistics.newCount}
      </InfoCardVisual>
      <InfoCardVisual
        color={colors['od-status-box-resolved-bg']}
        description={t('Resolved')}
        Icon={(
          <FontAwesomeIcon
            className="rotate-180"
            icon={light('arrow-up-to-dotted-line')}
            size="xl"
          />
        )}
        tag="li"
      >
        {scanStatistics.remediatedCount}
      </InfoCardVisual>
      <InfoCardVisual
        color={colors['od-status-box-current-bg']}
        description={t('Resolved')}
        Icon={(
          <FontAwesomeIcon
            icon={light('chart-scatter')}
            size="xl"
          />
        )}
        tag="li"
      >
        {scanStatistics.currentCount}
      </InfoCardVisual>
    </ul>
  );
}

CompanyDashboardVulnerabilityScanningOverviewTotal.propTypes = {
  scanStatistics: PropTypes.object.isRequired,
};

export default CompanyDashboardVulnerabilityScanningOverviewTotal;
