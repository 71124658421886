import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import useDomainsLoader from 'src/api/loaders/useDomainsLoader';
import GoBackLink from 'src/components/common/GoBackLink';
import Page from 'src/components/common/Page';
import { MAX_DOMAINS_COUNT } from 'src/constants/domains';
import { DOMAINS_UPDATED } from 'src/constants/events';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import IconBreachCredentials from 'src/design/custom-icons/IconBreachCredentials/IconBreachCredentials';
import PirateGif from 'src/design/images/pirate.gif';
import Loading from 'src/design/ui-kit/Loading/Loading';
import useEmitter from 'src/hooks/useEmitter';
import useUserParams from 'src/hooks/useUserParams';
import AddDomainsButton from 'src/views/TacticBreachCredentials/AddDomains/AddDomainsButton';
import DomainGridRow from 'src/views/TacticBreachCredentials/DomainGridRow';
import TacticBreachCredentialsDetails from 'src/views/TacticBreachCredentials/TacticBreachCredentialsDetails';

function TacticBreachCredentials() {
  const { t } = useTranslation();
  const { companyId } = useUserParams();
  const emitter = useEmitter();
  const { userConfig } = useCurrentUser();

  const variables = {
    companyId,
  };

  const { data: { items: bciDomains }, loading, refetch } = useDomainsLoader({
    variables,
  });

  // Refetch domains on update
  useEffect(() => {
    emitter.on(DOMAINS_UPDATED, async () => {
      await refetch(variables);
    });

    return () => {
      emitter.off(DOMAINS_UPDATED);
    };
  });

  if (loading) {
    return (
      <Page title={t('Loading')}>
        <Loading />
      </Page>
    );
  }

  return (
    <Page
      className="Page TacticsBreachCredentials"
      title={t('Breach Credential')}
    >
      <header>
        <h1 className="flex gap-2 items-center mb-4">
          <IconBreachCredentials />
          {t('Breached Credential Detection')}
        </h1>

        <GoBackLink to={userConfig.buildUrl('/tactics-settings', `/${companyId}`)}>
          {t('All tactics')}
        </GoBackLink>
      </header>

      <TacticBreachCredentialsDetails bciDomains={bciDomains} />

      <section className="flex items-center justify-between mt-10">
        <h5 className="od-font-title-semi-bold">
          {t('Domains')}
        </h5>
        {bciDomains.length < MAX_DOMAINS_COUNT && (
          <AddDomainsButton />
        )}
      </section>

      <section className="mt-8">
        {bciDomains.length > 0 ? (
          <div className="TacticsBreachCredentials__Domains grid grid-cols-1 gap-y-2">
            {bciDomains.map((domain) => (
              <DomainGridRow
                key={domain}
                domain={domain}
                domains={bciDomains}
              />
            ))}
          </div>
        ) : (
          <div className="text-sm text-center">
            <img
              alt={t('Loading')}
              className="block mx-auto my-4 w-[180px]"
              src={PirateGif}
            />
            {t('There are no domains. Click "Add domain" button to create a first domain')}
          </div>
        )}
      </section>
    </Page>
  );
}

export default TacticBreachCredentials;
