import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from 'src/design/ui-kit/Button/Button';
import ChangeBillingEmailModal from 'src/views/Billing/ChangeBillingEmail/ChangeBillingEmailModal';

function ChangeBillingEmailButton(props) {
  const { email } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        size="sm"
        variant="secondary"
      >
        {email ? t('Change') : t('Set email')}
      </Button>
      <ChangeBillingEmailModal
        email={email}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}

ChangeBillingEmailButton.defaultProps = {
  email: null,
};

ChangeBillingEmailButton.propTypes = {
  email: PropTypes.string,
};

export default ChangeBillingEmailButton;
