import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useCurrentCompany } from 'src/contexts/useCurrentCompany';
import { formatDate } from 'src/helpers/date';

const statuses = ['active', 'inactive', 'expiring'];

function SubscriptionIndicator(props) {
  const { status } = props;
  const { companyEvsSubscription } = useCurrentCompany();

  const { t } = useTranslation();
  // eslint-disable-next-line max-len
  const expirationMessage = () => t('This subscription has been deactivated. All data will be lost on {{date}} unless the subscription is resumed.', {
    date: formatDate(companyEvsSubscription.expiresAt),
  });

  return (
    <div className={classNames('flex gap-2 items-center od-font-secondary-semi-bold', {
      'text-od-complementary-bad': status === 'inactive',
      'text-od-complementary-good': status === 'active',
      'text-od-complementary-medium': status === 'expiring',
    })}
    >
      <div className={classNames('w-2 h-2 rounded-full shrink-0', {
        'bg-od-complementary-bad': status === 'inactive',
        'bg-od-complementary-good': status === 'active',
        'bg-od-complementary-medium': status === 'expiring',
      })}
      />
      <div className="max-w-md">
        {status === 'active' && t('Active')}
        {status === 'inactive' && t('Inactive')}
        {status === 'expiring' && expirationMessage()}
      </div>
    </div>
  );
}

SubscriptionIndicator.propTypes = {
  status: PropTypes.oneOf(statuses).isRequired,
};

export default SubscriptionIndicator;
