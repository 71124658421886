import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from 'src/components/modals/Modal';
import Button from 'src/design/ui-kit/Button/Button';

const supportedFormats = ['csv'];

function ExportModal(props) {
  const { isOpen, loading, onClose, onExport } = props;

  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      shouldFocusFirstFocusable
      subtitle={loading ? '' : t('Please select the export file format you prefer below')}
      title={t('Export')}
    >
      {loading ? (
        <div className="flex justify-center">
          <p>{t('Export will download soon, do not close this window')}</p>
        </div>
      ) : (
        <div className={classNames('flex justify-center gap-4 -mt-4')}>
          {supportedFormats.map((format) => (
            <Button
              key={format}
              className="inline-flex items-center justify-center w-auto px-20"
              onClick={onExport}
            >
              {format}
            </Button>
          ))}
        </div>
      )}
    </Modal>
  );
}

ExportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
};

export default ExportModal;
