import { useEffect } from 'react';

import { useQuery } from '@apollo/client';

import { EVS_PRICES, EVS_SUBSCRIPTION_FOR_COMPANY } from 'src/api/evsSubscription';
import { EVS_TARGET_GROUPS, SCAN_STATISTICS, transformResponse } from 'src/api/vulnerabilities';
import { useCurrentUser } from 'src/contexts/useCurrentUser';

const first = 100;
const scansFirst = 10;
const targetsFirst = 10;

const FETCH_SCANS_INTERVAL = 60 * 1000; // 1 min

function getTacticStatus(companyEvsSubscription) {
  if (companyEvsSubscription && companyEvsSubscription.isActive) {
    if (companyEvsSubscription.expiresAt) {
      return 'expiring';
    }
    return 'active';
  }
  return 'inactive';
}

export default function useVulnerabilities({ companyId, mspId }) {
  const { permissions } = useCurrentUser();
  const defaultVariables = {
    companyId,
  };
  const targetGroupsVariables = {
    ...defaultVariables,
    first,
    scansFirst,
    targetsFirst,
  };
  const evsPricesVariables = {
    customer: mspId || companyId,
  };

  const companyEvsSubscriptionQuery = useQuery(EVS_SUBSCRIPTION_FOR_COMPANY, { variables: defaultVariables });
  const scanStatisticsQuery = useQuery(SCAN_STATISTICS, { variables: defaultVariables });
  const evsTargetGroupsQuery = useQuery(EVS_TARGET_GROUPS, { variables: targetGroupsVariables });
  const evsPricesQuery = useQuery(EVS_PRICES, {
    skip: !permissions.canViewPrices(),
    variables: evsPricesVariables,
  });

  const { items: evsTargetGroups } = transformResponse(evsTargetGroupsQuery.data);
  const { companyEvsSubscription } = companyEvsSubscriptionQuery.data || { companyEvsSubscription: null };
  const { scanStatistics } = scanStatisticsQuery.data || { scanStatistics: null };

  const error = companyEvsSubscriptionQuery.error
    || scanStatisticsQuery.error
    || evsTargetGroupsQuery.error
    || evsPricesQuery.error;
  const loading = companyEvsSubscriptionQuery.loading
    || scanStatisticsQuery.loading
    || evsTargetGroupsQuery.loading
    || evsPricesQuery.loading;

  const refetch = async () => {
    await Promise.all([
      companyEvsSubscriptionQuery.refetch(defaultVariables),
      scanStatisticsQuery.refetch(defaultVariables),
      evsTargetGroupsQuery.refetch(targetGroupsVariables),
      permissions.canViewPrices() ? evsPricesQuery.refetch(evsPricesVariables) : false,
    ].filter(Boolean));
  };

  const isScanRunning = evsTargetGroups.some((targetGroup) => targetGroup.isScanRunning);
  const basePrice = evsPricesQuery.data?.evsPrices?.basePrice ?? 0;
  const targetPrice = evsPricesQuery.data?.evsPrices?.targetPrice ?? 0;
  const evsSubscriptionStatus = getTacticStatus(companyEvsSubscription);

  useEffect(() => {
    let interval = null;
    if (isScanRunning) {
      interval = setInterval(refetch, FETCH_SCANS_INTERVAL);
    } else {
      clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScanRunning]);

  return {
    basePrice,
    companyEvsSubscription,
    error,
    evsSubscriptionStatus,
    evsTargetGroups,
    isScanRunning,
    loading,
    refetch,
    scanStatistics,
    targetPrice,
  };
}
