import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { DELETE_EVS_TARGET_GROUP_MUTATION } from 'src/api/vulnerabilities';
import ConfirmationModal from 'src/components/modals/ConfirmationModal';
import { EVS_SUBSCRIPTION_CHANGED, TARGET_GROUP_DELETED } from 'src/constants/events';
import Button from 'src/design/ui-kit/Button/Button';
import useEmitter from 'src/hooks/useEmitter';
import useToast from 'src/hooks/useToast';

function DeleteTargetGroupButton(props) {
  const { targetGroup } = props;
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);

  const { t } = useTranslation();

  const emitter = useEmitter();

  const { showSuccessMessage } = useToast();

  const [deleteTargetGroup, { loading: saving }] = useMutation(
    DELETE_EVS_TARGET_GROUP_MUTATION,
    {
      onCompleted() {
        emitter.emit(TARGET_GROUP_DELETED);
        emitter.emit(EVS_SUBSCRIPTION_CHANGED);
        showSuccessMessage(t('Target group deleted successfully'));
        setIsOpenConfirmationModal(false);
      },
    },
  );

  const onDelete = async () => {
    await deleteTargetGroup({
      variables: {
        targetGroup: targetGroup.id,
      },
    });
  };

  return (
    <>
      <Button
        onClick={() => setIsOpenConfirmationModal(true)}
        size="sm"
        variant="dangerous"
      >
        {t('Delete')}
      </Button>
      <ConfirmationModal
        Cancel={(
          <Button
            disabled={saving}
            onClick={() => setIsOpenConfirmationModal(false)}
            variant="secondary"
          >
            {t('No')}
          </Button>
        )}
        Confirm={(
          <Button
            disabled={saving}
            onClick={onDelete}
          >
            {t('Yes')}
          </Button>
        )}
        isOpen={isOpenConfirmationModal}
        onClose={() => setIsOpenConfirmationModal(false)}
        subtitle={t("Are you sure that you want to delete '{{ targetGroup }}'?", { targetGroup: targetGroup.name })}
        title={t('Delete target group')}
      />
    </>
  );
}

DeleteTargetGroupButton.propTypes = {
  targetGroup: PropTypes.object.isRequired,
};

export default DeleteTargetGroupButton;
