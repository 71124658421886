import React from 'react';

import { useTranslation } from 'react-i18next';

import LoadingProgress from 'src/design/ui-kit/LoadingProgress/LoadingProgress';

function ScanStatus() {
  const { t } = useTranslation();
  return (
    <div className="ScanProgress flex gap-2 items-center">
      <LoadingProgress />
      <div className="text-sm text-od-white-500">
        {t('Scan in progress')}
      </div>
    </div>
  );
}

export default ScanStatus;
