import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Popover } from 'react-tiny-popover';

import DropdownArrow from 'src/components/common/DropdownArrow';
import Nav, { NavItem } from 'src/components/common/Nav';
import Button from 'src/design/ui-kit/Button/Button';
import MonthView from 'src/design/ui-kit/DaysSelect/MonthView';
import WeekView from 'src/design/ui-kit/DaysSelect/WeekView';
import Input from 'src/design/ui-kit/Input/Input';

function DaysSelect(props) {
  const { onChange, placeholder, popoverProps, value } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [localValue, setLocalValue] = useState(value.days);
  const [localPeriod, setLocalPeriod] = useState(value.period);
  const triggerRef = useRef();
  const { t } = useTranslation();

  const onApply = () => {
    onChange({ days: localValue, period: localPeriod });
    setIsPopoverOpen(false);
  };

  const onCancel = () => {
    setLocalValue(value.days);
    setLocalPeriod(value.period);
    setIsPopoverOpen(false);
  };

  const navItems = [
    new NavItem({
      action() {
        if (localPeriod === 'MONTHLY') {
          return;
        }
        setLocalValue([]);
        setLocalPeriod('MONTHLY');
      },
      key: 'MONTHLY',
      name: t('Monthly'),
    }),
    new NavItem({
      action() {
        if (localPeriod === 'WEEKLY') {
          return;
        }
        setLocalValue([]);
        setLocalPeriod('WEEKLY');
      },
      key: 'WEEKLY',
      name: t('Weekly'),
    }),
  ];

  const getContent = ({ childRect }) => (
    <div
      className="bg-od-black-300 px-3 py-5"
      style={{
        width: `${childRect.width}px`,
      }}
    >
      <Nav
        active={localPeriod}
        className="grid grid-cols-2 w-full"
        navItems={navItems}
        variant="secondary"
      />
      <div className="mt-5">
        {
          localPeriod === 'MONTHLY' && (
            <MonthView
              onChange={setLocalValue}
              value={localValue}
            />
          )
        }
        {
          localPeriod === 'WEEKLY' && (
            <WeekView
              onChange={setLocalValue}
              value={localValue}
            />
          )
        }
      </div>
      <div className="grid grid-cols-2 gap-3 mt-6">
        <Button
          className="justify-center"
          onClick={onCancel}
          variant="secondary"
        >
          {t('Cancel')}
        </Button>
        <Button
          className="justify-center"
          disabled={localValue.length === 0}
          onClick={onApply}
        >
          {t('Apply')}
        </Button>
      </div>
    </div>
  );

  const inputValue = () => {
    if (localValue.length === 0) {
      return '';
    }
    if (localPeriod === 'MONTHLY') {
      return localValue.join(', ');
    }
    if (localPeriod === 'WEEKLY') {
      return t('Weekly ({{count}} days)', { count: localValue.length });
    }
    return '';
  };

  return (
    <Popover
      boundaryElement={triggerRef.current}
      content={getContent}
      isOpen={isPopoverOpen}
      onClickOutside={onCancel}
      padding={4}
      reposition
      {...popoverProps}
    >
      <Input
        ref={triggerRef}
        className="Input__control"
        InputIcon={<DropdownArrow open={isPopoverOpen} />}
        name="days"
        onClick={() => setIsPopoverOpen(true)}
        placeholder={placeholder}
        readOnly
        type="text"
        value={inputValue()}
      />
    </Popover>
  );
}

DaysSelect.defaultProps = {
  popoverProps: {},
};

DaysSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  popoverProps: PropTypes.object,
  value: PropTypes.object.isRequired,
};

export default DaysSelect;
