import React, { useState } from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import Button from 'src/design/ui-kit/Button/Button';
import CompanyModal from 'src/views/Companies/CompanyModal';

function EditCompanyButton(props) {
  const { company } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <Button
        onClick={() => setIsOpen(true)}
        variant="icon"
      >
        <FontAwesomeIcon icon={light('pen-to-square')} />
      </Button>
      <CompanyModal
        company={company}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
}

EditCompanyButton.propTypes = {
  company: PropTypes.object.isRequired,
};

export default EditCompanyButton;
