import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import Page from 'src/components/common/Page';
import IconBreachCredentials from 'src/design/custom-icons/IconBreachCredentials/IconBreachCredentials';
import IconNews from 'src/design/custom-icons/IconNews/IconNews';
import IconVulnerability from 'src/design/custom-icons/IconVulnerability/IconVulnerability';
import InfoCard from 'src/design/ui-kit/InfoCard/InfoCard';
import Loading from 'src/design/ui-kit/Loading/Loading';
import MSPDashboardNews from 'src/views/MSPDashboard/MSPDashboardNews/MSPDashboardNews';

// TODO: Add info icons with tooltips
function MSPDashboard() {
  const { t } = useTranslation();

  const loading = false;

  if (loading) {
    return (
      <Page title={t('Loading')}>
        <Loading />
      </Page>
    );
  }
  return (
    <Page title={t('MSP Dashboard')}>
      <h1 className="mb-4">{t('MSP Dashboard')}</h1>
      <section className="grid lg:grid-cols-3 gap-4 mt-4">
        <InfoCard description={t('Total companies')}>
          10
        </InfoCard>

        <InfoCard
          color="#25D272"
          description={t('MSP Admins')}
          Icon={<FontAwesomeIcon icon={light('code')} size="xl" />}
        >
          5
        </InfoCard>

        <InfoCard description={t('MSP Analysts')}>
          1
        </InfoCard>
      </section>

      <h2 className="mt-8 text-xl">{t('Global tactic statistics')}</h2>

      <h3 className="text-base flex gap-3 items-center mt-5">
        <IconVulnerability />
        <span>{t('External vulnerability scans')}</span>
      </h3>

      <section className="grid lg:grid-cols-4 gap-4 mt-4">
        <InfoCard
          description={t('Deployment')}
          tooltip={t('Deployment tooltip')}
        >
          50% (5)
        </InfoCard>

        <InfoCard description={t('Unscheduled groups')}>
          12
        </InfoCard>

        <InfoCard description={t('Hosts scanned this month')}>
          10,800
        </InfoCard>

        <InfoCard description={t('Exploitable vulnerabilities')}>
          69
        </InfoCard>
      </section>

      <h3 className="text-base flex gap-3 items-center mt-5">
        <IconBreachCredentials />
        <span>{t('Breached credentials')}</span>
      </h3>

      <section className="grid lg:grid-cols-4 gap-4 mt-4">
        <InfoCard description={t('Deployment')}>
          30% (3)
        </InfoCard>

        <InfoCard description={t('Total records')}>
          12,000,000
        </InfoCard>

        <InfoCard description={t('Total breaches')}>
          4,592
        </InfoCard>

        <InfoCard description={t('Hits this month')}>
          42
        </InfoCard>
      </section>

      <h3 className="text-base flex gap-3 items-center mt-5">
        <IconNews />
        <span>{t('News')}</span>
      </h3>

      <section>
        <MSPDashboardNews />
      </section>
    </Page>
  );
}

export default MSPDashboard;
