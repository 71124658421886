import React from 'react';

import { useTranslation } from 'react-i18next';

import Page from 'src/components/common/Page';
import { useCurrentCompany } from 'src/contexts/useCurrentCompany';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import Button from 'src/design/ui-kit/Button/Button';
import EmptyState from 'src/design/ui-kit/EmptyState/EmptyState';
import Loading from 'src/design/ui-kit/Loading/Loading';
import useUserParams from 'src/hooks/useUserParams';
// import CompanyDashboardBreachCredentialsOverview
// from 'src/views/CompanyDashboard/CompanyDashboardBreachCredentialsOverview';
import CompanyDashboardVulnerabilityScanningOverview from
  'src/views/CompanyDashboard/CompanyDashboardVulnerabilityScanningOverview';

function CompanyDashboard() {
  const { companyId } = useUserParams();
  const { t } = useTranslation();
  const { userConfig } = useCurrentUser();

  const { companyEvsSubscription, loadingVulnerabilities } = useCurrentCompany();

  // TODO: Check for subscription for breach credentials
  const loadingBciSubscription = false;

  const isLoading = loadingVulnerabilities || loadingBciSubscription;

  if (!companyEvsSubscription && !isLoading) {
    return (
      <Page title={t('Company Dashboard')}>
        <EmptyState
          subtitle={t('To set up your first tactic, click the "Open tactics" button below!')}
          title={t("Here's where you'll see an overview of your tactics at work")}
        >
          <Button
            link={userConfig.buildUrl('/tactics-settings', `/${companyId}`)}
          >
            {t('Open tactics')}
          </Button>
        </EmptyState>
      </Page>
    );
  }

  if (companyEvsSubscription) {
    return (
      <Page className="flex flex-col gap-10" title={t('Company Dashboard')}>
        {/* <CompanyDashboardBreachCredentialsOverview /> */}
        <CompanyDashboardVulnerabilityScanningOverview />
      </Page>
    );
  }
  return (
    <Page title={t('Loading...')}>
      <Loading />
    </Page>
  );
}

export default CompanyDashboard;
