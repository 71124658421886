import React from 'react';

import PropTypes from 'prop-types';

function Badge(props) {
  const { children } = props;

  return (
    <div
      className="Badge text-od-extra-violet bg-od-extra-violet-12
       od-font-secondary-regular px-2 py-1 rounded-od-12 inline-flex"
    >
      {children}
    </div>
  );
}

Badge.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Badge;
