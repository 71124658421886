import { useMutation } from '@apollo/client';

import { BCI_SET_DOMAINS_MUTATION } from 'src/api/breach-credentials';

export default function useSetDomainsMutation({ onCompleted }) {
  return useMutation(
    BCI_SET_DOMAINS_MUTATION,
    {
      onCompleted,
    },
  );
}
