import React, { createContext, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { COMPANY_QUERY } from 'src/api/companies';
import useVulnerabilities from 'src/api/loaders/useVulnerabilities';
import { EVS_SCAN_STARTED,
  EVS_SUBSCRIPTION_CHANGED,
  TARGET_GROUP_ADDED,
  TARGET_GROUP_DELETED, TARGET_GROUP_UPDATED } from 'src/constants/events';
import useEmitter from 'src/hooks/useEmitter';
import useUserParams from 'src/hooks/useUserParams';

export const CompanyContext = createContext({
  basePrice: 0,
  bciSubscriptionStatus: 'active',
  company: null,
  companyEvsSubscription: null,
  error: null,
  evsSubscriptionStatus: 'inactive',
  evsTargetGroups: [],
  hasLoaded: false,
  isScanRunning: false,
  loadingCompany: true,
  loadingVulnerabilities: true,
  refetchVulnerabilities: async () => {},
  scanStatistics: null,
  targetPrice: 0,
});

export function CompanyProvider(props) {
  const { children } = props;
  const { companyId, mspId } = useUserParams();
  const emitter = useEmitter();

  const companyQuery = useQuery(COMPANY_QUERY, { variables: { companyId } });
  const { company } = companyQuery.data || { company: null };

  const {
    basePrice,
    companyEvsSubscription,
    error: vulnerabilitiesError,
    evsSubscriptionStatus,
    evsTargetGroups,
    isScanRunning,
    loading: loadingVulnerabilities,
    refetch: refetchVulnerabilities,
    scanStatistics,
    targetPrice,
  } = useVulnerabilities({ companyId, mspId });

  useEffect(() => {
    emitter.on(TARGET_GROUP_ADDED, refetchVulnerabilities);
    emitter.on(TARGET_GROUP_DELETED, refetchVulnerabilities);
    emitter.on(TARGET_GROUP_UPDATED, refetchVulnerabilities);
    emitter.on(EVS_SUBSCRIPTION_CHANGED, refetchVulnerabilities);
    emitter.on(EVS_SCAN_STARTED, refetchVulnerabilities);

    return () => {
      emitter.off(TARGET_GROUP_ADDED);
      emitter.off(TARGET_GROUP_DELETED);
      emitter.off(TARGET_GROUP_UPDATED);
      emitter.off(EVS_SUBSCRIPTION_CHANGED);
      emitter.off(EVS_SCAN_STARTED);
    };
  });

  const error = companyQuery.error || vulnerabilitiesError;
  const hasLoaded = !loadingVulnerabilities && !!scanStatistics && !!companyEvsSubscription;

  // TODO: Pass correct bciSubscriptionStatus
  const bciSubscriptionStatus = 'active';

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <CompanyContext.Provider value={{
      basePrice,
      bciSubscriptionStatus,
      company,
      companyEvsSubscription,
      error,
      evsSubscriptionStatus,
      evsTargetGroups,
      hasLoaded,
      isScanRunning,
      loadingCompany: companyQuery.loading,
      loadingVulnerabilities,
      refetchVulnerabilities,
      scanStatistics,
      targetPrice,
    }}
    >
      {children}
    </CompanyContext.Provider>
  );
}

CompanyProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useCurrentCompany = () => React.useContext(CompanyContext);
