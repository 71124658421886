import React, { useState } from 'react';

import uniq from 'lodash-es/uniq';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import useDomainsLoader from 'src/api/loaders/useDomainsLoader';
import useSetDomainsMutation from 'src/api/mutations/useSetDomainsMutation';
import ConfirmationModal from 'src/components/modals/ConfirmationModal';
import { DOMAINS_UPDATED } from 'src/constants/events';
import Button from 'src/design/ui-kit/Button/Button';
import useEmitter from 'src/hooks/useEmitter';
import useToast from 'src/hooks/useToast';
import useUserParams from 'src/hooks/useUserParams';

function AddDomainsContinueButton(props) {
  const { disabled, formData, onComplete, totalPrice } = props;

  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);

  const { t } = useTranslation();
  const emitter = useEmitter();
  const { showSuccessMessage } = useToast();
  const { companyId } = useUserParams();

  const { data: { items: bciDomains } } = useDomainsLoader({
    variables: {
      companyId,
    },
  });

  const [setDomains, { loading: saving }] = useSetDomainsMutation({
    onCompleted() {
      emitter.emit(DOMAINS_UPDATED);
      showSuccessMessage(t('Domains added successfully'));
      setIsOpenConfirmationModal(false);
      onComplete();
    },
  });

  const onConfirm = async () => {
    const updatedDomains = uniq([
      ...bciDomains, // existing domains
      ...formData.domains, // new domains
    ].sort());
    await setDomains({
      variables: {
        companyId,
        domains: updatedDomains,
      },
    });
  };

  return (
    <>
      <Button
        className="w-full"
        disabled={disabled}
        onClick={() => setIsOpenConfirmationModal(true)}
      >
        {t('Continue')}
      </Button>

      <ConfirmationModal
        Cancel={(
          <Button
            disabled={saving}
            onClick={() => setIsOpenConfirmationModal(false)}
            variant="secondary"
          >
            {t('Cancel')}
          </Button>
        )}
        Confirm={(
          <Button
            disabled={saving}
            onClick={onConfirm}
          >
            {t('Confirm')}
          </Button>
        )}
        isOpen={isOpenConfirmationModal}
        onClose={() => setIsOpenConfirmationModal(false)}
        Subtitle={(
          <span className="od-font-secondary-medium text-od-white-500 mt-3">
            <Trans
              components={[
                (
                  <span className="text-od-white-900 od-font-secondary-medium">
                    {`${totalPrice}$`}
                  </span>
                ),
              ]}
              i18nKey="For adding domains you will be charged <0>{{totalPrice}}</0>"
              t={t}
              values={{
                totalPrice: `${totalPrice}$`,
              }}
            />
          </span>
        )}
        title={t('Add domains')}
      />
    </>
  );
}

AddDomainsContinueButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  formData: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
  totalPrice: PropTypes.number.isRequired,
};

export default AddDomainsContinueButton;
