import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useVulnerabilityScansLoader from 'src/api/loaders/useVulnerabilityScansLoader';
import ScanPieChart from 'src/components/charts/ScanPieChart/ScanPieChart';
import ScanPolarChart from 'src/components/charts/ScanPolarChart/ScanPolarChart';
import ScanStackedChart from 'src/components/charts/ScanStackedChart/ScanStackedChart';
import Loading from 'src/design/ui-kit/Loading/Loading';
import useUserParams from 'src/hooks/useUserParams';
import ScanOverviewDetails from 'src/views/VulnerabilityScan/ScanOverviewDetails';

const first = 10;

function ScanOverview(props) {
  const { scan, showInfo } = props;
  const { companyId } = useUserParams();

  const { t } = useTranslation();

  const {
    data: { items: allScans },
    error,
    loading,
  } = useVulnerabilityScansLoader({
    variables: {
      companyId,
      endAt: scan.ranAt,
      first,
    },
  });

  if (error || loading || allScans.length === 0) {
    return (
      <Loading />
    );
  }

  const hasScanComparison = allScans.length >= 2;

  return (
    <section className="ScanOverview grid gap-6 mt-8">
      <ScanOverviewDetails
        scan={scan}
        showInfo={showInfo}
      />

      <div className="ScanOverview__charts grid xl:grid-cols-2 gap-4">
        <ScanPieChart
          className="h-[500px] bg-od-black-300 rounded-od-20"
          scan={scan}
          showInfo={showInfo}
        />

        {hasScanComparison && (
          <ScanPolarChart
            className="h-[500px] bg-od-black-300 rounded-od-20"
            scans={allScans}
            showInfo={showInfo}
            title={t('Comparison to last scan')}
          />
        )}

        <ScanStackedChart
          className={classNames('h-[500px] bg-od-black-300 rounded-od-20', { 'xl:col-span-2': hasScanComparison })}
          scans={allScans}
          showInfo={showInfo}
          title={t('Scan results over time')}
        />
      </div>
    </section>
  );
}

ScanOverview.propTypes = {
  scan: PropTypes.object.isRequired,
  showInfo: PropTypes.bool.isRequired,
};

export default ScanOverview;
