import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ScanStatus from 'src/components/features/vulnerabilities/ScanStatus';
import ScheduleStatus from 'src/components/features/vulnerabilities/ScheduleStatus';
import DeleteTargetGroupButton from 'src/views/TacticVulnerabilities/TargetGroups/DeleteTargetGroupButton';

function GroupsGridViewRow(props) {
  const { canDelete, targetGroup } = props;

  const { t } = useTranslation();

  const isScheduled = !!targetGroup.schedule;

  return (
    <div
      className="grid od-font-main-medium items-center h-18 bg-od-black-500 px-5 rounded-lg justify-items-center"
      id={`GridRow${targetGroup.id}`}
      style={{
        gridTemplateColumns: '1fr 2fr 1fr 1fr 120px',
      }}
    >
      <span className="place-self-start self-center">{targetGroup.name}</span>

      <div className="place-self-start self-center px-6">
        {isScheduled && <ScheduleStatus schedule={targetGroup.schedule} />}
      </div>

      <span className="place-self-start text-od-white-500 self-center">
        {t('{{count}} Targets', { count: targetGroup.targetCount })}
      </span>

      <div className="place-self-start self-center">
        {targetGroup.isScanRunning && (
          <ScanStatus />
        )}
      </div>

      {/* <span> */}
      {/*  <FieldPeriodCost timeUnit="month" value={700} /> */}
      {/* </span> */}

      <div className="place-self-end self-center">
        {canDelete && (
          <DeleteTargetGroupButton targetGroup={targetGroup} />
        )}
      </div>
    </div>
  );
}

GroupsGridViewRow.propTypes = {
  canDelete: PropTypes.bool.isRequired,
  targetGroup: PropTypes.object.isRequired,
};

export default GroupsGridViewRow;
