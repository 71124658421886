import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Button from 'src/design/ui-kit/Button/Button';
import RecentTagHoldingTimeModal from 'src/views/TacticBreachCredentials/RecentTagHoldingTimeModal';

function EditRecentTagHoldingTimeButton() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <Button
        className="place-self-end"
        onClick={() => setIsModalOpen(true)}
        size="sm"
        variant="secondary"
      >
        {t('Edit')}
      </Button>
      <RecentTagHoldingTimeModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}

export default EditRecentTagHoldingTimeButton;
