import React, { useState } from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import IconExpanded from 'src/design/custom-icons/IconExpanded/IconExpanded';
import Button from 'src/design/ui-kit/Button/Button';
import Tooltip from 'src/design/ui-kit/Tooltip/Tooltip';
import VulnerabilityRowDetails
  from 'src/views/VulnerabilityScan/ScanVulnerabilities/VulnerabilityRow/VulnerabilityRowDetails';

function VulnerabilityRow(props) {
  const { index, vulnerability, vulnerabilityType } = props;
  const [expanded, setExpanded] = useState(false);

  const { t } = useTranslation();

  return (
    <div>
      <div
        className={classNames(
          'VulnerabilityRow',
          'h-14 grid grid-cols-[2fr_1fr_max-content] gap-4 items-center px-5 border-od-black-300',
          {
            'border-b': expanded,
            'border-t': index !== 0,
          },
        )}
      >
        <Button
          className="flex items-center gap-3 od-font-main-medium"
          onClick={() => setExpanded(!expanded)}
          variant="text"
        >
          <span className="text-od-white-500">
            {index + 1}
          </span>
          <span style={{ color: vulnerabilityType.color }}>
            { vulnerability.name }
          </span>
        </Button>

        <div className="grid grid-cols-[max-content_max-content_60px] items-center gap-4 justify-end">
          <div>
            {vulnerability.plugin.patchPublicationDate && (
              <Tooltip text={t('A published patch is available which remediates this vulnerability')}>
                <div
                  className="px-2 py-1 bg-od-black-300 hover:bg-od-black-100 rounded-od-8 text-od-complementary-good"
                >
                  <FontAwesomeIcon className="-rotate-45" icon={light('bandage')} />
                </div>
              </Tooltip>
            )}
          </div>

          <div>
            {vulnerability.plugin.exploitable && (
              <Tooltip text={vulnerability.plugin.exploitabilityText}>
                <div className="px-2 py-1 bg-od-black-300 hover:bg-od-black-100 text-od-complementary-bad rounded-od-8">
                  <FontAwesomeIcon icon={light('skull-crossbones')} />
                </div>
              </Tooltip>
            )}
          </div>

          <div className="flex">
            <Tooltip text={t('Number of hosts affected by this vulnerability')}>
              <div
                className="flex px-2 py-1 bg-od-black-300 w-auto
               hover:bg-od-black-100 rounded-od-8 gap-1 items-center text-od-vulnerability-item-count"
              >
                <FontAwesomeIcon icon={light('desktop')} />
                {vulnerability.affectedHosts}
              </div>
            </Tooltip>
          </div>
        </div>

        <Button
          className="mr-1 flex items-center"
          onClick={() => setExpanded(!expanded)}
          variant="text"
        >
          <IconExpanded expanded={expanded} />
        </Button>
      </div>
      {
        expanded && (
          <VulnerabilityRowDetails
            vulnerability={vulnerability}
            vulnerabilityType={vulnerabilityType}
          />
        )
      }
    </div>
  );
}

VulnerabilityRow.propTypes = {
  index: PropTypes.number.isRequired,
  vulnerability: PropTypes.object.isRequired,
  vulnerabilityType: PropTypes.object.isRequired,
};

export default VulnerabilityRow;
