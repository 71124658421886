import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import GoBackLink from 'src/components/common/GoBackLink';
import Page from 'src/components/common/Page';
import ScanActions from 'src/components/features/vulnerabilities/ScanActions';
import { TARGET_GROUP_ADDED, TARGET_GROUP_DELETED } from 'src/constants/events';
import { useCurrentCompany } from 'src/contexts/useCurrentCompany';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import IconVulnerability from 'src/design/custom-icons/IconVulnerability/IconVulnerability';
import Loading from 'src/design/ui-kit/Loading/Loading';
import highlightElement from 'src/helpers/highlightElement';
import useEmitter from 'src/hooks/useEmitter';
import useUserParams from 'src/hooks/useUserParams';
import EvsTargets from 'src/views/TacticVulnerabilities/EvsTargets/EvsTargets';
import TacticVulnerabilitiesDetails from 'src/views/TacticVulnerabilities/TacticVulnerabilitiesDetails';
import AddTargetGroupButton from 'src/views/TacticVulnerabilities/TargetGroups/AddTargetGroup/AddTargetGroupButton';
import GroupsGridView from 'src/views/TacticVulnerabilities/TargetGroups/GroupsGridView';

function TacticVulnerabilities() {
  const { t } = useTranslation();
  const { companyId } = useUserParams();
  const emitter = useEmitter();
  const { permissions, userConfig } = useCurrentUser();
  const { companyEvsSubscription, evsTargetGroups, refetchVulnerabilities } = useCurrentCompany();

  // Refetch target groups on update
  useEffect(() => {
    emitter.on(TARGET_GROUP_ADDED, async ({ evsTargetGroupCreate }) => {
      if (evsTargetGroupCreate && evsTargetGroupCreate.targetGroup) {
        await refetchVulnerabilities();
        // Highlight added target group
        setTimeout(() => highlightElement(`GridRow${evsTargetGroupCreate.targetGroup.id}`));
      }
    });

    emitter.on(TARGET_GROUP_DELETED, refetchVulnerabilities);

    return () => {
      emitter.off(TARGET_GROUP_ADDED);
      emitter.off(TARGET_GROUP_DELETED);
    };
  });

  if (!companyEvsSubscription) {
    return (
      <Page title={t('Loading')}>
        <Loading />
      </Page>
    );
  }

  return (
    <Page
      className="Page TacticsVulnerabilities"
      title={t('External Network Vulnerability Scanning')}
    >
      <header>
        <h3 className="flex gap-2 items-center mb-4">
          <IconVulnerability />
          {t('External Network Vulnerability Scanning')}
        </h3>

        <GoBackLink to={userConfig.buildUrl('/tactics-settings', `/${companyId}`)}>
          {t('All tactics')}
        </GoBackLink>
      </header>

      <TacticVulnerabilitiesDetails evsTargetGroups={evsTargetGroups} />

      <section className="flex items-center justify-between mt-10 mb-6">
        <h5 className="od-font-title-semi-bold">{t('Groups')}</h5>

        {permissions.canManageEvsSubscription() && (
          <div className="flex items-center gap-3">
            <ScanActions showSchedule />
            <AddTargetGroupButton />
          </div>
        )}
      </section>

      <GroupsGridView evsTargetGroups={evsTargetGroups} />

      <EvsTargets />
    </Page>
  );
}

export default TacticVulnerabilities;
