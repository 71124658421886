import React from 'react';

import { ApolloProvider } from '@apollo/client';
import ReactDOM from 'react-dom/client';
import ReactModal from 'react-modal';
import { BrowserRouter } from 'react-router-dom';

import apolloClient from 'src/api/apolloClient';
import App from 'src/App';
import { initI18n } from 'src/design/i18n/i18n';
import reportWebVitals from 'src/reportWebVitals';

import 'src/design/css';

initI18n();

window.Chargebee.init({
  publishableKey: 'live_TGc3RL1Ss5yjHmxVhMk13EUEZ9mJKre3',
  site: 'opfordelta',
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

window.addEventListener('load', () => {
  ReactModal.setAppElement('.App');
});
