import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import ConfirmationModal from 'src/components/modals/ConfirmationModal';
import Button from 'src/design/ui-kit/Button/Button';
import FieldCost from 'src/design/ui-kit/Field/FieldCost/FieldCost';

function AddTargetGroupContinueButton(props) {
  const { disabled, mode, onConfirm, totalPrice } = props;

  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <Button
        className="w-full"
        disabled={disabled}
        onClick={() => setIsOpenConfirmationModal(true)}
      >
        {t('Continue')}
      </Button>

      <ConfirmationModal
        Cancel={(
          <Button
            onClick={() => setIsOpenConfirmationModal(false)}
            variant="secondary"
          >
            {t('Cancel')}
          </Button>
        )}
        Confirm={(
          <Button onClick={onConfirm}>
            {t('Confirm')}
          </Button>
        )}
        isOpen={isOpenConfirmationModal}
        onClose={() => setIsOpenConfirmationModal(false)}
        Subtitle={(
          <span className="od-font-secondary-medium text-od-white-500 mt-3">
            <Trans
              components={[
                (
                  <FieldCost className="text-od-white-900 od-font-secondary-medium inline-block" value={totalPrice} />
                ),
              ]}
              i18nKey={mode === 'subscription'
                ? 'For creating this subscription you will be charged <0>{{totalPrice}}</0>'
                : 'For creating this group you will be charged <0>{{totalPrice}}</0>'}
              t={t}
            />
          </span>
        )}
        title={mode === 'subscription' ? t('Subscribe') : t('Create group')}
      />
    </>
  );
}

AddTargetGroupContinueButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf(['subscription', 'targetGroup']).isRequired,
  onConfirm: PropTypes.func.isRequired,
  totalPrice: PropTypes.number.isRequired,
};

export default AddTargetGroupContinueButton;
