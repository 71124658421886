import React, { Fragment, useState } from 'react';

import classNames from 'classnames';
import map from 'lodash-es/map';
import startCase from 'lodash-es/startCase';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import IconExpanded from 'src/design/custom-icons/IconExpanded/IconExpanded';
import Button from 'src/design/ui-kit/Button/Button';

function VulnerabilityRowDetailsPlugin(props) {
  const { attributes } = props;

  const [expanded, setExpanded] = useState(false);

  const { t } = useTranslation();

  return (
    <section className="grid gap-4 bg-od-black-300 rounded-lg">
      <p className=" text-od-white-900 od-font-secondary-medium border-b border-b-od-black-500 py-4 px-5">
        <Button
          className="mr-1 flex gap-3 items-center"
          onClick={() => setExpanded(!expanded)}
          variant="text"
        >
          <IconExpanded expanded={expanded} />
          {t('Plugin Details')}
        </Button>
      </p>
      {expanded && (
        <div
          className="grid gap-x-10 gap-y-5 items-center rounded-od-8 p-5"
          style={{ gridTemplateColumns: 'max-content auto' }}
        >
          {map(attributes, (value, key) => (
            <Fragment key={key}>
              <span className="od-font-main-regular text-od-white-500 max-w-max">{startCase(key)}</span>
              <span
                className={classNames('od-font-main-medium', {
                  'text-od-complementary-medium': ['od-vulnerability-risk'].includes(key),
                })}
              >
                {value}
              </span>
            </Fragment>
          ))}
        </div>
      )}
    </section>
  );
}

VulnerabilityRowDetailsPlugin.propTypes = {
  attributes: PropTypes.object.isRequired,
};

export default VulnerabilityRowDetailsPlugin;
