import React, { useState } from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ScanModal from 'src/components/features/vulnerabilities/RunScanButton/ScanModal';
import { useCurrentCompany } from 'src/contexts/useCurrentCompany';
import Button, { buttonSizes } from 'src/design/ui-kit/Button/Button';
import LoadingProgress from 'src/design/ui-kit/LoadingProgress/LoadingProgress';

function RunScanButton(props) {
  const { initialTargetGroup, size } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const { isScanRunning } = useCurrentCompany();

  return (
    <div>
      <Button
        className="flex gap-2 items-center"
        disabled={isScanRunning}
        onClick={() => setIsOpen(true)}
        size={size}
      >
        {isScanRunning ? (
          <>
            <LoadingProgress />
            {t('Scan in progress')}
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={light('play')} />
            {t('Run Scan')}
          </>
        )}
      </Button>
      <ScanModal
        initialTargetGroup={initialTargetGroup}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
}

RunScanButton.defaultProps = {
  initialTargetGroup: null,
};

RunScanButton.propTypes = {
  initialTargetGroup: PropTypes.object,
  size: PropTypes.oneOf(buttonSizes).isRequired,
};

export default RunScanButton;
