import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCurrentUser } from 'src/contexts/useCurrentUser';
import Button from 'src/design/ui-kit/Button/Button';
import FieldCost from 'src/design/ui-kit/Field/FieldCost/FieldCost';
import InfoCard from 'src/design/ui-kit/InfoCard/InfoCard';
import Tooltip from 'src/design/ui-kit/Tooltip/Tooltip';
import useUserParams from 'src/hooks/useUserParams';
import EditRecentTagHoldingTimeButton from 'src/views/TacticBreachCredentials/EditRecentTagHoldingTimeButton';

function TacticBreachCredentialsDetails(props) {
  const { bciDomains } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { permissions } = useCurrentUser();
  const { companyId } = useUserParams();

  // TODO: Fetch correct cost
  const cost = 10000;

  return (
    <section className={classNames('mt-8 grid gap-4', {
      'lg:grid-cols-2': !permissions.canViewBillingDetails(),
      'lg:grid-cols-3': permissions.canViewBillingDetails(),
    })}
    >

      <InfoCard className="od-card grid gap-y-3 h-26" description={t('Domains')}>
        {bciDomains.length}
      </InfoCard>

      <InfoCard
        description={(
          <div className="flex gap-2 items-center">
            <span>{t('Recent tag holding time')}</span>
            <Tooltip
              text={t('During this period of time (since detection) breach credentials would be marked as "Recent"')}
            >
              <FontAwesomeIcon icon={light('circle-info')} />
            </Tooltip>
          </div>
      )}
      >
        <div className="flex justify-between items-center w-full">
          <div className="od-font-title-regular">
            2 days
          </div>

          <EditRecentTagHoldingTimeButton />
        </div>
      </InfoCard>

      {permissions.canViewBillingDetails() && (
        <InfoCard description={t('Total cost')}>
          <div className="flex items-center justify-between">
            <h4>
              <FieldCost value={cost} />
            </h4>

            {permissions.canSetupPaymentMethod() && (
              <Button
                onClick={() => navigate(`/billing/companies/${companyId}`)}
                size="sm"
                variant="secondary"
              >
                {t('Open billing')}
              </Button>
            )}
          </div>
        </InfoCard>
      )}
    </section>
  );
}

TacticBreachCredentialsDetails.propTypes = {
  bciDomains: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TacticBreachCredentialsDetails;
