import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// import BulkAddStringsFormImportSection from
// 'src/components/BulkEditStringsForm/BulkAddStringsFormImportSection';
import BulkEditStringsRow from 'src/components/common/BulkEditStringsForm/BulkEditStringsRow';
import { schema } from 'src/components/common/BulkEditStringsForm/useBulkEditStringsFormValidations';
import FormControl from 'src/components/common/FormControl';
import Button from 'src/design/ui-kit/Button/Button';
import Input from 'src/design/ui-kit/Input/Input';
import { deleteAt, findDuplicates, updateAt } from 'src/helpers/array';
import useValidationHelpers from 'src/hooks/useValidationHelpers';

/**
 * Bulk add multiple strings. Reused for domains & IPs
 */
function BulkAddStringsForm(props) {
  const { canEdit, indexFormatter, onChange, placeholder, validator, valueFormatter } = props;
  const [editingIndex, setEditingIndex] = useState(null);

  const { t } = useTranslation();

  const { clearErrors, formState, register, reset, setValue, watch } = useForm({
    defaultValues: {
      inputValue: '',
      valuesArray: [],
    },
    mode: 'onChange',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema(validator)),
  });

  const formData = watch();

  const { getFieldError, isFieldValid } = useValidationHelpers({
    formState,
  });

  const setValuesArray = (previousStateFn, shouldReset = false) => {
    const array = previousStateFn(formData.valuesArray);
    setValue('valuesArray', array);

    // Update parent listeners
    onChange(array);

    // Clear editing index after any successful update
    setEditingIndex(null);

    if (shouldReset) {
      reset({
        inputValue: '',
        valuesArray: array,
      });
    }
  };

  // const onImportSuccess = (importedValues) => {
  //   setValuesArray((valuesArray) => [
  //     ...valuesArray,
  //     ...importedValues,
  //   ]);
  // };

  // Find duplicates
  const duplicates = findDuplicates(formData.valuesArray);

  const onDeleteRow = (index) => {
    setValuesArray((valuesArray) => deleteAt(index, valuesArray));
  };

  const onEditRow = (value, index) => {
    // Set new inputValue
    setEditingIndex(index);
    setValue('inputValue', value);
    clearErrors();
  };

  const onEnter = () => {
    setValuesArray((valuesArray) => {
      // If item is being edited, change it instead
      if (editingIndex !== null) {
        return updateAt(editingIndex, formData.inputValue, valuesArray);
      }
      return [
        ...valuesArray,
        formData.inputValue,
      ];
    }, true);
  };

  const onKeyDown = (event) => {
    if (event.code === 'Enter' && isFieldValid('inputValue')) {
      onEnter();
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <form
      className="BulkEditForm flex flex-col gap-6 h-full pb-3"
      noValidate
      onSubmit={onSubmit}
    >
      {/* <BulkAddStringsFormImportSection */}
      {/*  count={formData.valuesArray.length} */}
      {/*  onImportSuccess={onImportSuccess} */}
      {/*  validator={validator} */}
      {/* /> */}

      <section>
        <FormControl
          error={getFieldError('inputValue')}
          htmlFor="BulkAddStringsForm-inputValue"
        >
          <Input
            error={getFieldError('inputValue')}
            id="BulkAddStringsForm-inputValue"
            InputIcon={(
              <Button
                className="-mr-4"
                disabled={!isFieldValid('inputValue')}
                onClick={onEnter}
                size="sm"
                variant="secondary"
              >
                {t('Press Enter')}
              </Button>
            )}
            name="inputValue"
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            register={register}
          />
        </FormControl>
      </section>

      <section className="flex flex-col gap-1 overflow-y-scroll flex-1 pr-3 od-scrollbar pb-1">
        {formData.valuesArray.map((value, index) => (
          <BulkEditStringsRow
            key={index}
            canEdit={canEdit}
            index={index}
            indexFormatter={indexFormatter}
            isDuplicated={duplicates.includes(value)}
            onDelete={onDeleteRow}
            onEdit={onEditRow}
            value={value}
            valueFormatter={valueFormatter}
          />
        ))}
      </section>
    </form>
  );
}

BulkAddStringsForm.defaultProps = {
  canEdit: true,
  indexFormatter: (index) => index + 1,
  valueFormatter: (value) => value,
};

BulkAddStringsForm.propTypes = {
  canEdit: PropTypes.bool,
  indexFormatter: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  validator: PropTypes.func.isRequired,
  valueFormatter: PropTypes.func,
};

export default BulkAddStringsForm;
