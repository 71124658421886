import { gql } from '@apollo/client';

const EVS_SUBSCRIPTION_FRAGMENT = gql`
  fragment FullSubscription on EVSSubscriptionNode {
    id

    startsAt
    expiresAt

    isActive
    
    targetCount

    targetGroups {
      edges {
        node {
          id
        }
      }
    }
  }
`;

export const EVS_SUBSCRIPTION_FOR_COMPANY = gql`
  ${EVS_SUBSCRIPTION_FRAGMENT}

  query companyEvsSubscription ($companyId: ID!) {
    companyEvsSubscription(company: $companyId) {
      ...FullSubscription
    }
  }
`;

export const EVS_SUBSCRIBE = gql`
  ${EVS_SUBSCRIPTION_FRAGMENT}

  mutation (
    $company: ID!,
    $targetGroupName: String,
    $targetGroupTargets: [String!],
    $reactivatedTargetGroups: [ID!]
  ) {
    evsSubscribe(input: {
      company: $company
      targetGroupName: $targetGroupName
      targetGroupTargets: $targetGroupTargets
      reactivatedTargetGroups: $reactivatedTargetGroups
      runScan: false
    }) {
      subscription {
        ...FullSubscription
      }
    }
  }
`;

export const EVS_UNSUBSCRIBE = gql`
  ${EVS_SUBSCRIPTION_FRAGMENT}

  mutation ($company: ID!) {
    evsUnsubscribe(input: {
      company: $company
    }) {
      subscription {
        ...FullSubscription
      }
    }
  }
`;

export const EVS_PRICES = gql`
  query evsPrices ($customer: ID!) {
    evsPrices(customer: $customer) {
      basePrice
      targetPrice
    }
  }
`;
