import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import useSetDomainsMutation from 'src/api/mutations/useSetDomainsMutation';
import FormControl from 'src/components/common/FormControl';
import Modal from 'src/components/modals/Modal';
import { DOMAINS_UPDATED } from 'src/constants/events';
import Button from 'src/design/ui-kit/Button/Button';
import Input from 'src/design/ui-kit/Input/Input';
import { updateAt } from 'src/helpers/array';
import { isDomain } from 'src/helpers/ip';
import useEmitter from 'src/hooks/useEmitter';
import useToast from 'src/hooks/useToast';
import useUserParams from 'src/hooks/useUserParams';
import useValidationHelpers from 'src/hooks/useValidationHelpers';

const schema = () => yup.object({
  domain: yup
    .string()
    .test(
      'domainName',
      i18n.t('Must be a valid domain name'),
      isDomain,
    )
    .required(i18n.t('Field is required')),
}).required();

const initialFormData = (domain) => ({
  domain,
});

function EditDomainModal(props) {
  const { domain, domains, isOpen, onClose } = props;
  const { companyId } = useUserParams();

  const { t } = useTranslation();

  const emitter = useEmitter();

  const { clearErrors, formState, handleSubmit, register, reset } = useForm({
    defaultValues: initialFormData(domain),
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema()),
  });

  const { showSuccessMessage } = useToast();

  const handleClose = () => {
    reset();
    clearErrors();
    onClose();
  };

  const [setDomains, { loading: saving }] = useSetDomainsMutation({
    onCompleted() {
      emitter.emit(DOMAINS_UPDATED);
      showSuccessMessage(t('Domains updated successfully'));
      handleClose();
    },
  });

  const { getFieldError } = useValidationHelpers({
    formState,
  });

  const onSubmit = async (submitData, event) => {
    event.preventDefault();

    const currentIndex = domains.indexOf(domain);
    const updatedDomains = updateAt(currentIndex, submitData.domain, domains);

    await setDomains({
      variables: {
        companyId,
        domains: updatedDomains,
      },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      shouldCloseOnOverlayClick={false}
      shouldFocusFirstFocusable
      title={t('Edit domain')}
    >
      <form
        className="mt-12 flex flex-col gap-7"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormControl
          error={getFieldError('domain')}
          htmlFor="EditDomainModal__input-domain"
          label={t('Name')}
        >
          <Input
            error={getFieldError('domain')}
            id="EditDomainModal__input-domain"
            name="domain"
            placeholder={t('Domain')}
            register={register}
          />
        </FormControl>

        <div className="mt-10 flex justify-end">
          <Button
            disabled={!formState.isValid || saving}
            type="submit"
          >
            {t('Save changes')}
          </Button>
        </div>
      </form>
    </Modal>
  );
}

EditDomainModal.propTypes = {
  domain: PropTypes.string.isRequired,
  domains: PropTypes.arrayOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditDomainModal;
