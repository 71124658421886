import React from 'react';

import { useTranslation } from 'react-i18next';

import Page from 'src/components/common/Page';
import { useMSPBilling } from 'src/contexts/useMSPBilling';
import EmptyState from 'src/design/ui-kit/EmptyState/EmptyState';
import Loading from 'src/design/ui-kit/Loading/Loading';
import useFeatures from 'src/hooks/useFeatures';
import BillingCompaniesTableView from 'src/views/Billing/BillingCompaniesTableView';
import BillingDetails from 'src/views/Billing/BillingDetails';
import BillingInvoicesDetails from 'src/views/Billing/BillingInvoicesDetails';
import ChangePaymentMethodButton from 'src/views/Billing/ChangePaymentMethod/ChangePaymentMethodButton';

function Billing() {
  const { loading, mspBillingDetails: { companies, paymentSource } } = useMSPBilling();
  const { t } = useTranslation();
  const { isBillingInvoicesEnabled } = useFeatures();

  if (loading) {
    return (
      <Page title={t('Loading')}>
        <Loading />
      </Page>
    );
  }

  return (
    <Page className="grid gap-6" title={t('Billing')}>
      <h3>{t('Billing')}</h3>
      {paymentSource ? (
        <>
          <BillingDetails />

          {isBillingInvoicesEnabled() && <BillingInvoicesDetails />}

          <section>
            <h2 className="text-base mb-6">{t('Current monthly cost by company')}</h2>
            <BillingCompaniesTableView companies={companies} />
          </section>
        </>
      ) : (
        <EmptyState
          subtitle={t('There is no payment method currently setup. Please add a credit credit to your account.')}
        >
          <ChangePaymentMethodButton />
        </EmptyState>
      )}
    </Page>
  );
}

export default Billing;
