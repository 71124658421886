import React from 'react';

import i18n from 'i18next';

import TableView from 'src/components/common/Table/TableView';
import { TableColumnDefinition } from 'src/hooks/useTable';
import MSPDashboardNewsTableViewRow from 'src/views/MSPDashboard/MSPDashboardNews/MSPDashboardNewsTableViewRow';

function MSPDashboardNews() {
  const columns = [
    new TableColumnDefinition({
      name: 'date',
      title: i18n.t(''),
    }),
    new TableColumnDefinition({
      name: 'text',
      title: i18n.t(''),
    }),
  ];

  const news = [
    {
      date: new Date().toDateString(),
      id: 1,
      text: 'New feature: Now you can customize evs scan features in EVS Tactic',
    },
    {
      date: new Date().toDateString(),
      id: 2,
      text: 'New date breached indexed: \n facebook.com: 25,444,444 records \n Twitter: 222,222 records ',
    },
  ];

  const onLoadMore = () => {};
  const isLoadingMore = false;

  return (
    <TableView
      columns={columns}
      data={news}
      isLoadingMore={isLoadingMore}
      onLoadMore={onLoadMore}
      TableRow={MSPDashboardNewsTableViewRow}
    />
  );
}

export default MSPDashboardNews;
