import React, { createContext } from 'react';

import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import { ME_QUERY } from 'src/api/users';
import { buildPermissions } from 'src/helpers/permissions';
import useToast from 'src/hooks/useToast';
import { userTypeStore, getUserConfig, USER_TYPES } from 'src/users';

export const CurrentUserContext = createContext({
  error: null,
  fetchMe: () => {},
  isCompanyUser: false,
  isGlobalAdminUser: false,
  isMSPUser: false,
  loading: false,
  me: null,
  navigateToDefaultPage: () => {},
  navigateToSignIn: () => {},
  permissions: {},
  userConfig: getUserConfig(null),
  userType: userTypeStore.get(),
});

export function CurrentUserProvider(props) {
  const { children } = props;
  const { showErrorMessage } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const isAuthPage = location.pathname.includes('/auth/');
  const isHomePage = location.pathname === '/';

  const navigateToSignIn = () => window.location.replace('/users/login/', { replace: true });

  const navigateToDefaultPage = async () => {
    const _userConfig = getUserConfig(userTypeStore.get());
    navigate(_userConfig.buildUrl('/'));
  };

  const handleError = () => {
    userTypeStore.clear();
    if (!isAuthPage) {
      navigateToSignIn();
      if (!isHomePage) {
        showErrorMessage(t('User is not authorized'));
      }
    }
  };

  const { data, error, loading, refetch: fetchMe } = useQuery(ME_QUERY, {
    async onCompleted(response) {
      const { me: _me } = response || { me: null };

      // We are interested only in non-auth views
      if (!_me) {
        handleError();
        return;
      }

      userTypeStore.set(_me.userType);
    },
    onError: handleError,
    variables: {
      ignoreErrors: true,
    },
  });

  const { me } = data || { me: null };

  const userConfig = getUserConfig(userTypeStore.get());

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <CurrentUserContext.Provider value={{
      error,
      fetchMe,
      isCompanyUser: [
        USER_TYPES.COMPANY_ADMIN,
        USER_TYPES.AUDITOR,
      ].includes(userConfig.userType),
      isGlobalAdminUser: [
        USER_TYPES.GLOBAL_ADMIN,
      ].includes(userConfig.userType),
      isMSPUser: [
        USER_TYPES.MSP_ADMIN,
        USER_TYPES.MSP_ANALYST,
      ].includes(userConfig.userType),
      loading,
      me,
      navigateToDefaultPage,
      navigateToSignIn,
      permissions: buildPermissions(me),
      userConfig,
      userType: userTypeStore.get(),
    }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
}

CurrentUserProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useCurrentUser = () => React.useContext(CurrentUserContext);
