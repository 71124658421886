import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { EXPORT_SCAN_MUTATION } from 'src/api/vulnerabilities';
import ExportModal from 'src/components/modals/ExportModal';
import Button from 'src/design/ui-kit/Button/Button';
import { openInNewTab } from 'src/helpers/url';
import useToast from 'src/hooks/useToast';

function ExportButton(props) {
  const { scan } = props;
  const { t } = useTranslation();
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const { showSuccessMessage } = useToast();

  const [exportCSV, { loading: saving }] = useMutation(
    EXPORT_SCAN_MUTATION,
    {
      onCompleted({ exportScanCsv }) {
        if (!exportScanCsv) {
          return;
        }
        openInNewTab(exportScanCsv.url);
        showSuccessMessage(t('Scan exported successfully'));
        setIsExportModalOpen(false);
      },
    },
  );

  const onExport = async () => {
    await exportCSV({
      variables: {
        scan: scan.id,
      },
    });
  };

  return (
    <>
      <Button onClick={() => setIsExportModalOpen(true)}>
        {t('Export')}
      </Button>
      <ExportModal
        isOpen={isExportModalOpen}
        loading={saving}
        onClose={() => setIsExportModalOpen(false)}
        onExport={onExport}
      />
    </>
  );
}

ExportButton.propTypes = {
  scan: PropTypes.object.isRequired,
};

export default ExportButton;
