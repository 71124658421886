import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

import { useCurrentUser } from 'src/contexts/useCurrentUser';
import { ReactComponent as IconBci } from 'src/design/custom-icons/bci.svg';
import NavigationItem from 'src/design/models/NavigationItem';
import useFeatures from 'src/hooks/useFeatures';
import useUserParams from 'src/hooks/useUserParams';
import BreachCredentials
  from 'src/views/BreachCredentials/BreachCredentials';
import BreachCredentialsDomain from 'src/views/BreachCredentialsDomain/BreachCredentialsDomain';
import CompanyDashboard from 'src/views/CompanyDashboard/CompanyDashboard';
import TacticBreachCredentials from 'src/views/TacticBreachCredentials/TacticBreachCredentials';
import TacticsSettings from 'src/views/TacticsSettings/TacticsSettings';
import TacticVulnerabilities from 'src/views/TacticVulnerabilities/TacticVulnerabilities';
import Users from 'src/views/Users/Users';
import VulnerabilityScan from 'src/views/VulnerabilityScan/VulnerabilityScan';
import VulnerabilityScans from 'src/views/VulnerabilityScans/VulnerabilityScans';

function useCompanyNavigationItems(company) {
  const { t } = useTranslation();
  const { permissions, userConfig } = useCurrentUser();
  const { companyId } = useUserParams();
  const { isBCIEnabled } = useFeatures();

  if (!company) {
    return [];
  }
  return [
    new NavigationItem({
      Icon: (
        <FontAwesomeIcon icon={light('grid-horizontal')} />
      ),
      absolutePath: userConfig.buildUrl('/dashboard', '/:companyId'),
      component: <CompanyDashboard company={company} />,
      componentProps: { company: 'company' },
      name: t('Dashboard'),
      relativePath: 'dashboard',
      to: userConfig.buildUrl('/dashboard', `/${companyId}`),
    }),
    new NavigationItem({
      Icon: (
        <FontAwesomeIcon icon={light('radar')} />
      ),
      absolutePath: userConfig.buildUrl('/vulnerability-scans/*', '/:companyId'),
      component: (
        <Routes>
          <Route element={<VulnerabilityScans />} index />
          <Route element={<VulnerabilityScan />} path=":scanId" />
        </Routes>
      ),
      name: t('Vulnerability Scans'),
      relativePath: 'vulnerability-scans/*',
      to: userConfig.buildUrl('/vulnerability-scans', `/${companyId}`),
    }),
    isBCIEnabled() ? new NavigationItem({
      Icon: (
        <div className="w-4">
          <IconBci style={{
            fill: 'currentColor',
          }}
          />
        </div>
      ),
      absolutePath: userConfig.buildUrl('/breach-credentials/*', '/:companyId'),
      component: (
        <Routes>
          <Route element={<BreachCredentials company={company} />} index />
          <Route element={<BreachCredentialsDomain />} path=":domain" />
        </Routes>
      ),
      name: t('Breach Credentials'),
      relativePath: 'breach-credentials/*',
      to: userConfig.buildUrl('/breach-credentials', `/${companyId}`),
    }) : false,
    new NavigationItem({
      Icon: (
        <FontAwesomeIcon icon={light('sliders-up')} />
      ),
      absolutePath: userConfig.buildUrl('/tactics-settings/*', '/:companyId'),
      component: (
        <Routes>
          <Route element={<TacticsSettings />} index />
          <Route element={<TacticVulnerabilities />} path="evs" />
          {isBCIEnabled() && <Route element={<TacticBreachCredentials />} path="bci" />}
        </Routes>
      ),
      name: t('Tactics Settings'),
      relativePath: 'tactics-settings/*',
      to: userConfig.buildUrl('/tactics-settings', `/${companyId}`),
    }),
    permissions.canViewCompanyUsers() ? new NavigationItem({
      Icon: (
        <FontAwesomeIcon icon={light('people-group')} />
      ),
      absolutePath: userConfig.buildUrl('/users', '/:companyId'),
      component: <Users />,
      name: t('Users'),
      relativePath: 'users',
      to: userConfig.buildUrl('/users', `/${companyId}`),
    }) : null,
  ].filter(Boolean);
}

export default useCompanyNavigationItems;
