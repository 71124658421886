const DEFAULT_CURRENCY = 'USD';

export const useConvertCentValue = (decimalPoints = 2) => {
  const convertToCentValue = (value) => {
    if (!value && value !== 0) {
      return null;
    }
    const centValue = value * 10 ** decimalPoints;
    return Number(
      `${Math.round(`${centValue}e${decimalPoints}`)}e-${decimalPoints}`,
    );
  };

  const convertFromCentValue = (value) => {
    if (!value && value !== 0) {
      return null;
    }
    return decimalPoints
      ? (value / 100).toFixed(value % 100 ? decimalPoints : 0)
      : Math.floor((value / 100));
  };

  return {
    convertFromCentValue,
    convertToCentValue,
  };
};

export function formatPrice(value) {
  const formatter = new Intl.NumberFormat('en-US', {
    currency: DEFAULT_CURRENCY,
    maximumSignificantDigits: 3,
    style: 'currency',
  });
  return formatter.format(value);
}

export function calculatePrices({ basePrice, evsSubscriptionStatus, targetPrice, targetsCount }) {
  const totalTargetsPrice = targetPrice * targetsCount;
  let totalPrice = totalTargetsPrice;

  if (evsSubscriptionStatus !== 'active') {
    totalPrice += basePrice;
  }

  return {
    totalPrice,
    totalTargetsPrice,
  };
}
