import React from 'react';

import classNames from 'classnames';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import { DEFAULT_DATE_FORMAT } from 'src/constants/date';

function FieldDatetime(props) {
  const { className, dateFormat, value } = props;
  return (
    <time className={classNames('Field DatetimeField', className)}>
      {format(value, dateFormat)}
    </time>
  );
}

FieldDatetime.defaultProps = {
  className: 'text-od-white-500 text-sm',
  dateFormat: DEFAULT_DATE_FORMAT,
};

FieldDatetime.propTypes = {
  className: PropTypes.string,
  dateFormat: PropTypes.string,
  value: PropTypes.instanceOf(Date).isRequired,
};

export default FieldDatetime;
