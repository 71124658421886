import React, { useRef, useState } from 'react';

import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Popover } from 'react-tiny-popover';

import DropdownArrow from 'src/components/common/DropdownArrow';
import { DEFAULT_TIME_FORMAT } from 'src/constants/date';
import Button from 'src/design/ui-kit/Button/Button';
import Input from 'src/design/ui-kit/Input/Input';
import TimeUnitView from 'src/design/ui-kit/TimePicker/TimeUnitView';
import { buildTime, getHoursList, getMinutesList, parseHourAndMinute } from 'src/helpers/date';

function TimePicker(props) {
  const { onChange, placeholder, popoverProps, value } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const triggerRef = useRef();
  const { t } = useTranslation();
  const [localValue, setLocalValue] = useState(value);

  const hours = getHoursList();
  const minutes = getMinutesList();
  const { hour, minute } = parseHourAndMinute(localValue);

  const canSave = !!hour && !!minute;

  const onCancel = () => {
    setLocalValue(value);
    setIsPopoverOpen(false);
  };

  const onClickNow = () => {
    setLocalValue(format(new Date(), DEFAULT_TIME_FORMAT));
  };

  const onApply = () => {
    onChange(localValue);
    setIsPopoverOpen(false);
  };

  const getContent = () => (
    <div
      className="bg-od-black-300 px-3 py-5"
      style={{
      }}
    >
      <div className="grid grid-cols-2 gap-2">
        <TimeUnitView
          onChange={(newHour) => setLocalValue(buildTime({ hour: newHour, minute }))}
          units={hours}
          value={hour}
        />
        <TimeUnitView
          onChange={(newMinute) => setLocalValue(buildTime({ hour, minute: newMinute }))}
          units={minutes}
          value={minute}
        />
      </div>
      <div className="grid grid-cols-2 gap-3 mt-3">
        <Button
          className="justify-center px-auto"
          onClick={onClickNow}
          size="xs"
          variant="secondary"
        >
          {t('Now')}
        </Button>
        <Button
          className="justify-center w-auto"
          disabled={!canSave}
          onClick={onApply}
          size="xs"
        >
          {t('Ok')}
        </Button>
      </div>
    </div>
  );

  const inputValue = () => {
    const hourPart = hour || '--';
    const minutePart = minute || '--';
    return buildTime({ hour: hourPart, minute: minutePart });
  };

  return (
    <Popover
      align="start"
      boundaryElement={triggerRef.current}
      className="TimePicker"
      content={getContent}
      isOpen={isPopoverOpen}
      onClickOutside={onCancel}
      padding={4}
      reposition
      {...popoverProps}
    >
      <Input
        ref={triggerRef}
        className="Input__control"
        InputIcon={<DropdownArrow open={isPopoverOpen} />}
        name="days"
        onClick={() => setIsPopoverOpen(true)}
        placeholder={placeholder}
        readOnly
        type="text"
        value={inputValue()}
      />
    </Popover>
  );
}

TimePicker.defaultProps = {
  popoverProps: {},
};

TimePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  popoverProps: PropTypes.object,
  value: PropTypes.string.isRequired,
};

export default TimePicker;
