import { gql } from '@apollo/client';

export const ALL_NOTIFICATIONS_QUERY = gql`
  query allNotifications($first: Int!, $after: String) {
    allNotifications(
      first: $first
      after: $after
    ) @connection(key: "id") {
      edges {
        node {
          id
          seen
          notification {
            id
            message
            addedAt
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const UNSEEN_NOTIFICATIONS_QUERY = gql`
  query {
    unseenNotifications
  }
`;

export const COMPANY_NOTIFICATIONS_CONFIG_QUERY = gql`
  query($company: ID!) {
    companyNotificationConfig(company: $company) {
      id
      evs {
        email
      }
      bci {
        email
      }
    }
  }
`;

export const UPDATE_NOTIFICATIONS_CONFIG_MUTATION = gql`
  mutation($companyId: ID!, $evsEmail: Boolean!, $bciEmail: Boolean!) {
    updateNotificationConfig(input: {
      companyId: $companyId
      evsEmail: $evsEmail
      bciEmail: $bciEmail
    }) {
      config {
        evs {
          email
        }
      }
    }
  }
`;

export const SEE_NOTIFICATIONS_MUTATION = gql`
  mutation($notifications: [ID!]!) {
    seeNotifications(input: {
      notifications: $notifications
    }) {
      clientMutationId
    }
  }
`;
