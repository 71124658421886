import React, { useRef } from 'react';

import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CREATE_USER_MUTATION, UPDATE_USER_MUTATION } from 'src/api/users';
import Modal from 'src/components/modals/Modal';
import { USER_ADDED, USER_UPDATED } from 'src/constants/events';
import useEmitter from 'src/hooks/useEmitter';
import useToast from 'src/hooks/useToast';
import useUserParams from 'src/hooks/useUserParams';
import { USER_TYPE_CATEGORIES, USER_TYPE_CATEGORIES_LIST } from 'src/users';
import UserModalForm from 'src/views/common/Users/UserModal/UserModalForm';

function UserModal(props) {
  const { isOpen, onClose, user, userCategoryType } = props;
  const { t } = useTranslation();
  const { companyId, mspId } = useUserParams();
  const { showSuccessMessage } = useToast();
  const formRef = useRef();

  const isEditMode = !!user;

  const handleClose = () => {
    formRef.current.reset();
    onClose();
  };

  const emitter = useEmitter();

  const [saveUser, { loading: saving }] = useMutation(
    isEditMode ? UPDATE_USER_MUTATION : CREATE_USER_MUTATION,
    {
      onCompleted(response) {
        if (response.createUser) {
          showSuccessMessage('User successfully invited');
          emitter.emit(USER_ADDED, response);
        }
        if (response.updateUser) {
          showSuccessMessage('User successfully updated');
          emitter.emit(USER_UPDATED, response);
        }
        handleClose();
      },
    },
  );

  const onSubmit = async (submitData, event) => {
    event.preventDefault();

    await saveUser({
      variables: {
        companyId,
        email: submitData.email,

        userType: submitData.userType.value,
        ...user && {
          firstName: submitData.firstName,
          id: user.id,
          lastName: submitData.lastName,
        },
        ...userCategoryType === USER_TYPE_CATEGORIES.MSP && {
          mspId,
        },
      },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      shouldFocusFirstFocusable
      subtitle={t('Enter user details')}
      title={user ? t('Edit user') : t('Invite user')}
    >
      <UserModalForm
        ref={formRef}
        loading={saving}
        onSubmit={onSubmit}
        user={user}
        userCategoryType={userCategoryType}
      />
    </Modal>
  );
}

UserModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object,
  userCategoryType: PropTypes.oneOf(USER_TYPE_CATEGORIES_LIST).isRequired,
};

UserModal.defaultProps = {
  user: null,
};

export default UserModal;
