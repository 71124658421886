import React, { forwardRef } from 'react';

import { CardComponent, CardCVV, CardExpiry, CardNumber } from '@chargebee/chargebee-js-react-wrapper';
import PropTypes from 'prop-types';

import CardComponentWrapper from 'src/views/Billing/ChangePaymentMethod/CardComponentWrapper';
import chargebeeStyles from 'src/views/Billing/ChangePaymentMethod/chargebeeStyles';

const ChargebeeWidget = forwardRef((props, cardRef) => {
  const {
    cvvState, expiryState, isDirty, numberState, setCvvState, setExpiryState, setNumberState,
  } = props;
  return (
    <CardComponent
      ref={cardRef}
      className="field"
      styles={chargebeeStyles}
    >
      <div className="grid gap-4">
        <CardComponentWrapper isDirty={isDirty} state={numberState}>
          <CardNumber
            className="Input--chargebee field empty"
            onChange={setNumberState}
            placeholder="4111 1111 1111 1111"
          />
        </CardComponentWrapper>

        <CardComponentWrapper
          isDirty={isDirty}
          state={expiryState}
        >
          <CardExpiry
            className="Input--chargebee field empty"
            onChange={setExpiryState}
            placeholder="MM / YY"
          />
        </CardComponentWrapper>

        <CardComponentWrapper isDirty={isDirty} state={cvvState}>
          <CardCVV
            className="Input--chargebee field empty"
            onChange={setCvvState}
            placeholder="CVV"
          />
        </CardComponentWrapper>
      </div>
    </CardComponent>
  );
});

ChargebeeWidget.defaultProps = {
  cvvState: null,
  expiryState: null,
  numberState: null,
};

ChargebeeWidget.propTypes = {
  cvvState: PropTypes.object,
  expiryState: PropTypes.object,
  isDirty: PropTypes.bool.isRequired,
  numberState: PropTypes.object,
  setCvvState: PropTypes.func.isRequired,
  setExpiryState: PropTypes.func.isRequired,
  setNumberState: PropTypes.func.isRequired,
};

export default ChargebeeWidget;
