import React from 'react';

import omit from 'lodash-es/omit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Alert from 'src/design/ui-kit/Alert/Alert';
import VulnerabilityRowDetailsAsset
  from 'src/views/VulnerabilityScan/ScanVulnerabilities/VulnerabilityRow/VulnerabilityRowDetailsAsset';
import VulnerabilityRowDetailsPlugin
  from 'src/views/VulnerabilityScan/ScanVulnerabilities/VulnerabilityRow/VulnerabilityRowDetailsPlugin';

function VulnerabilityRowDetails(props) {
  const { vulnerability, vulnerabilityType } = props;

  const { t } = useTranslation();

  const attributes = JSON.parse(vulnerability.plugin.attributes);

  const pluginAttributes = omit(
    attributes,
    [
      'description', 'solution', 'see_also', 'synopsis', 'cve',
      'cvss_base_score', 'cvss3_base_score', 'cvss_vector', 'cvss3_vector',
    ],
  );

  const getCVSSLabel = () => {
    if (attributes.cvss3_base_score) {
      return t('CVSS 3');
    }
    if (attributes.cvss_base_score) {
      return t('CVSS');
    }
    return '';
  };

  return (
    <div className="VulnerabilityItemDetails py-6 px-10 grid gap-10">
      <section className="grid gap-4 grid-cols-[max-content_auto]">
        <p className="text-od-white-500 od-font-secondary-medium flex gap-4 items-center">
          {t('Severity')}
        </p>

        <div className="flex">
          <div
            className="px-4 py-1 rounded-md text-od-white-900 text-sm"
            style={{ background: vulnerabilityType.color }}
          >
            {vulnerabilityType.name}
          </div>
        </div>

        {(attributes.cvss_base_score || attributes.cvss3_base_score) && (
          <>
            <p className="text-od-white-500 od-font-secondary-medium flex gap-4 items-center">
              {getCVSSLabel()}
            </p>

            <div className="flex">
              <div
                className="px-4 py-1 rounded-md text-od-white-900 bg-od-white-300 text-sm group cursor-pointer"
              >
                <div className="group-hover:hidden">{attributes.cvss_base_score || attributes.cvss3_base_score}</div>
                <div className="hidden group-hover:block">{attributes.cvss3_vector || attributes.cvss_vector}</div>
              </div>
            </div>
          </>
        )}
      </section>

      <section className="grid gap-4">
        <p className="text-od-white-500 od-font-secondary-medium">
          {t('Synopsis')}
        </p>
        <div
          className="code text-sm bg-od-black-900 p-2 rounded-md overflow-x-auto od-scrollbar"
        >
          {vulnerability.plugin.synopsis}
        </div>
      </section>

      <section className="grid gap-4">
        <p className="text-od-white-500 od-font-secondary-medium">
          {t('Description')}
        </p>
        <div
          className="code text-sm bg-od-black-900 p-2 rounded-md overflow-x-auto od-scrollbar"
        >
          {vulnerability.plugin.description}
        </div>
      </section>

      <section className="grid gap-4">
        <p className="text-od-white-500 od-font-secondary-medium">
          {t('Solution')}
        </p>
        <Alert variant="success">
          {vulnerability.plugin.solution}
        </Alert>
      </section>

      {(vulnerability.plugin.seeAlso.length > 0 || attributes.cve) && (
      <section className="grid gap-4">
        <p className="text-od-white-500 od-font-secondary-medium">
          {t('See also')}
        </p>
        <ul>
          {attributes.cve && (
            <li className="grid gap-2">
              <a
                className="text-sm link--text-highlighted"
                href={`https://nvd.nist.gov/vuln/detail/${attributes.cve}`}
                rel="noreferrer"
                target="_blank"
              >
                {attributes.cve}
              </a>
            </li>
          )}
          {vulnerability.plugin.seeAlso.map((link, index) => (
            <li key={index} className="grid gap-2">
              <a
                className="text-sm link--text-highlighted"
                href={link}
                rel="noreferrer"
                target="_blank"
              >
                {link}
              </a>
            </li>
          ))}
        </ul>
      </section>
      )}

      <section className="grid gap-4">
        <p className="text-od-white-500 od-font-secondary-medium">
          {t('Affected hosts')}
        </p>
        <ul className="grid gap-4">
          {vulnerability.assets.map((asset, index) => (
            <VulnerabilityRowDetailsAsset key={index} asset={asset} />
          ))}
        </ul>
      </section>

      <VulnerabilityRowDetailsPlugin attributes={pluginAttributes} />
    </div>
  );
}

VulnerabilityRowDetails.propTypes = {
  vulnerability: PropTypes.object.isRequired,
  vulnerabilityType: PropTypes.object.isRequired,
};

export default VulnerabilityRowDetails;
