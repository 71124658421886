import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Popover } from 'react-tiny-popover';

function Tooltip(props) {
  const { children, popoverProps, text } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const getContent = () => (
    <div className="od-tooltip od-tooltip--sm max-w-[288px]">
      {text}
    </div>
  );

  return (
    <Popover
      align="center"
      content={getContent()}
      isOpen={isPopoverOpen}
      onClickOutside={() => setIsPopoverOpen(false)}
      padding={6}
      positions={['top']}
      reposition
      {...popoverProps}
    >
      <div
        onMouseEnter={() => setIsPopoverOpen(true)}
        onMouseLeave={() => setIsPopoverOpen(false)}
      >
        {children}
      </div>
    </Popover>
  );
}

Tooltip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  popoverProps: PropTypes.object,
  text: PropTypes.string,
};

Tooltip.defaultProps = {
  popoverProps: {},
  text: '',
};

export default Tooltip;
