import React, { useState } from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from 'src/design/ui-kit/Button/Button';
import { USER_TYPE_CATEGORIES_LIST } from 'src/users';
import UserModal from 'src/views/common/Users/UserModal/UserModal';

function AddUserButton(props) {
  const { userCategoryType } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      <Button onClick={() => setIsOpen(true)}>
        <FontAwesomeIcon icon={light('square-plus')} />
        {t('Add user')}
      </Button>
      <UserModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        userCategoryType={userCategoryType}
      />
    </div>
  );
}

AddUserButton.propTypes = {
  userCategoryType: PropTypes.oneOf(USER_TYPE_CATEGORIES_LIST).isRequired,
};

export default AddUserButton;
