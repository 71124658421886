import React, { useEffect } from 'react';

import useTargets from 'src/api/loaders/useTargets';
import TableView from 'src/components/common/Table/TableView';
import { TARGET_GROUP_UPDATED } from 'src/constants/events';
import useEmitter from 'src/hooks/useEmitter';
import useUserParams from 'src/hooks/useUserParams';
import EvsTargetsTableViewRow from 'src/views/TacticVulnerabilities/EvsTargets/EvsTargetsTableViewRow';
import useEvsTargetsColumns from 'src/views/TacticVulnerabilities/EvsTargets/useEvsTargetsColumns';

const first = 20;

function EvsTargetsTableView() {
  const columns = useEvsTargetsColumns();
  const { companyId } = useUserParams();
  const emitter = useEmitter();

  const variables = {
    companyId,
    first,
  };
  const { data: { items: evsTargets, pageInfo }, fetchMore, isLoadingMore, refetch } = useTargets({ variables });

  useEffect(() => {
    emitter.on(TARGET_GROUP_UPDATED, () => {
      refetch(variables);
    });
  });

  const onLoadMore = async () => {
    await fetchMore({
      variables: {
        ...variables,
        after: pageInfo.endCursor,
      },
    });
  };

  return (
    <TableView
      className="EvsTargetsTableView gap-y-2"
      columns={columns}
      data={evsTargets}
      isLoadingMore={isLoadingMore}
      onLoadMore={onLoadMore}
      pageInfo={pageInfo}
      TableRow={EvsTargetsTableViewRow}
    />
  );
}

export default EvsTargetsTableView;
