/* eslint-disable react/no-this-in-sfc,object-shorthand,func-names */
import React from 'react';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';

import * as colorsMap from 'src/design/theme/colors';

function StackedChart(props) {
  const { bgColor, chartData, tooltipFormatter } = props;
  const { categories, colors, series } = chartData;

  const options = {
    accessibility: {
      enabled: false,
    },
    chart: {
      backgroundColor: null,
      type: 'column',
    },
    colors,
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        borderColor: bgColor,
        borderRadius: 4,
        borderWidth: 4,
        colors,
        dataLabels: {
          enabled: false,
        },
        groupPadding: 0,
        pointPadding: 0.1,
        stacking: 'normal',
      },
      series: {
        dataLabels: {
          enabled: true,
          formatter: tooltipFormatter,
        },
        events: {
          legendItemClick(e) {
            e.preventDefault();
          },
        },
      },
    },
    series,
    title: {
      text: '',
    },
    tooltip: {
      backgroundColor: colorsMap['od-black-900'],
      borderRadius: 12,
      borderWidth: 0,
      formatter: tooltipFormatter,
      shadow: false,
      shape: 'square',
      shared: false,
      useHTML: true,
    },
    xAxis: {
      categories,
      labels: {
        formatter: function () {
          return (`
          <div class="od-font-secondary-medium od-font-family text-od-white-500">
             ${this.value}
          </div>
      `);
        },
        useHTML: true,
      },
      lineWidth: 0,
    },
    yAxis: {
      gridLineColor: colorsMap['od-black-100'],
      labels: {
        formatter: function () {
          return (`
          <div class="od-font-secondary-regular od-font-family text-od-white-900">
             ${this.value}
          </div>
      `);
        },
        useHTML: true,
      },
      title: '',
      visible: true,
    },
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
}

StackedChart.defaultProps = {
  // bgColor is used to add spacing between segments as borderWidth
  bgColor: colorsMap['od-black-900'],
};

StackedChart.propTypes = {
  bgColor: PropTypes.string,
  chartData: PropTypes.object.isRequired,
  tooltipFormatter: PropTypes.func.isRequired,
};

export default StackedChart;
