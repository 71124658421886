import React from 'react';

import { useTranslation } from 'react-i18next';

import useTargets from 'src/api/loaders/useTargets';
import useUserParams from 'src/hooks/useUserParams';
import EvsTargetsTableView from 'src/views/TacticVulnerabilities/EvsTargets/EvsTargetsTableView';

// TODO: Extend with search
function EvsTargets() {
  const { companyId } = useUserParams();
  const { t } = useTranslation();
  const variables = {
    companyId,
  };
  const { data: { items: evsTargets } } = useTargets({ variables });

  return (
    <section className="EvsTargets grid gap-4 mt-10">
      <h5 className="od-font-title-semi-bold mb-2">{t('IP addresses from all groups')}</h5>

      <EvsTargetsTableView evsTargets={evsTargets} />
    </section>
  );
}

export default EvsTargets;
