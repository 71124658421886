import React, { forwardRef, useImperativeHandle } from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormControl from 'src/components/common/FormControl';
import Button from 'src/design/ui-kit/Button/Button';
import Input from 'src/design/ui-kit/Input/Input';
import Select from 'src/design/ui-kit/Select/Select';
import { USER_TYPE_CATEGORIES_LIST } from 'src/users';
import useUserForm from 'src/views/common/Users/UserModal/useUserForm';

const UserModalForm = forwardRef((props, ref) => {
  const { loading, onSubmit, user, userCategoryType } = props;

  const { t } = useTranslation();

  const {
    form: { control, formState, handleSubmit, register, reset },
    helpers: { getFieldError },
    userTypes,
  } = useUserForm({ user, userCategoryType });

  useImperativeHandle(ref, () => ({
    reset,
  }));

  const isEditMode = !!user;

  return (
    <form
      className="UserModalForm mt-12 grid gap-4"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormControl
        error={getFieldError('email')}
        label={t('Email')}
      >
        <Input
          disabled={isEditMode}
          error={getFieldError('email')}
          name="email"
          placeholder={t('Enter email')}
          register={register}
        />
      </FormControl>

      {isEditMode && (
        <>
          <FormControl
            error={getFieldError('firstName')}
            label={t('First name')}
          >
            <Input
              error={getFieldError('firstName')}
              name="firstName"
              placeholder={t('Enter first name')}
              register={register}
            />
          </FormControl>

          <FormControl
            error={getFieldError('lastName')}
            label={t('Last name')}
          >
            <Input
              error={getFieldError('lastName')}
              name="lastName"
              placeholder={t('Enter last name')}
              register={register}
            />
          </FormControl>
        </>
      )}

      <FormControl
        error={getFieldError('userType')}
        label={t('Role')}
      >
        <Controller
          control={control}
          name="userType"
          render={({ field: { onChange, ref: inputRef, value } }) => (
            <Select
              inputRef={inputRef}
              isClearable={false}
              isSearchable={false}
              onChange={onChange}
              options={userTypes}
              placeholder={t('Select role')}
              value={value}
            />
          )}
        />
      </FormControl>

      <div className="flex justify-end mt-10">
        <Button
          disabled={!formState.isValid || loading}
          type="submit"
        >
          {isEditMode ? t('Save') : t('Invite')}
        </Button>
      </div>
    </form>
  );
});

UserModalForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.object,
  userCategoryType: PropTypes.oneOf(USER_TYPE_CATEGORIES_LIST).isRequired,
};

UserModalForm.defaultProps = {
  user: null,
};

export default UserModalForm;
