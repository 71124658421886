import React, { useState } from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ScheduleModal from 'src/components/features/vulnerabilities/ScheduleButton/ScheduleModal';
import Button, { buttonSizes } from 'src/design/ui-kit/Button/Button';

function ScheduleButton(props) {
  const { initialTargetGroup, size } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      <Button
        onClick={() => setIsOpen(true)}
        size={size}
        variant="secondary"
      >
        <FontAwesomeIcon icon={light('calendar-days')} />
        {t('Schedule')}
      </Button>
      <ScheduleModal
        initialTargetGroup={initialTargetGroup}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
}

ScheduleButton.defaultProps = {
  initialTargetGroup: null,
};

ScheduleButton.propTypes = {
  initialTargetGroup: PropTypes.object,
  size: PropTypes.oneOf(buttonSizes).isRequired,
};

export default ScheduleButton;
