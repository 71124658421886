import { toast } from 'react-toastify';

const defaultOptions = {
  autoClose: 3000,
  bodyStyle: {
    height: 40,
    maxHeight: 40,
    width: 'auto',
  },
  className: 'h-10 shadow-od-toast p-0 mt-8 rounded-od-22',
  closeButton: false,
  closeOnClick: true,
  hideProgressBar: true,
  position: toast.POSITION.TOP_CENTER,
  style: {
    height: 40,
    maxHeight: 40,
    minHeight: 40,
    width: 'auto',
  },
};

const showSuccessMessage = (message, options) => toast(
  message,
  {
    ...defaultOptions,
    bodyClassName: ('flex items-center bg-od-complementary-good px-6 py-3 od-secondary-regular '
      + 'text-center rounded-od-22 text-od-white-900'),
    ...options,
  },
);

const showErrorMessage = (message, options) => toast(
  message,
  {
    ...defaultOptions,
    bodyClassName: ('flex items-center bg-od-complementary-bad px-6 py-3 od-secondary-regular '
      + 'text-center rounded-od-22 text-od-white-900'),
    ...options,
  },
);

// https://fkhadra.github.io/react-toastify/api/toast
export default function useToast() {
  return {
    showErrorMessage,
    showSuccessMessage,
    toast,
  };
}
