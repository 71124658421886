import React, { useState } from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import Button from 'src/design/ui-kit/Button/Button';
import { USER_TYPE_CATEGORIES_LIST } from 'src/users';
import UserModal from 'src/views/common/Users/UserModal/UserModal';

function EditUserButton(props) {
  const { user, userCategoryType } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <Button
        onClick={() => setIsOpen(true)}
        variant="icon"
      >
        <FontAwesomeIcon icon={light('pen-to-square')} />
      </Button>
      <UserModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        user={user}
        userCategoryType={userCategoryType}
      />
    </div>
  );
}

EditUserButton.propTypes = {
  user: PropTypes.object.isRequired,
  userCategoryType: PropTypes.oneOf(USER_TYPE_CATEGORIES_LIST).isRequired,
};

export default EditUserButton;
