import React, { useEffect } from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { components } from 'react-select';

import useCompaniesLoader from 'src/api/loaders/useCompaniesLoader';
import Select from 'src/design/ui-kit/Select/Select';

const first = 20;

// eslint-disable-next-line react/prop-types
function Option({ data: option, innerProps, isSelected }) {
  return (
    <div
      {...innerProps}
      className="py-2 hover:shadow-od-select-option
       rounded-od-8 text-od-white-900 cursor-pointer mb-2 flex justify-between items-center"
    >
      <span className="pl-2 od-font-secondary-regular">
        {/* eslint-disable-next-line react/prop-types */}
        { option.name }
      </span>
      {
        isSelected && (
          <FontAwesomeIcon icon={light('check')} />
        )
      }
    </div>
  );
}

function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={light('chevrons-down')} />
    </components.DropdownIndicator>
  );
}

function CompanySelect(props) {
  const { onChange, selectedCompany } = props;

  const { t } = useTranslation();

  const {
    data: { items: companies, pageInfo },
    fetchMore,
    isLoadingMore,
    loading,
  } = useCompaniesLoader({
    variables: {
      first,
    },
  });

  const onLoadMore = async () => {
    await fetchMore({
      variables: {
        after: pageInfo.endCursor,
        first,
      },
    });
  };

  // We need to nest the component to be able to call onLoadMore function
  // eslint-disable-next-line react/no-unstable-nested-components
  function MenuList(menuListProps) {
    const [ref, inView] = useInView();

    useEffect(() => {
      if (inView) {
        onLoadMore();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView]);

    return (
      <components.MenuList {...menuListProps}>
        {/* eslint-disable-next-line react/destructuring-assignment,react/prop-types */}
        {menuListProps.children}
        {/* eslint-disable-next-line react/prop-types,react/destructuring-assignment */}
        { isLoadingMore && (
          <div className="text-center">
            ...
          </div>
        )}
        {
          pageInfo.hasNextPage && !isLoadingMore && (
            <div ref={ref} />
          )
        }
      </components.MenuList>
    );
  }

  return (
    <Select
      className="flex-1 mr-2"
      components={{
        DropdownIndicator,
        MenuList,
        Option,
      }}
      getOptionLabel={(company) => company.name}
      getOptionValue={(company) => company.id}
      isClearable={false}
      isLoading={loading}
      isSearchable={false}
      loadingMessage={() => t('Loading...')}
      onChange={onChange}
      options={companies}
      placeholder={t('Loading...')}
      value={selectedCompany}
    />
  );
}

CompanySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedCompany: PropTypes.object.isRequired,
};

export default CompanySelect;
