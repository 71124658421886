import React, { useState, Fragment } from 'react';

import classNames from 'classnames';
import { differenceInDays, format } from 'date-fns';
import map from 'lodash-es/map';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { LONG_LOCALIZED_DATE } from 'src/constants/date';
import IconExpanded from 'src/design/custom-icons/IconExpanded/IconExpanded';
import Badge from 'src/design/ui-kit/Badge/Badge';
import Button from 'src/design/ui-kit/Button/Button';

function BreachCredentialsTableViewRow(props) {
  const { data: breachCredential, id } = props;

  const [expanded, setExpanded] = useState(false);

  const { t } = useTranslation();

  const allData = JSON.parse(breachCredential.allData);

  return (
    <>
      <tr
        id={id}

      >
        <td>
          <time>
            {format(new Date(breachCredential.addedAt), LONG_LOCALIZED_DATE)}
          </time>
        </td>
        <td className="text-od-white-500">{breachCredential.domain}</td>
        <td>
          {breachCredential.emailAddress}
        </td>
        <td>
          {breachCredential.password ? t('Yes') : t('No')}
        </td>
        <td>
          {differenceInDays(new Date(), new Date(breachCredential.addedAt)) <= 10 && (
          <Badge>
            {t('New')}
          </Badge>
          )}
        </td>
        <td className="flex justify-end">
          <Button
            onClick={() => setExpanded(!expanded)}
            variant="text"
          >
            <IconExpanded expanded={expanded} />
          </Button>
        </td>
      </tr>
      {expanded && (
        <tr>
          <td className="col-span-6 h-auto -mt-2">
            <div
              className="grid gap-x-14 gap-y-5 items-center"
              style={{ gridTemplateColumns: 'max-content auto' }}
            >
              {map(allData, (value, key) => (
                <Fragment key={key}>
                  <span className="od-font-secondary-medium text-od-white-500 max-w-max">{key}</span>
                  <span
                    className={classNames('od-font-secondary-semi-bold', {
                      'text-od-breach-credentials-red': ['username', 'password'].includes(key),
                    })}
                  >
                    {value}
                  </span>
                </Fragment>
              ))}
            </div>
          </td>
        </tr>
      )}
    </>
  );
}

BreachCredentialsTableViewRow.propTypes = {
  data: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
};

export default BreachCredentialsTableViewRow;
