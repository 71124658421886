import React from 'react';

import { useTranslation } from 'react-i18next';

import useVulnerabilityScansLoader from 'src/api/loaders/useVulnerabilityScansLoader';
import Page from 'src/components/common/Page';
import TableView from 'src/components/common/Table/TableView';
import ScanActions from 'src/components/features/vulnerabilities/ScanActions';
import { useCurrentCompany } from 'src/contexts/useCurrentCompany';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import Button from 'src/design/ui-kit/Button/Button';
import EmptyState from 'src/design/ui-kit/EmptyState/EmptyState';
import Loading from 'src/design/ui-kit/Loading/Loading';
import useUserParams from 'src/hooks/useUserParams';
import useVulnerabilityScansColumns from 'src/views/VulnerabilityScans/useVulnerabilityScansColumns';
import VulnerabilityScansTableViewRow from 'src/views/VulnerabilityScans/VulnerabilityScansTableViewRow';

const first = 20;

function VulnerabilityScans() {
  const { t } = useTranslation();
  const { companyId } = useUserParams();
  const columns = useVulnerabilityScansColumns();
  const { permissions, userConfig } = useCurrentUser();
  const { companyEvsSubscription, loadingVulnerabilities } = useCurrentCompany();

  const {
    data: { items: allScans, pageInfo },
    fetchMore,
    isLoadingMore,
    loading: loadingScans,
  } = useVulnerabilityScansLoader({
    variables: {
      companyId,
      first,
    },
  });

  if (loadingScans || loadingVulnerabilities) {
    return (
      <Page title={t('Loading...')}>
        <Loading />
      </Page>
    );
  }

  if (!companyEvsSubscription) {
    return (
      <Page title={t('Vulnerability Scans')}>
        <EmptyState
          subtitle={t('You need to setup the tactic to see an overview')}
          title={t("Here's where you'll see an overview of your scan results")}
          variant="secondary"
        >
          <Button link={userConfig.buildUrl('/tactics-settings', `/${companyId}`)}>
            {t('Open Tactics')}
          </Button>
        </EmptyState>
      </Page>
    );
  }

  if (allScans.length === 0) {
    return (
      <Page title={t('Vulnerability Scans')}>
        <EmptyState
          subtitle={t('Click a button below to run your first scan')}
          title={t('Your scans history is empty')}
          variant="secondary"
        >
          {permissions.canManageEvsSubscription() && (
            <ScanActions />
          )}
        </EmptyState>
      </Page>
    );
  }

  const onLoadMore = async () => {
    await fetchMore({
      variables: {
        after: pageInfo.endCursor,
        first,
      },
    });
  };

  return (
    <Page title={t('Vulnerability Scans')}>
      <div className="flex items-center gap-3">
        <div className="flex-1">
          <h3>
            {t('Vulnerabilities Scans')}
          </h3>
        </div>

        {permissions.canManageEvsSubscription() && (
          <ScanActions />
        )}
      </div>
      <div className="mt-8">
        <TableView
          columns={columns}
          data={allScans}
          isLoadingMore={isLoadingMore}
          onLoadMore={onLoadMore}
          pageInfo={pageInfo}
          TableRow={VulnerabilityScansTableViewRow}
        />
      </div>
    </Page>
  );
}

export default VulnerabilityScans;
