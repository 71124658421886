import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import TableView from 'src/components/common/Table/TableView';
import { TableColumnDefinition } from 'src/hooks/useTable';
import BillingCompaniesTableViewRow from 'src/views/Billing/BillingCompaniesTableViewRow';

function BillingCompaniesTableView(props) {
  const { companies } = props;
  const { t } = useTranslation();

  const columns = [
    new TableColumnDefinition({
      name: 'company',
      title: t('Company'),
    }),
    // new TableColumnDefinition({
    //   name: 'lastPayment',
    //   title: t('Last payment'),
    // }),
    new TableColumnDefinition({
      name: 'vulnerabilities',
      title: t('Vulnerability cost'),
    }),
    new TableColumnDefinition({
      justifyContent: 'flex-end',
      name: 'nextPayment',
      title: t('Next payment'),
    }),
    // new TableColumnDefinition({
    //   name: 'vulnerabilityCost',
    //   title: t('Vulnerability cost'),
    // }),
    // new TableColumnDefinition({
    //   justifyContent: 'flex-end',
    //   name: 'totalCost',
    //   title: t('Cost'),
    // }),
  ];

  return (
    <TableView
      columns={columns}
      data={companies}
      TableRow={BillingCompaniesTableViewRow}
    />
  );
}

BillingCompaniesTableView.propTypes = {
  companies: PropTypes.array.isRequired,
};

export default BillingCompaniesTableView;
