import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from 'src/design/ui-kit/Button/Button';

function BulkEditStringsRow(props) {
  const {
    canEdit, index, indexFormatter, isDuplicated, onDelete, onEdit, value, valueFormatter,
  } = props;

  return (
    <div
      className={classNames(
        'BulkEditStringsRow',
        'flex h-7 bg-od-black-300 rounded-od-10 items-center gap-4 px-4 flex-shrink-0',
        {
          'Button--dangerous': isDuplicated,
        },
      )}
    >
      <span className="od-font-secondary-regular text-od-white-500">
        {indexFormatter(index, value)}
      </span>

      <span className="od-font-secondary-medium flex-1">{valueFormatter(value)}</span>

      {canEdit && (
      <Button
        onClick={() => onEdit(value, index)}
        variant="text"
      >
        <FontAwesomeIcon icon={light('pen-to-square')} />
      </Button>
      )}

      <Button
        onClick={() => onDelete(index)}
        variant="text"
      >
        <FontAwesomeIcon icon={light('trash')} />
      </Button>
    </div>
  );
}

BulkEditStringsRow.defaultProps = {
  canEdit: true,
  indexFormatter: (index) => index + 1,
  valueFormatter: (value) => value,
};

BulkEditStringsRow.propTypes = {
  canEdit: PropTypes.bool,
  index: PropTypes.number.isRequired,
  indexFormatter: PropTypes.func,
  isDuplicated: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  valueFormatter: PropTypes.func,
};

export default BulkEditStringsRow;
