import React from 'react';

import hexToRgba from 'hex-to-rgba';
import PropTypes from 'prop-types';

function ProgressBar(props) {
  const { data } = props;

  const percent = `${data.percent}%`;
  const color = hexToRgba(data.color, 0.4);
  const background = `linear-gradient(to right, ${color} 0, ${color} ${percent}, transparent ${percent})`;

  return (
    <div
      className="ProgressBar h-12 p-4 rounded-xl border border-od-black-100 flex items-center od-font-secondary-medium"
      style={{ background }}
    >
      <div className="flex items-center gap-2 flex-1">
        <div className="h-3.5 w-3.5 rounded" style={{ background: data.color }} />
        {data.name}
      </div>
      {data.value}
    </div>
  );
}

ProgressBar.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ProgressBar;
