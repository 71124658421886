import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const buttonVariants = ['primary', 'secondary', 'text', 'icon', 'link', 'dangerous'];

export const buttonSizes = ['xs', 'sm', 'md'];

function Button(props) {
  const {
    children, className, disabled, link,
    onClick, size, title, type, variant, visuallyDisabled,
  } = props;
  const classes = classNames(
    'Button border-0 outline-none focus:outline-none box-border od-font-button',
    `Button--${variant}`,
    `Button--${size}`,
    {
      'appearance-none border-0 outline-none focus:outline-none': variant === 'text' && !disabled && !visuallyDisabled,
      'appearance-none border-0 outline-none focus:outline-none p-2 hover:opacity-70': variant === 'icon'
        && !disabled && !visuallyDisabled,
      'opacity-40': disabled || visuallyDisabled,
      'px-4 py-1 h-8': variant !== 'text' && variant !== 'icon' && size === 'xs',
      'px-6 py-2 h-9': variant !== 'text' && variant !== 'icon' && size === 'sm',
      'px-6 py-3 h-10': variant !== 'text' && variant !== 'icon' && size === 'md',
      'rounded-od-10': variant !== 'text' && variant !== 'icon',
    },
    className,
  );

  if (link) {
    return (
      <NavLink
        className={classes}
        to={link}
      >
        {children}
      </NavLink>
    );
  }

  return (
    <button
      className={classes}
      disabled={disabled}
      onClick={onClick}
      title={title}
      type={type}
    >
      {children}
    </button>
  );
}

Button.defaultProps = {
  children: '',
  className: 'flex items-center gap-2',
  disabled: false,
  link: '',
  onClick: () => {
  },
  size: 'md',
  title: '',
  type: 'button',
  variant: 'primary',
  visuallyDisabled: false,
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  link: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(buttonSizes),
  title: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
  variant: PropTypes.oneOf(buttonVariants),
  visuallyDisabled: PropTypes.bool,
};

export default Button;
