import React, { forwardRef } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import colors from 'src/design/theme/colors';

const variants = ['default', 'transparent'];

const Input = forwardRef((props, ref) => {
  const {
    InputIcon,
    disabled, error, id, name, onClick, onInput, onKeyDown,
    placeholder, readOnly, register, type, value, variant,
  } = props;
  const inputClasses = classNames(
    'Input__input',
    `Input__input--${variant}`,
    'h-11 w-full placeholder-od-white-500 focus:outline-none rounded-od-10',
    {
      'bg-transparent border-none': variant === 'transparent',
      'border bg-od-black-700 px-3': variant === 'default',
      'border border-od-input-border-disabled text-od-white-500': disabled,
      'text-od-complementary-bad border-od-complementary-bad': error,
      'text-od-white-900 border-od-black-100': !error && !disabled,
    },
  );
  const isUsingReactHookForm = !!register;
  // Do not pass value to the input if registered is activated
  const registeredProps = isUsingReactHookForm ? {
    ...register(name),
  } : {
    value,
  };
  return (
    <div className="Input relative">
      <input
        ref={ref}
        className={inputClasses}
        disabled={disabled}
        id={id}
        name={name}
        onClick={onClick}
        onInput={(event) => onInput(event.target.value, event)}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        readOnly={readOnly}
        style={{
          caretColor: colors['od-white-900'],
        }}
        type={type}
        {...registeredProps}
      />
      {InputIcon && (
        <div className="absolute h-11 top-0 right-5 flex items-center">
          {InputIcon}
        </div>
      )}
    </div>
  );
});

Input.defaultProps = {
  InputIcon: null,
  disabled: false,
  error: '',
  id: null,
  onClick: () => {},
  onInput: () => {},
  onKeyDown: () => {},
  placeholder: '',
  readOnly: false,
  register: null,
  type: 'text',
  value: '',
  variant: 'default',
};

Input.propTypes = {
  InputIcon: PropTypes.object,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onInput: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  register: PropTypes.func,
  type: PropTypes.oneOf(['text', 'number', 'password']),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  variant: PropTypes.oneOf(variants),
};

export default Input;
