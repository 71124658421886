import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useCurrentCompany } from 'src/contexts/useCurrentCompany';
import FieldCost from 'src/design/ui-kit/Field/FieldCost/FieldCost';
import FieldPeriodCost from 'src/design/ui-kit/Field/FieldPeriodCost/FieldPeriodCost';
import CostInfoTooltip from 'src/views/TacticsSettings/CostInfoTooltip';

function TargetGroupCost(props) {
  const { totalPrice, totalTargetsPrice } = props;
  const { t } = useTranslation();
  const { basePrice, evsSubscriptionStatus, targetPrice } = useCurrentCompany();

  return (
    <section className="TargetGroupCost pt-3">
      <div className="grid gap-2">
        {evsSubscriptionStatus !== 'active' && (
        <div className="flex items-center justify-between">
          <span className="od-font-main-regular text-od-white-500">
            {t('Base subscription cost:')}
          </span>
          <FieldCost
            className="od-font-main-regular text-od-white-500"
            value={basePrice}
          />
        </div>
        )}
        <div className="flex items-center justify-between">
          <span className="od-font-main-regular text-od-white-500">
            {t('Cost per IP:')}
          </span>
          <FieldCost
            className="od-font-main-regular text-od-white-500"
            value={targetPrice}
          />
        </div>
        <div className="flex items-center justify-between">
          <span className="od-font-main-regular text-od-white-500">
            {t('Total cost for IPs:')}
          </span>
          <FieldCost
            className="od-font-main-regular text-od-white-500"
            value={totalTargetsPrice}
          />
        </div>
      </div>

      <div className="flex items-center justify-between mt-2">
        <span className="od-font-title-regular">
          {t('Total cost:')}
        </span>
        <span className="flex items-center gap-2.5">
          <CostInfoTooltip />
          <FieldPeriodCost timeUnit="month" value={totalPrice} />
        </span>
      </div>
    </section>
  );
}

TargetGroupCost.propTypes = {
  totalPrice: PropTypes.number.isRequired,
  totalTargetsPrice: PropTypes.number.isRequired,
};

export default TargetGroupCost;
