import React from 'react';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const size = 300;

function PieChart(props) {
  const { chartData } = props;
  const { t } = useTranslation();

  const options = {
    accessibility: {
      enabled: false,
      point: {
        valueSuffix: '%',
      },
    },
    chart: {
      backgroundColor: 'transparent',
      height: size,
      margin: 0,
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      width: size,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        allowPointSelect: false,
        borderWidth: 0,
        dataLabels: {
          enabled: false,
        },
        shadow: false,
        size,
      },
    },
    series: [{
      colorByPoint: true,
      data: chartData,
      name: t('Vulnerabilities'),
      states: {
        hover: {
          enabled: false,
        },
        inactive: {
          enabled: false,
        },
        normal: {
          enabled: false,
        },
        select: {
          enabled: false,
        },
      },
    }],
    title: {
      text: '',
    },
    tooltip: {
      enabled: false,
    },
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
}

PieChart.propTypes = {
  chartData: PropTypes.array.isRequired,
};

export default PieChart;
