import React from 'react';

import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { UPDATE_USER_MUTATION } from 'src/api/users';
import FormControl from 'src/components/common/FormControl';
import Modal from 'src/components/modals/Modal';
import ProfileAvatar from 'src/components/modals/ProfileModal/ProfileAvatar';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import Button from 'src/design/ui-kit/Button/Button';
import Input from 'src/design/ui-kit/Input/Input';
import Loading from 'src/design/ui-kit/Loading/Loading';
import Select, { SelectOptionModel } from 'src/design/ui-kit/Select/Select';
import { isValidName } from 'src/helpers/validators';
import useToast from 'src/hooks/useToast';
import useValidationHelpers from 'src/hooks/useValidationHelpers';
import { USER_TYPE_LABELS } from 'src/users';

const schema = () => yup.object({
  avatar: yup
    .string()
    .required(i18n.t('Field is required')),
  email: yup
    .string()
    .required(i18n.t('Field is required'))
    .email(i18n.t('Must be a valid email field')),
  firstName:
    yup
      .string()
      .required(i18n.t('Field is required'))
      .test('name', i18n.t('Must be a valid first name'), isValidName),
  lastName:
    yup
      .string()
      .required(i18n.t('Field is required'))
      .test('name', i18n.t('Must be a valid last name'), isValidName),
  userType: yup
    .object()
    .required(i18n.t('Field is required')),
}).required();

const initialFormData = (me) => ({
  avatar: me.avatar,
  email: me.email,
  firstName: me.firstName,
  lastName: me.lastName,
  userType: new SelectOptionModel(me.userType, USER_TYPE_LABELS[me.userType]),
});

function ProfileModal(props) {
  const { isOpen, onClose } = props;

  const { t } = useTranslation();
  const { showSuccessMessage } = useToast();

  const { error, fetchMe, loading, me } = useCurrentUser();

  const { control, formState, handleSubmit, register, reset, watch } = useForm({
    defaultValues: initialFormData(me),
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema()),
  });

  const formData = watch();

  const handleClose = () => {
    onClose();
    reset({
      defaultValues: initialFormData(me),
    });
  };

  const { getFieldError } = useValidationHelpers({
    formState,
  });

  const [saveUser, { loading: saving }] = useMutation(
    UPDATE_USER_MUTATION,
    {
      onCompleted({ updateUser }) {
        if (updateUser) {
          fetchMe();
          handleClose();
          showSuccessMessage(t('Profile updated successfully '));
        }
      },
    },
  );

  if (loading || error || !me) {
    return '';
  }

  const onSubmit = async (submitData, event) => {
    event.preventDefault();

    await saveUser({
      variables: {
        avatar: formData.avatar,
        email: formData.email,
        firstName: formData.firstName,
        id: me.id,
        lastName: formData.lastName,
        userType: me.userType,
        ...me.msp && {
          mspId: me.msp.id,
        },
        ...me.company && {
          companyId: me.company.id,
        },
      },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      shouldCloseOnOverlayClick={false}
      shouldFocusFirstFocusable
      title={t('My Profile')}
    >
      {loading ? (
        <Loading />
      ) : (
        <form
          className="mt-12 flex flex-col gap-7"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex justify-center">
            <Controller
              control={control}
              name="avatar"
              render={({ field: { onChange, value } }) => (
                <ProfileAvatar
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </div>
          <FormControl
            error={getFieldError('firstName')}
            htmlFor="ProfileModal__input-first-name"
            label={t('First Name')}
          >
            <Input
              error={getFieldError('firstName')}
              id="ProfileModal__input-first-name"
              name="firstName"
              placeholder={t('First name')}
              register={register}
            />
          </FormControl>
          <FormControl
            error={getFieldError('lastName')}
            htmlFor="ProfileModal__input-first-last-name"
            label={t('Last name')}
          >
            <Input
              error={getFieldError('lastName')}
              id="ProfileModal__input-last-name"
              name="lastName"
              placeholder={t('Last name')}
              register={register}
            />
          </FormControl>
          <FormControl
            error={getFieldError('email')}
            htmlFor="ProfileModal__input-email"
            label={t('Email')}
          >
            <Input
              disabled
              error={getFieldError('email')}
              name="email"
              placeholder={t('Email')}
              register={register}
            />
          </FormControl>
          <FormControl
            disabled
            htmlFor="ProfileModal__input-role"
            label={t('Role')}
          >
            <Controller
              control={control}
              name="userType"
              render={({ field: { onChange, ref, value } }) => (
                <Select
                  id="ProfileModal__input-role"
                  inputRef={ref}
                  isClearable={false}
                  isDisabled
                  isSearchable={false}
                  onChange={onChange}
                  options={[]}
                  placeholder={t('Select role')}
                  value={value}
                />
              )}
            />
          </FormControl>
          <div className="mt-10 flex justify-end">
            <Button
              disabled={saving || !formState.isValid}
              type="submit"
            >
              {t('Save changes')}
            </Button>
          </div>
        </form>
      )}
    </Modal>
  );
}

ProfileModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ProfileModal;
