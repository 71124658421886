import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import IconExpanded from 'src/design/custom-icons/IconExpanded/IconExpanded';
import Button from 'src/design/ui-kit/Button/Button';
import VulnerabilityRow
  from 'src/views/VulnerabilityScan/ScanVulnerabilities/VulnerabilityRow/VulnerabilityRow';

function VulnerabilityTypeRow(props) {
  const { vulnerabilities, vulnerabilitySeverityDefinition } = props;
  const [expanded, setExpanded] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setExpanded(false);
  }, [vulnerabilitySeverityDefinition]);

  const filteredVulnerabilities = vulnerabilities.filter(
    (vulnerability) => vulnerability.severity === vulnerabilitySeverityDefinition.severity,
  );

  return (
    <div className="VulnerabilityTypeRow">
      <div
        className={classNames(
          'px-5 py-3 bg-od-black-300 h-12 flex gap-2 items-center',
          'od-font-secondary-medium rounded-tl-od-8 rounded-tr-od-8',
          {
            'rounded-bl-od-8 rounded-br-od-8': !expanded,
          },
        )}
      >
        {filteredVulnerabilities.length > 0 && (
          <Button
            onClick={() => setExpanded(!expanded)}
            variant="text"
          >
            <IconExpanded expanded={expanded} />
          </Button>
        )}
        <div
          className="h-3.5 w-3.5 rounded"
          style={{ background: vulnerabilitySeverityDefinition.color }}
        />
        <Button
          disabled={filteredVulnerabilities.length === 0}
          onClick={() => setExpanded(!expanded)}
          variant="text"
        >
          {vulnerabilitySeverityDefinition.name}
        </Button>
        <div className="h-1 w-1 rounded-full bg-od-white-300" />
        <span className="text-od-white-500">
          {t('{{count}} vulnerabilities', { count: filteredVulnerabilities.length })}
        </span>
      </div>
      {
        expanded && (
          <div className="rounded-bl-od-8 rounded-br-od-8 border-b border-l border-r border-od-black-300">
            {filteredVulnerabilities.map((vulnerability, index) => (
              <VulnerabilityRow
                key={index}
                index={index}
                vulnerability={vulnerability}
                vulnerabilityType={vulnerabilitySeverityDefinition}
              />
            ))}
          </div>
        )
      }
    </div>
  );
}

VulnerabilityTypeRow.propTypes = {
  vulnerabilities: PropTypes.array.isRequired,
  vulnerabilitySeverityDefinition: PropTypes.object.isRequired,
};

export default VulnerabilityTypeRow;
