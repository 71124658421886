import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import Tooltip from 'src/design/ui-kit/Tooltip/Tooltip';

function CostInfoTooltip() {
  const { t } = useTranslation();

  return (
    <Tooltip
      text={t('Information about the cost calculation with recalculation at the time of the 1st day of each month')}
    >
      <FontAwesomeIcon icon={light('circle-info')} />
    </Tooltip>
  );
}

export default CostInfoTooltip;
