import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import colors from 'src/design/theme/colors';
import Tooltip from 'src/design/ui-kit/Tooltip/Tooltip';
import { hexToRgba } from 'src/helpers/color';

export const tooltipPositions = ['top', 'bottom'];

function InfoCardVisual(props) {
  const {
    Icon, children, className, color, description, tag, tooltip, tooltipPosition,
  } = props;

  const CustomTag = tag;

  return (
    <CustomTag
      className={classNames(
        'InfoCardVisual px-4 py-4 rounded-od-20 shadow-od-card flex justify-between',
        'bg-gradient-to-r to-od-black-300',
        'from-od-black-300',
        className,
      )}
      style={{
        '--tw-gradient-from': hexToRgba(color, 0.08),
      }}
    >
      <div className="w-full">
        <div
          className="od-font-main-medium flex gap-2 items-center"
          style={{
            color,
          }}
        >
          {Icon && (
            <div
              className="py-2 px-3 rounded-md"
              style={{
                background: hexToRgba(color, 0.1),
                color,
              }}
            >
              {Icon}
            </div>
          )}
          <span className="text-3xl font-semibold">
            { children }
          </span>
        </div>
        <div className="mt-2 text-sm text-od-white-500 flex items-center gap-1">
          {description}
          {tooltip && (
            <div className="px-1">
              <Tooltip
                popoverProps={{
                  positions: [tooltipPosition],
                }}
                text={tooltip}
              >
                <FontAwesomeIcon icon={light('circle-info')} />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </CustomTag>
  );
}

InfoCardVisual.defaultProps = {
  Icon: null,
  children: '',
  className: '',
  color: colors['od-black-100'],
  tag: 'div',
  tooltip: '',
  tooltipPosition: 'top',
};

InfoCardVisual.propTypes = {
  Icon: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  color: PropTypes.string,
  description: PropTypes.string.isRequired,
  tag: PropTypes.oneOf(['div', 'li']),
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.oneOf(tooltipPositions),
};

export default InfoCardVisual;
