import React, { useRef, useEffect } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

function scrollToTop(element) {
  element.scrollIntoView(true);
}

const ACTIVE_CLASS = 'TimeUnitView--is-active';

function TimeUnitView(props) {
  const { onChange, units, value } = props;

  const containerRef = useRef();

  useEffect(() => {
    const activeElement = containerRef.current.querySelector(`.${ACTIVE_CLASS}`);
    if (activeElement) {
      scrollToTop(activeElement);
    }
  }, [value, units]);

  return (
    <ul
      ref={containerRef}
      className="TimeUnitView grid gap-1 h-[256px] overflow-y-scroll od-scrollbar px-1 pt-1 pb-56"
    >
      {units.map((unit, index) => (
        <li key={index}>
          <button
            className={classNames(
              'h-8 w-full py-2 px-4 flex items-center justify-center',
              'od-font-secondary-regular border border-od-black-100 rounded-md',
              {
                [`bg-od-primary ${ACTIVE_CLASS}`]: value === unit,
              },
            )}
            onClick={() => onChange(unit)}
          >
            {unit}
          </button>
        </li>
      ))}
    </ul>
  );
}

TimeUnitView.propTypes = {
  onChange: PropTypes.func.isRequired,
  units: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
};

export default TimeUnitView;
