import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { EXPORT_BREACHES_MUTATION } from 'src/api/breach-credentials';
import ExportModal from 'src/components/modals/ExportModal';
import Button from 'src/design/ui-kit/Button/Button';
import { openInNewTab } from 'src/helpers/url';
import useToast from 'src/hooks/useToast';
import useUserParams from 'src/hooks/useUserParams';

function ExportButton() {
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const { companyId } = useUserParams();
  const { t } = useTranslation();
  const { showSuccessMessage } = useToast();

  const [exportCSV, { loading: saving }] = useMutation(
    EXPORT_BREACHES_MUTATION,
    {
      onCompleted({ exportBreachesCsv }) {
        if (!exportBreachesCsv) {
          return;
        }
        openInNewTab(exportBreachesCsv.url);
        showSuccessMessage(t('Breaches exported successfully'));
        setIsExportModalOpen(false);
      },
    },
  );

  const onExport = async () => {
    await exportCSV({
      variables: {
        companyId,
      },
    });
  };

  return (
    <>
      <Button onClick={() => setIsExportModalOpen(true)}>
        {t('Export')}
      </Button>
      <ExportModal
        isOpen={isExportModalOpen}
        loading={saving}
        onClose={() => setIsExportModalOpen(false)}
        onExport={onExport}
      />
    </>
  );
}

export default ExportButton;
