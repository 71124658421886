import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCurrentUser } from 'src/contexts/useCurrentUser';
import IconVulnerability from 'src/design/custom-icons/IconVulnerability/IconVulnerability';
import Button from 'src/design/ui-kit/Button/Button';
import FieldPeriodCost from 'src/design/ui-kit/Field/FieldPeriodCost/FieldPeriodCost';
import useUserParams from 'src/hooks/useUserParams';

function CompanyBillingSubscriptions(props) {
  const { cost, evsSubscription } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userConfig } = useCurrentUser();
  const { companyId } = useUserParams();

  return (
    <section>
      <ul className="flex flex-col gap-2">
        <li className="flex items-center p-4 od-font-main-medium bg-od-black-500">
          <div className="flex-1 flex items-center gap-3">
            <IconVulnerability />
            <div>
              {t('External Network Vulnerability Scanning')}
            </div>
          </div>

          <div className="flex-1 flex justify-between items-center">
            <div>
              { t('{{count}} targets', { count: evsSubscription.targetCount }) }
            </div>
            <FieldPeriodCost
              timeUnit="month"
              value={evsSubscription.cost || cost}
            />
            <Button
              className="place-self-end mr-2 flex gap-2 items-center h-9"
              onClick={() => navigate(userConfig.buildUrl('/tactics-settings/evs', `/${companyId}`))}
              size="sm"
              variant="secondary"
            >
              <FontAwesomeIcon icon={light('wrench')} />
              {t('Manage')}
            </Button>
          </div>
        </li>
        {/* <li className="flex items-center p-4 od-font-main-medium bg-od-black-500"> */}
        {/*  <div className="flex-1 flex items-center gap-3"> */}
        {/*    <IconBciXl /> */}
        {/*    <div> */}
        {/*      Breach credential */}
        {/*    </div> */}
        {/*  </div> */}
        {/*  <div className="flex-1 flex justify-between items-center"> */}
        {/*    <div> */}
        {/*      10 IP adresses */}
        {/*    </div> */}
        {/*    <FieldPeriodCost */}
        {/*      timeUnit="month" */}
        {/*      value={789} */}
        {/*    /> */}
        {/*    <Button */}
        {/*      size="sm" */}
        {/*      variant="secondary" */}
        {/*    > */}
        {/*      {t('Cancel subscription')} */}
        {/*    </Button> */}
        {/*  </div> */}
        {/* </li> */}
      </ul>
    </section>
  );
}

CompanyBillingSubscriptions.defaultProps = {
  cost: 0,
};

CompanyBillingSubscriptions.propTypes = {
  cost: PropTypes.number,
  evsSubscription: PropTypes.object.isRequired,
};

export default CompanyBillingSubscriptions;
