export function isBillingInvoicesEnabled() {
  return process.env.REACT_APP_DEV_ENABLED_BILLING_INVOICES === 'true';
}

export function isMSPDashboardEnabled() {
  return process.env.REACT_APP_DEV_ENABLED_MSP_DASHBOARD === 'true';
}

export function isBCIEnabled() {
  return process.env.REACT_APP_DEV_ENABLED_BCI === 'true';
}

export function isEvsTimeMetricEnabled() {
  return process.env.REACT_APP_DEV_ENABLED_EVS_TIME_METRIC === 'true';
}

function useFeatures() {
  return {
    isBCIEnabled,
    isBillingInvoicesEnabled,
    isEvsTimeMetricEnabled,
    isMSPDashboardEnabled,
  };
}

export default useFeatures;
