import React from 'react';

import PropTypes from 'prop-types';

import RunScanButton from 'src/components/features/vulnerabilities/RunScanButton/RunScanButton';
import ScheduleButton from 'src/components/features/vulnerabilities/ScheduleButton/ScheduleButton';

function ScanActions(props) {
  const { showSchedule } = props;

  return (
    <div className="ScanActions flex items-center gap-3">
      {showSchedule && <ScheduleButton size="sm" />}
      <RunScanButton size="sm" />
    </div>
  );
}

ScanActions.propTypes = {
  showSchedule: PropTypes.bool,
};

ScanActions.defaultProps = {
  showSchedule: false,
};

export default ScanActions;
