import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import TargetGroupSidebarModal
  from 'src/components/modals/TargetGroupSidebarModal/TargetGroupSidebarModal';
import { useMSPBilling } from 'src/contexts/useMSPBilling';
import Button from 'src/design/ui-kit/Button/Button';
import PaymentMethodModal from 'src/views/Billing/ChangePaymentMethod/PaymentMethodModal';

function EvsTacticSubscribeButton() {
  const [isSetupModalOpen, setIsSetupModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const { mspBillingDetails: { paymentSource: { isCardWorking } } } = useMSPBilling();

  const { t } = useTranslation();

  const onClickSubscribe = () => {
    if (isCardWorking) {
      setIsSetupModalOpen(true);
    } else {
      setIsPaymentModalOpen(true);
    }
  };

  return (
    <>
      <PaymentMethodModal
        isOpen={isPaymentModalOpen}
        onClose={() => setIsPaymentModalOpen(false)}
        onSuccess={() => setIsSetupModalOpen(true)}
      />
      <TargetGroupSidebarModal
        isOpen={isSetupModalOpen}
        mode="subscription"
        onClose={() => setIsSetupModalOpen(false)}
        title={t('Subscribe to EVS')}
      />
      <Button
        onClick={onClickSubscribe}
        size="sm"
      >
        {t('Subscribe')}
      </Button>
    </>
  );
}

export default EvsTacticSubscribeButton;
