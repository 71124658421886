import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function IconNews() {
  return (
    <FontAwesomeIcon
      className="
        IconNews
        rounded-[10px] bg-od-status-box-previous-text/10 flex items-center
        justify-center p-2.5 text-od-status-box-previous-text m-0 max-w-[40px] h-max-[40px] border-box
      "
      icon={light('newspaper')}
      size="lg"
    />
  );
}

export default IconNews;
