import { gql } from '@apollo/client';

export const USERS_QUERY = gql`
  query Users($first: Int!, $after: String, $companyId: ID, $mspId: ID, $search: String) {
    users(
      first: $first
      after: $after
      company: $companyId
      msp: $mspId
      search: $search
    ) @connection(key: "id") {
      edges {
        node {
          id
          avatar
          userType
          email
          fullName
          firstName
          lastName
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export function transformResponse(data) {
  if (!data) {
    return {
      items: [],
      pageInfo: {
        endCursor: null,
        hasNextPage: false,
      },
    };
  }
  return {
    items: data.users.edges.map((edge) => edge.node),
    pageInfo: data.users.pageInfo,
  };
}

export const ME_QUERY = gql`
  query me {
    me {
      id
      avatar
      email
      fullName
      firstName
      lastName
      userType
      company {
        id
        name
      }
      msp {
        id
        name
      }
      permissions
    }
  }
`;

export const CREATE_USER_MUTATION = gql`
  mutation createUser($email: String!, $userType: UserTypeEnum!, $companyId: ID, $mspId: ID) {
    createUser(input: {
      email: $email
      userType: $userType
      company: $companyId
      msp: $mspId
    }) {
      user {
        id
        company {
          id
        }
        msp {
          id
        }
      }
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser(
    $id: ID!,
    $email: String!,
    $userType: UserTypeEnum!,
    $companyId: ID,
    $mspId: ID,
    $firstName: String,
    $lastName: String,
    $avatar: String
  ) {
    updateUser(input: {
      avatar: $avatar
      id: $id
      email: $email
      userType: $userType
      company: $companyId
      msp: $mspId
      firstName: $firstName
      lastName: $lastName
    }) {
      user {
        id
        company {
          id
          name
        }
        msp {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_USER_MUTATION = gql`
  mutation($id: ID!) {
    deleteUser(input: {
      id: $id
    }) {
      id
    }
  }
`;
