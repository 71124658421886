import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import FieldDatetime from 'src/design/ui-kit/Field/FieldDatetime/FieldDatetime';

function BillingInvoicesTableViewRow(props) {
  const { data: invoice } = props;

  return (
    <tr className="hover:bg-od-black-300">
      <td>{invoice.id}</td>
      <td>{invoice.total}</td>
      <td>
        <div
          className="px-4 py-1 rounded-md text-od-white-900 text-sm bg-od-complementary-good"
        >
          {invoice.status}
        </div>
      </td>
      <td>
        <FieldDatetime value={new Date(invoice.issuedAt)} />
      </td>
      <td>
        <FieldDatetime value={new Date(invoice.paidAt)} />
      </td>
      <td className="justify-end">
        <FontAwesomeIcon icon={light('download')} />
      </td>
    </tr>
  );
}

BillingInvoicesTableViewRow.propTypes = {
  data: PropTypes.any.isRequired,
};

export default BillingInvoicesTableViewRow;
