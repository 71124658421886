import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DATETIME } from 'src/constants/date';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import FieldDatetime from 'src/design/ui-kit/Field/FieldDatetime/FieldDatetime';
import { useScanVulnerabilitySeverityDefinitions } from 'src/helpers/scan';
import useUserParams from 'src/hooks/useUserParams';

function VulnerabilityScansTableViewRow(props) {
  const { data: scan } = props;
  const navigate = useNavigate();
  const { companyId } = useUserParams();
  const { userConfig } = useCurrentUser();
  const { t } = useTranslation();
  const vulnerabilitySeverityDefinitions = useScanVulnerabilitySeverityDefinitions({ showInfo: true });

  const onClick = () => {
    navigate(userConfig.buildUrl(`/vulnerability-scans/${scan.id}`, `/${companyId}`));
  };

  return (
    <tr className="cursor-pointer" onClick={onClick}>
      <td>
        {scan.startedAt ? (
          <FieldDatetime
            dateFormat={DATETIME}
            value={new Date(scan.startedAt)}
          />
        ) : '-'}
      </td>
      <td>{scan.owner.name}</td>
      <td>
        <div className="flex gap-3">
          {vulnerabilitySeverityDefinitions.map(({ color, key }) => (
            <div
              key={key}
              className="px-2 py-0.5 od-font-main-medium rounded-md"
              style={{ backgroundColor: color }}
            >
              {scan.counts[key]}
            </div>
          ))}
        </div>
      </td>
      <td className="justify-end">
        {t('Completed')}
      </td>
    </tr>
  );
}

VulnerabilityScansTableViewRow.propTypes = {
  data: PropTypes.any.isRequired,
};

export default VulnerabilityScansTableViewRow;
