import React, { useState } from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import Button from 'src/design/ui-kit/Button/Button';
import EditDomainModal from 'src/views/TacticBreachCredentials/EditDomainModal';

function EditDomainButton(props) {
  const { domain, domains } = props;

  const [isOpenEditDomainModal, setIsOpenEditDomainModal] = useState(false);

  return (
    <>
      <Button
        onClick={() => setIsOpenEditDomainModal(true)}
        variant="text"
      >
        <FontAwesomeIcon icon={light('pen-to-square')} />
      </Button>
      <EditDomainModal
        domain={domain}
        domains={domains}
        isOpen={isOpenEditDomainModal}
        onClose={() => setIsOpenEditDomainModal(false)}
      />
    </>
  );
}

EditDomainButton.propTypes = {
  domain: PropTypes.string.isRequired,
  domains: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default EditDomainButton;
