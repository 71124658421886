import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as IconDropdownArrow } from 'src/design/custom-icons/dropdown-arrow.svg';

function DropdownArrow(props) {
  const { Icon, open } = props;
  return (
    <Icon
      className={
        classNames({
          'transform rotate-180': open,
        })
      }
    />
  );
}

DropdownArrow.defaultProps = {
  Icon: IconDropdownArrow,
  open: false,
};

DropdownArrow.propTypes = {
  Icon: PropTypes.object,
  open: PropTypes.bool,
};

export default DropdownArrow;
