import React, { createContext, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { UNSEEN_NOTIFICATIONS_QUERY } from 'src/api/notifications';
import { UNSEEN_NOTIFICATIONS_UPDATED } from 'src/constants/events';
import useEmitter from 'src/hooks/useEmitter';

// const FETCH_UNSEEN_NOTIFICATIONS_INTERVAL = 60 * 1000; // 1 min

export const CurrentUserNotificationsContext = createContext({
  hasUnseenNotifications: false,
  refetchUnseenNotifications: () => {},
  unseenNotifications: 0,
});

export function CurrentUserNotificationsProvider(props) {
  const { children } = props;

  const emitter = useEmitter();
  const { data, refetch: refetchUnseenNotifications } = useQuery(UNSEEN_NOTIFICATIONS_QUERY, { variables: {
    ignoreErrors: true,
  } });

  useEffect(() => {
    emitter.on(UNSEEN_NOTIFICATIONS_UPDATED, refetchUnseenNotifications);

    return () => {
      emitter.off(UNSEEN_NOTIFICATIONS_UPDATED);
    };
  });

  const unseenNotifications = data ? data.unseenNotifications : 0;

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <CurrentUserNotificationsContext.Provider value={{
      hasUnseenNotifications: unseenNotifications > 0,
      refetchUnseenNotifications,
      unseenNotifications,
    }}
    >
      {children}
    </CurrentUserNotificationsContext.Provider>
  );
}

CurrentUserNotificationsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useCurrentUserNotifications = () => React.useContext(CurrentUserNotificationsContext);
