import React, { useRef } from 'react';

import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import { ReactComponent as IconClose } from 'src/design/custom-icons/close.svg';
import Button from 'src/design/ui-kit/Button/Button';
import { focusFirstFocusable } from 'src/helpers/focus';

// Transitions
// https://github.com/reactjs/react-modal/blob/master/docs/styles/transitions.md

function Modal(props) {
  const {
    Subtitle, children, isOpen,
    onClose, shouldCloseOnOverlayClick, shouldFocusFirstFocusable, subtitle, title,
  } = props;

  const modalContent = useRef();

  const onAfterOpen = () => {
    if (shouldFocusFirstFocusable) {
      focusFirstFocusable(modalContent.current);
    }
  };

  return (
    <ReactModal
      ariaHideApp={false}
      className="Modal--default"
      closeTimeoutMS={50}
      contentLabel={title}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onClose}
      overlayClassName="Modal__overlay"
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldFocusAfterRender={false}
    >
      <div className="Modal__content-wrapper">
        <header className="flex justify-between">
          <div>
            <h3>{title}</h3>
            {
              subtitle && (
                <p className="text-od-white-500 od-font-secondary-regular mt-3">{subtitle}</p>
              )
            }
            {Subtitle}
          </div>
          <Button
            className="self-start p-2"
            onClick={onClose}
            variant="text"
          >
            <IconClose />
          </Button>
        </header>
        <div ref={modalContent} className="Modal__content mt-12">
          {children}
        </div>
      </div>
    </ReactModal>
  );
}

Modal.defaultProps = {
  Subtitle: null,
  shouldCloseOnOverlayClick: false,
  shouldFocusFirstFocusable: false,
  subtitle: '',
};

Modal.propTypes = {
  // Subtitle component
  Subtitle: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  shouldCloseOnOverlayClick: PropTypes.bool,
  shouldFocusFirstFocusable: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Modal;
