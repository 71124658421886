import React from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import useFeatures from 'src/hooks/useFeatures';

import VulnerabilityRowDetailsAssetFirstSeen from './VulnerabilityRowDetailsAssetFirstSeen';

function VulnerabilityRowDetailsAsset(props) {
  const { asset } = props;
  const { isEvsTimeMetricEnabled } = useFeatures();

  return (
    <li className="VulnerabilityRowDetailsAsset bg-od-black-300 rounded-lg p-5">
      <div className="grid gap-5">
        {asset.output && (
          <div className="grid gap-2">
            <div className="od-font-main-regular text-od-white-500 max-w-max">Vulnerability output</div>
            <pre
              className="code text-sm bg-od-black-900 p-2 rounded-md overflow-x-auto od-scrollbar"
            >
              {asset.output}
            </pre>
          </div>
        )}

        <div className={classNames('grid gap-x-4 gap-y-3 text-sm', {
          'grid-cols-[max-content_max-content_max-content]': !isEvsTimeMetricEnabled(),
          'grid-cols-[max-content_max-content_max-content_max-content_max-content]': isEvsTimeMetricEnabled(),
        })}
        >
          <div className="text-od-white-500">Host</div>
          <div className="text-od-white-500">Port</div>
          <div className="text-od-white-500">Protocol</div>
          <div className="text-od-white-500">Date first identified</div>
          <div className="text-od-white-500">Note</div>

          <div className="text-od-white-900">{asset.ipAddress}</div>
          <div className="text-od-white-900">{asset.port}</div>
          <div className="text-od-white-900">{asset.protocol}</div>
          <div className="text-od-white-900 group flex gap-2 items-center">
            <FontAwesomeIcon icon={light('calendar-days')} />
            <VulnerabilityRowDetailsAssetFirstSeen date={asset.firstSeenAt} />
          </div>
          <div className="text-od-white-900">{asset.note}</div>
        </div>
      </div>
    </li>
  );
}

VulnerabilityRowDetailsAsset.propTypes = {
  asset: PropTypes.object.isRequired,
};

export default VulnerabilityRowDetailsAsset;
