import React from 'react';

import { useTranslation } from 'react-i18next';

import LoaderGif from 'src/design/images/loader.gif';

function Loading() {
  const { t } = useTranslation();
  return (
    <img
      alt={t('Loading')}
      className="block mx-auto w-16 h-16 my-4"
      src={LoaderGif}
    />
  );
}

export default Loading;
