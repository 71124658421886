import React, { useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from 'src/design/ui-kit/Button/Button';
import { useVulnerabilityStatuses, useScanVulnerabilitySeverityDefinitions } from 'src/helpers/scan';
import VulnerabilityTypeRow
  from 'src/views/VulnerabilityScan/ScanVulnerabilities/VulnerabilityTypeRow';

function ScanVulnerabilities(props) {
  const { scan, showInfo } = props;

  const [selectedNavigationItemId, setSelectedNavigationItemId] = useState('currentVulnerabilities');

  const vulnerabilitySeverityDefinitions = useScanVulnerabilitySeverityDefinitions({ showInfo });

  const vulnerabilityStatuses = useVulnerabilityStatuses({ scan, showInfo });

  return (
    <div className="ScanVulnerabilities">
      <section className="mt-8">
        <nav>
          <ul className="flex items-center gap-10">
            {vulnerabilityStatuses.map(({ id, text }) => (
              <li key={id}>
                <Button
                  className={classNames('h-11 flex flex-col od-font-main-regular', {
                    'text-od-white-500': id !== selectedNavigationItemId,
                    'text-od-white-900': id === selectedNavigationItemId,
                  })}
                  onClick={() => setSelectedNavigationItemId(id)}
                  variant="text"
                >
                  {text}
                  {id === selectedNavigationItemId && (
                    <div className="h-0.5 w-full bg-od-white-900 rounded-full mt-4" />
                  )}
                </Button>
              </li>
            ))}
          </ul>
        </nav>
      </section>
      <section
        className="grid grid-cols-1 gap-3 mt-8"
      >
        {vulnerabilitySeverityDefinitions.map((vulnerabilitySeverityDefinition) => (
          <VulnerabilityTypeRow
            key={vulnerabilitySeverityDefinition.key}
            vulnerabilities={scan[selectedNavigationItemId]}
            vulnerabilitySeverityDefinition={vulnerabilitySeverityDefinition}
          />
        ))}
      </section>
    </div>
  );
}

ScanVulnerabilities.propTypes = {
  scan: PropTypes.object.isRequired,
  showInfo: PropTypes.bool.isRequired,
};

export default ScanVulnerabilities;
