import React, { useState, useRef } from 'react';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import colors from 'src/design/theme/colors';
import Button from 'src/design/ui-kit/Button/Button';

function InputSearch(props) {
  const { disabled, onInput, placeholder, value } = props;
  const [isFocused, setIsFocused] = useState(false);
  const input = useRef();

  const shouldShowCancelButton = !!value || isFocused;
  const inputClasses = classNames(
    'h-11 w-full bg-od-black-700 placeholder-od-white-500 text-od-white-900 pl-10 rounded-od-10',
    'focus:outline-none',
    {
      'border border-od-black-100': isFocused,
      'border-none': !isFocused,
      'pr-18': shouldShowCancelButton,
    },
  );
  const onCancel = () => {
    onInput('');
    setIsFocused(false);
    input.current.blur();
  };

  const onKeydown = (event) => {
    if (event.key === 'Escape') {
      onCancel();
    }
  };

  return (
    <div className="relative">
      <input
        ref={input}
        autoComplete="off"
        className={inputClasses}
        disabled={disabled}
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
        onInput={(event) => onInput(event.target.value)}
        onKeyDown={onKeydown}
        placeholder={placeholder}
        style={{
          caretColor: colors['od-white-900'],
        }}
        type="text"
        value={value}
      />
      <div className="absolute left-4 flex items-center top-3.5">
        <Button
          onClick={() => input.current.focus()}
          variant="text"
        >
          <FontAwesomeIcon icon={light('magnifying-glass')} />
        </Button>
      </div>
      {(value || isFocused) && (
        <div className="absolute right-1 top-1">
          <Button
            onClick={onCancel}
            size="sm"
            variant="secondary"
          >
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
}

InputSearch.defaultProps = {
  disabled: false,
  onInput: () => {},
  placeholder: '',
  value: '',
};

InputSearch.propTypes = {
  disabled: PropTypes.bool,
  onInput: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default InputSearch;
