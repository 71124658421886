import React, { useEffect, useState } from 'react';

import debounce from 'lodash-es/debounce';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useUsersLoader from 'src/api/loaders/useUsersLoader';
import NoResultMessage from 'src/components/common/NoResultMessage';
import Page from 'src/components/common/Page';
import TableView from 'src/components/common/Table/TableView';
import { USER_ADDED, USER_DELETED, USER_UPDATED } from 'src/constants/events';
import { useCurrentUser } from 'src/contexts/useCurrentUser';
import EmptyState from 'src/design/ui-kit/EmptyState/EmptyState';
import InputSearch from 'src/design/ui-kit/InputSearch/InputSearch';
import Loading from 'src/design/ui-kit/Loading/Loading';
import highlightElement from 'src/helpers/highlightElement';
import useEmitter from 'src/hooks/useEmitter';
import useUserParams from 'src/hooks/useUserParams';
import { USER_TYPE_CATEGORIES, USER_TYPE_CATEGORIES_LIST } from 'src/users';
import AddUserButton from 'src/views/common/Users/AddUserButton';
import UsersTableViewRow from 'src/views/common/Users/UsersTableViewRow';
import useUserColumns from 'src/views/common/Users/useUserColumns';

const first = 20;

function Users(props) {
  const { title, userCategoryType } = props;

  const [searchValue, setSearchValue] = useState();

  const { companyId, mspId } = useUserParams();
  const { permissions } = useCurrentUser();
  const columns = useUserColumns({ userCategoryType });
  const emitter = useEmitter();

  const variables = {
    companyId,
    first,
    search: searchValue,
    ...companyId && {
      companyId,
    },
    ...userCategoryType === USER_TYPE_CATEGORIES.MSP && {
      mspId,
    },
  };

  const {
    data: { items: users, pageInfo },
    fetchMore,
    isLoadingMore,
    loading,
    refetch,
  } = useUsersLoader({ variables });

  useEffect(() => {
    emitter.on(USER_ADDED, async ({ createUser: { user } }) => {
      await refetch(variables);
      // Highlight added user
      setTimeout(() => highlightElement(`TableRow${user.id}`));
    });

    emitter.on(USER_UPDATED, async () => {
      await refetch(variables);
    });

    emitter.on(USER_DELETED, async () => {
      await refetch(variables);
    });

    return () => {
      emitter.off(USER_ADDED);
      emitter.off(USER_UPDATED);
    };
  });

  const { t } = useTranslation();

  if (users.length === 0 && !loading && !searchValue) {
    return (
      <Page title={title}>
        <EmptyState
          subtitle={
            permissions.canManageUsers(userCategoryType)
              ? t('To invite your first user, simply click the "Add User" button below!') : ''
            }
          title={t("Here's a list of all the team members on your account.")}
        >
          {permissions.canManageUsers(userCategoryType) && (
            <AddUserButton userCategoryType={userCategoryType} />
          )}
        </EmptyState>
      </Page>
    );
  }

  const onLoadMore = async () => {
    await fetchMore({
      variables: {
        ...variables,
        after: pageInfo.endCursor,
      },
    });
  };

  const onSearchInput = debounce(async (value) => {
    setSearchValue(value);
    await refetch({
      ...variables,
      search: value,
    });
  }, 200);

  return (
    <Page className="grid gap-6" title={title}>
      <div className="flex items-center gap-4">
        <div className="flex-1">
          <InputSearch
            onInput={onSearchInput}
            placeholder={t('Search by name or email')}
            value={searchValue}
          />
        </div>
        {permissions.canManageUsers(userCategoryType) && (
          <AddUserButton userCategoryType={userCategoryType} />
        )}
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          {users.length === 0 ? !(
            <div className="mt-6 od-font-main-semi-bold text-od-white-500">
              <NoResultMessage searchValue={searchValue} />
            </div>
          ) : (
            <TableView
              columns={columns}
              data={users}
              isLoadingMore={isLoadingMore}
              onLoadMore={onLoadMore}
              pageInfo={pageInfo}
              rowProps={{ userCategoryType }}
              TableRow={UsersTableViewRow}
            />
          )}
        </div>
      )}
    </Page>
  );
}

Users.propTypes = {
  title: PropTypes.string.isRequired,
  userCategoryType: PropTypes.oneOf(USER_TYPE_CATEGORIES_LIST).isRequired,
};

export default Users;
