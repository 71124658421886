import { gql } from '@apollo/client';

export const ALL_SCANS_QUERY = gql`
  query allScans($first: Int, $last: Int, $after: String, $companyId: ID!, $endAt: DateTime) {
    allScans(
      first: $first
      last: $last
      after: $after
      company: $companyId
      endAt: $endAt
    ) {
      edges {
        node {
          id
          name
          ranAt
          startedAt
          owner {
            name
          }
          counts {
            critical
            high
            medium
            low
            info
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export function transformAllScansResponse(data) {
  if (!data) {
    return {
      items: [],
      pageInfo: {
        endCursor: null,
        hasNextPage: false,
      },
    };
  }
  return {
    items: data.allScans.edges.map((edge) => edge.node),
    pageInfo: data.allScans.pageInfo,
  };
}

// previous + newCount - remediatedCount = currentCount =>
// previous = currentCount + remediatedCount - newCount
export const SCAN_STATISTICS = gql`
  query scanStatistics($companyId: ID!) {
    scanStatistics(company: $companyId) {
      newCount
      currentCount
      remediatedCount
    }
  }
`;

export const SCAN_QUERY = gql`
  query($scanId: ID!) {
    scan(id: $scanId) {
      id
      name
      ranAt
      startedAt
      owner {
        name
      }
      liveHosts
      deadHosts
      targetedHosts
      newCount
      remediatedCount
      counts {
        critical
        high
        medium
        low
        info
      }
      currentVulnerabilities {
        id
        name
        severity
        affectedHosts
        plugin {
          exploitable
          exploitabilityText
          patchPublicationDate
          description
          solution
          attributes
          family
          seeAlso
          synopsis
        }
        assets {
          ipAddress
          output
          service
          port
          protocol
          note
          firstSeenAt
        }
      }
      newVulnerabilities {
        id
        name
        severity
        affectedHosts
        riskFactor
        plugin {
          exploitable
          exploitabilityText
          patchPublicationDate
          description
          solution
          attributes
          family
          seeAlso
          synopsis
        }
        assets {
          ipAddress
          output
          service
          port
          protocol
          note
          firstSeenAt
        }
      }
      remediatedVulnerabilities {
        id
        name
        severity
        affectedHosts
        plugin {
          exploitable
          exploitabilityText
          patchPublicationDate
          description
          solution
          attributes
          family
          seeAlso
          synopsis
        }
        assets {
          ipAddress
          output
          service
          port
          protocol
          note
          firstSeenAt
        }
      }
    }
  }
`;

export const EVS_TARGET_GROUPS = gql`
  query evsTargetGroups ($companyId: ID!, $first: Int!, $scansFirst: Int!, $targetsFirst: Int) {
    evsTargetGroups(
      company: $companyId
      first: $first
    ) {
      edges {
        node {
          id
          name
          schedule {
            id
            period
            days
            timeOfDay
            timezone
          }
          isScanRunning
          targetCount
          scans(first: $scansFirst) {
            edges {
              node {
                id
                newCount
                remediatedCount
                currentCount
                ranAt
                startedAt
                counts {
                  critical
                  high
                  medium
                  low
                  info
                }
              }
            }
          }
          targets(first: $targetsFirst) {
            edges {
              node {
                id
                ip
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const EVS_TARGETS = gql`
  query evsTargets($companyId: ID!, $targetGroupId: ID, $first: Int, $search: ID, $after: String) {
    evsTargets(
      company: $companyId
      targetGroup: $targetGroupId
      first: $first
      search: $search
      after: $after
    ) {
      edges {
        node {
          group {
            id
            name
          }
          ip
          id
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const CREATE_EVS_TARGET_GROUP_MUTATION = gql`
  mutation($name: String!, $companyId: ID!, $targets: [String!]!) {
    evsTargetGroupCreate(input: {
      name: $name
      company: $companyId
      targets: $targets
    }) {
      targetGroup {
        id
        name
      }
    }
  }
`;

export const UPDATE_EVS_TARGET_GROUP_MUTATION = gql`
  mutation($name: String!, $companyId: ID!, $targets: [String!]!) {
    createEvsTargetGroup(input: {
      name: $name
      company: $companyId
      targets: $targets
    }) {
      targetGroup {
        id
        name
        targets
      }
    }
  }
`;
export const DELETE_EVS_TARGET_GROUP_MUTATION = gql`
  mutation($targetGroup: ID!) {
    evsTargetGroupDelete(input: {
      targetGroup: $targetGroup
    }) {
      id
    }
  }
`;

export const DELETE_EVS_TARGET_MUTATION = gql`
  mutation($targetGroup: ID!, $targets: [String!]!) {
    evsTargetGroupRemoveTargets(input: {
      targetGroup: $targetGroup
      targets: $targets
    }) {
      targetGroup {
        id
      }
    }
  }
`;

function mapScans(targetGroup) {
  return {
    ...targetGroup,
    scans: targetGroup.scans.edges.map((edge) => edge.node),
  };
}

// createEvsTargetGroup
export function transformResponse(data) {
  if (!data) {
    return {
      items: [],
    };
  }
  return {
    items: data.evsTargetGroups.edges.map((edge) => mapScans(edge.node)),
  };
}

export const EXPORT_SCAN_MUTATION = gql`
  mutation($scan: ID!) {
    exportScanCsv(input: {
      scan: $scan
    }) {
      url
    }
  }
`;

export const RUN_SCAN_MUTATION = gql`
  mutation runScan($targetGroup: ID!) {
    runScan(input: {
      targetGroup: $targetGroup
    }) {
      trackId
    }
  }
`;

export const SET_PERIOD_SCAN_SCHEDULE_MUTATION = gql`
  mutation setPeriodScanSchedule(
    $targetGroup: ID!,
    $period: SchedulePeriod!,
    $days: [Int!]!,
    $timeOfDay: Time!,
    $timezone: String!
  ) {
    setPeriodScanSchedule(input: {
      targetGroup: $targetGroup
      period: $period
      days: $days
      timeOfDay: $timeOfDay
      timezone: $timezone
    }) {
      schedule {
        id
      }
    }
  }
`;
