import { useQuery } from '@apollo/client';

import { BCI_DOMAINS } from 'src/api/breach-credentials';

export default function useDomainsLoader({ variables }) {
  const query = useQuery(BCI_DOMAINS, {
    variables,
  });

  const { bciDomains } = query.data || { bciDomains: [] };
  return {
    ...query,
    data: { items: bciDomains },
  };
}
